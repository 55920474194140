import React from 'react';

import { StyledSVG } from '../Icon';

export const LinkIcon = props => {
  const { color } = props;

  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill={color}
      {...props}
    >
      <path d="M7.8325 10.6525C8.2425 11.0425 8.2425 11.6825 7.8325 12.0725C7.4425 12.4625 6.8025 12.4625 6.4125 12.0725C4.4625 10.1225 4.4625 6.9525 6.4125 5.0025L9.9525 1.4625C11.9025 -0.4875 15.0725 -0.4875 17.0225 1.4625C18.9725 3.4125 18.9725 6.5825 17.0225 8.5325L15.5325 10.0225C15.5425 9.2025 15.4125 8.3825 15.1325 7.6025L15.6025 7.1225C16.7825 5.9525 16.7825 4.0525 15.6025 2.8825C14.4325 1.7025 12.5325 1.7025 11.3625 2.8825L7.8325 6.4125C6.6525 7.5825 6.6525 9.4825 7.8325 10.6525ZM10.6525 6.4125C11.0425 6.0225 11.6825 6.0225 12.0725 6.4125C14.0225 8.3625 14.0225 11.5325 12.0725 13.4825L8.5325 17.0225C6.5825 18.9725 3.4125 18.9725 1.4625 17.0225C-0.4875 15.0725 -0.4875 11.9025 1.4625 9.9525L2.9525 8.4625C2.9425 9.2825 3.0725 10.1025 3.3525 10.8925L2.8825 11.3625C1.7025 12.5325 1.7025 14.4325 2.8825 15.6025C4.0525 16.7825 5.9525 16.7825 7.1225 15.6025L10.6525 12.0725C11.8325 10.9025 11.8325 9.0025 10.6525 7.8325C10.2425 7.4425 10.2425 6.8025 10.6525 6.4125Z" />
    </StyledSVG>
  );
};
