import React from 'react';

import { StyledSVG } from '../Icon';

export const DownloadIcon = props => {
  const { color } = props;

  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill={color}
      {...props}
    >
      <path d="M0 17H14V15H0V17ZM14 6H10V0H4V6H0L7 13L14 6Z" />
    </StyledSVG>
  );
};
