import * as React from 'react';

import { StyledSVG } from '../Icon';

export const Snowflake = props => {
  const { color } = props;

  return (
    <StyledSVG
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="github-icon-alt"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={color}
      {...props}
    >
      <path
        d="M5.45455 9.09091H0V10.9091H5.45455V9.09091ZM7.42727 6.14545L5.5 4.21818L4.21818 5.5L6.14545 7.42727L7.42727 6.14545ZM10.9091 0H9.09091V5.45455H10.9091V0ZM15.7818 5.5L14.5 4.21818L12.5727 6.14545L13.8545 7.42727L15.7818 5.5ZM14.5455 9.09091V10.9091H20V9.09091H14.5455ZM10 7.27273C9.27668 7.27273 8.58299 7.56006 8.07153 8.07153C7.56006 8.58299 7.27273 9.27668 7.27273 10C7.27273 10.7234 7.56006 11.4171 8.07153 11.9285C8.58299 12.4399 9.27668 12.7273 10 12.7273C10.7234 12.7273 11.4171 12.4399 11.9285 11.9285C12.4399 11.4171 12.7273 10.7234 12.7273 10C12.7273 9.27668 12.4399 8.58299 11.9285 8.07153C11.4171 7.56006 10.7234 7.27273 10 7.27273ZM12.5727 13.8545L14.5 15.7818L15.7818 14.5L13.8545 12.5727L12.5727 13.8545ZM4.21818 14.5L5.5 15.7818L7.42727 13.8545L6.14545 12.5727L4.21818 14.5ZM9.09091 20H10.9091V14.5455H9.09091V20Z"
        fill="#D97706"
      />
    </StyledSVG>
  );
};
