import * as React from 'react';

import { segmentTrack } from '../../utils/segment';
import { Maybe, SegmentTrackingType } from '../../utils/types';
import {
  Notification,
  NOTIFICATION_TYPE_ALERT,
  NOTIFICATION_TYPE_DISMISS,
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
  NOTIFICATION_TYPE_WARNING,
} from '../UIKit';

const getDefaultToastInterval = (type: string): Maybe<number> => {
  switch (type) {
    case NOTIFICATION_TYPE_SUCCESS: {
      return 5000;
    }
    case NOTIFICATION_TYPE_ALERT: {
      return 20000;
    }
    case NOTIFICATION_TYPE_WARNING: {
      return 10000;
    }
    case NOTIFICATION_TYPE_ERROR: {
      return null;
    }
    case NOTIFICATION_TYPE_DISMISS: {
      return null;
    }
    default: {
      return 10000;
    }
  }
};

const showNotification = (
  type: string,
  message: string,
  tracking?: Maybe<SegmentTrackingType>,
  placement?: Maybe<string>,
  tInterval?: Maybe<number>,
  setNotification?: any
) => {
  if (!setNotification) return;
  const toastInterval = tInterval || getDefaultToastInterval(type);
  const toastTimer = toastInterval
    ? setTimeout(() => {
        setNotification(null);
      }, toastInterval)
    : null;
  const dismiss = () => {
    setNotification(null);
    if (toastTimer) {
      clearTimeout(toastTimer);
    }
  };
  if (tracking) {
    const { event, properties, options, callback } = tracking;
    segmentTrack(event, properties, options, callback);
  }
  setNotification(
    <div data-testid="notification">
      <Notification
        type={type}
        message={message}
        dismiss={dismiss}
        placement={placement}
      />
    </div>
  );
};

// This higher order component's purpose is provide an API to display notifications.
// It is designed as a context which passes notification display functions down the
// component tree. To use notifications, you need to extract the required functions
// from the context and call them when required.
//
// ## Sample usage
// ```javascript
// import { NotificationsContext } from '../../Notifications';
// ...
// const YourComponent: React.FC<PropsType> = props => {
//   const { showErrorNotification, showSuccessNotification } = React.useContext(
//     NotificationsContext
//    );
//   ...
//   showErrorNotification('Error  occured!');
//   ...
// ```

export const NotificationsContext = React.createContext({
  showErrorNotification: (
    message: string,
    tracking?: Maybe<SegmentTrackingType>,
    placement?: Maybe<string>,
    toastInterval?: Maybe<number>
  ) => showNotification('warn', message, tracking, placement, toastInterval),
  showAlertNotification: (
    message: string,
    tracking?: Maybe<SegmentTrackingType>,
    placement?: Maybe<string>,
    toastInterval?: Maybe<number>
  ) => showNotification('warn', message, tracking, placement, toastInterval),
  showSuccessNotification: (
    message: string,
    tracking?: Maybe<SegmentTrackingType>,
    placement?: Maybe<string>,
    toastInterval?: Maybe<number>
  ) => showNotification('warn', message, tracking, placement, toastInterval),
  showWarningNotification: (
    message: string,
    tracking?: Maybe<SegmentTrackingType>,
    placement?: Maybe<string>,
    toastInterval?: Maybe<number>
  ) => showNotification('warn', message, tracking, placement, toastInterval),
});

const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [notification, setNotification] = React.useState(null);

  // functions to toast notifications
  const showErrorNotification = (
    message: string,
    tracking?: Maybe<SegmentTrackingType>,
    placement?: Maybe<string>,
    toastInterval?: Maybe<number>
  ) => {
    return showNotification(
      NOTIFICATION_TYPE_ERROR,
      message,
      tracking,
      placement,
      toastInterval,
      setNotification
    );
  };

  const showSuccessNotification = (
    message: string,
    tracking?: Maybe<SegmentTrackingType>,
    placement?: Maybe<string>,
    toastInterval?: Maybe<number>
  ) => {
    return showNotification(
      toastInterval === undefined
        ? NOTIFICATION_TYPE_DISMISS
        : NOTIFICATION_TYPE_SUCCESS,
      message,
      tracking,
      placement,
      toastInterval,
      setNotification
    );
  };

  const showAlertNotification = (
    message: string,
    tracking?: Maybe<SegmentTrackingType>,
    placement?: Maybe<string>,
    toastInterval?: Maybe<number>
  ) => {
    return showNotification(
      NOTIFICATION_TYPE_ALERT,
      message,
      tracking,
      placement,
      toastInterval,
      setNotification
    );
  };

  const showWarningNotification = (
    message: string,
    tracking?: Maybe<SegmentTrackingType>,
    placement?: Maybe<string>,
    toastInterval?: Maybe<number>
  ) => {
    return showNotification(
      NOTIFICATION_TYPE_WARNING,
      message,
      tracking,
      placement,
      toastInterval,
      setNotification
    );
  };

  return (
    <>
      {notification}
      <NotificationsContext.Provider
        value={{
          showErrorNotification,
          showSuccessNotification,
          showWarningNotification,
          showAlertNotification,
        }}
      >
        {children}
      </NotificationsContext.Provider>
    </>
  );
};

export default NotificationProvider;
