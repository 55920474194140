import React from 'react';

import { StyledSVG } from '../Icon';

export const PlusIcon = props => {
  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="black"
      {...props}
    >
      <path d="M8.47059 0H6.52941V6.52941H0V8.47059H6.52941V15H8.47059V8.47059H15V6.52941H8.47059V0Z" />
    </StyledSVG>
  );
};
