import React from 'react';

import NetworkLoader from '../../utils/NetworkLoader';
import { startSAMLSSO } from '../../utils/saml';
import { segmentIdentify } from '../../utils/segment';
import { TextOnChange } from '../../utils/types';
import { Button, Flex, Heading, Icon, Input, Label, Text } from '../UIKit';
import { AuthAlert } from './AuthAlert';
import { fieldNames } from './LoginActions';

interface SSOLoginProps {
  redir: string;
}

const SSOForm: React.FC<SSOLoginProps> = ({ redir }) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [email, setEmail] = React.useState('');

  const buttonText = loading ? 'Signing in' : 'Sign in with SSO';

  const handleSSORegistration = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    const lowerCaseEmail = email.toLowerCase();

    // Segment Ideintify call after the user clicks on "Sign in with SSO"
    segmentIdentify(lowerCaseEmail);

    startSAMLSSO(lowerCaseEmail, redir, err => {
      setLoading(false);
      if (err) {
        setError(err);
      }
    });
  };

  return (
    <>
      <Flex mb="20px" justifyContent="space-between" minHeight="45px">
        {loading && <NetworkLoader />}
        <Heading fontSize="1.5rem" fontFamily="Poppins" id="m-sso-heading">
          Sign in with SSO
        </Heading>
      </Flex>
      <Flex flexDirection="column" as="form" onSubmit={handleSSORegistration}>
        <Label>
          <Icon type="email" mr="12px" isActive={email || ''} />
          <Input
            type="email"
            data-field-name={fieldNames.email}
            placeholder="Email"
            value={email}
            onChange={(e: TextOnChange) => {
              setEmail(e.target.value);
            }}
          />
        </Label>
        <Button
          type="submit"
          variant="primary"
          size="large"
          width="100%"
          mt="8px"
          mb="20px"
          fontFamily="Poppins"
          isLoading={loading}
          disabled={loading || !email}
          id="login-btn"
          data-testid="sso-login-btn-test-id"
        >
          {buttonText}
        </Button>
        {/* email verification alert(success) */}
        {/* Auth error alert */}
        {error ? (
          <>
            <AuthAlert errorMessage={error} />
          </>
        ) : (
          <Text as="span" height="16px" />
        )}
      </Flex>
    </>
  );
};

export default SSOForm;
