import React from 'react';

import { theme } from '../../theme';
import { Text } from '../Typography';
import { StyledBadge } from './Badge';

interface BadgeProps {
  variant: string;
  badgeSize?: string;
  disabled?: boolean;
  badgeText?: string;
  justifyContent?: string;
  isLightMode?: boolean;
  borderColor?: string;
}

export const Badge: React.FC<BadgeProps> = ({
  variant,
  badgeSize = 'small',
  justifyContent = 'center',
  disabled,
  badgeText,
  isLightMode,
  borderColor,
}) => {
  const { badge }: any = theme;

  const badgeVariantConfig = { ...badge.default, ...badge[variant] };
  const badgeScaleConfig = badge.scale[badgeSize];

  return (
    <StyledBadge
      bg={disabled ? '#C4C4C4' : badgeVariantConfig.bg}
      minWidth={badgeScaleConfig.width}
      width="auto"
      height={badgeScaleConfig.height}
      borderRadius={badgeScaleConfig.borderRadius}
      border="1px solid"
      borderColor={borderColor || badgeVariantConfig.borderColor}
      justifyContent={justifyContent}
      px="10px"
      fontWeight={badgeScaleConfig.fontWeight}
      ml={badgeScaleConfig.ml}
    >
      <Text
        fontSize={badgeScaleConfig.fontSize}
        fontWeight={badgeScaleConfig.fontWeight}
        letterSpacing="-0.02em"
        color={
          disabled
            ? 'white'
            : isLightMode
              ? badgeVariantConfig.lightModeText
              : badgeVariantConfig.text
        }
      >
        {badgeText}
      </Text>
    </StyledBadge>
  );
};
