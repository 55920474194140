import React, { useState } from 'react';
import {
  Box,
  Group,
  PasswordInput as MantinePasswordInput,
  PasswordInputProps,
  Popover,
  Progress,
  Text,
  ThemeIcon,
} from '@mantine/core';
import { GetInputPropsReturnType } from '@mantine/form/lib/types';
import { PiCheck, PiKey, PiX } from 'react-icons/pi';

import { ICON_SIZE } from '../constants';

function PasswordRequirement({
  meets,
  label,
}: {
  meets: boolean;
  label: string;
}) {
  return (
    <Text style={{ display: 'flex', alignItems: 'center' }} mt={7} size="sm">
      {meets ? (
        <ThemeIcon color={meets ? 'teal' : 'red'} size={'sm'} variant="light">
          <PiCheck />
        </ThemeIcon>
      ) : (
        <ThemeIcon color={meets ? 'teal' : 'red'} size={'sm'} variant="light">
          <PiX />
        </ThemeIcon>
      )}{' '}
      <Box ml={10}>{label}</Box>
    </Text>
  );
}

const requirements = [
  {
    test: (value: string) => !!value && value.length >= 8 && value.length <= 64,
    label: 'Between 8 and 64 characters',
  },
  {
    test: (value: string) => /[0-9]/.test(value),
    label: 'Includes number',
  },
  {
    test: (value: string) => /[a-z]/.test(value),
    label: 'Includes lowercase letter',
  },
  {
    test: (value: string) => /[A-Z]/.test(value),
    label: 'Includes uppercase letter',
  },
  {
    test: (value: string) => /[$&+,:;=?@#|'<>.^*()%!-]/.test(value),
    label: 'Includes special symbol',
  },
];

export function getPasswordStrength(password: string) {
  if (!password) {
    return 0;
  }
  let multiplier = 0;

  requirements.forEach(requirement => {
    if (!requirement.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / requirements.length) * multiplier, 10);
}

export function isPasswordValid(password: string) {
  return getPasswordStrength(password) === 100;
}

export function PasswordInput({
  formInputProps,
  showStrengthPopover = false,
  placeholder = 'Password',
  disabled,
  icon = <PiKey size={ICON_SIZE} />,
  autoCompleteType,
}: {
  formInputProps: GetInputPropsReturnType;
  showStrengthPopover?: boolean;
  icon?: React.ReactNode;
  placeholder?: string;
  disabled?: PasswordInputProps['disabled'];
  autoCompleteType: 'current-password' | 'new-password';
}) {
  const [popoverOpened, setPopoverOpened] = useState(false);
  const value = formInputProps.value as string;

  const strength = getPasswordStrength(value);
  const color = strength === 100 ? 'teal' : strength > 50 ? 'yellow' : 'red';
  const isValid = strength === 100;

  return (
    <Popover
      opened={popoverOpened}
      position="bottom"
      shadow="md"
      disabled={!showStrengthPopover}
      width="target"
      transitionProps={{ transition: 'pop' }}
    >
      <Popover.Target>
        <div
          onFocusCapture={() => setPopoverOpened(!isValid)}
          onBlurCapture={() => setPopoverOpened(false)}
        >
          <MantinePasswordInput
            withAsterisk
            disabled={disabled}
            size="lg"
            placeholder={placeholder}
            autoComplete={autoCompleteType}
            styles={{
              input: {
                fontSize: 'var(--mantine-font-size-md)',
              },
            }}
            leftSection={icon}
            {...formInputProps}
            onChange={event => {
              const eventValue = event.currentTarget.value;

              if (!popoverOpened && getPasswordStrength(eventValue) < 100) {
                setPopoverOpened(true);
              }
              formInputProps.onChange(event);
            }}
          />
        </div>
      </Popover.Target>
      <Popover.Dropdown p={'1.75rem'}>
        <Group grow gap={5} mb={'1.5rem'}>
          <Progress
            size="xs"
            color={color}
            value={strength < 20 ? 0 : 100}
            transitionDuration={0}
          />
          <Progress
            size="xs"
            color={color}
            transitionDuration={0}
            value={strength < 40 ? 0 : 100}
          />
          <Progress
            size="xs"
            color={color}
            transitionDuration={0}
            value={strength < 60 ? 0 : 100}
          />
          <Progress
            size="xs"
            color={color}
            transitionDuration={0}
            value={strength < 80 ? 0 : 100}
          />
          <Progress
            size="xs"
            color={color}
            transitionDuration={0}
            value={strength < 100 ? 0 : 100}
          />
        </Group>

        {requirements.map((requirement, index) => (
          <PasswordRequirement
            key={index}
            label={requirement.label}
            meets={requirement.test(value)}
          />
        ))}
      </Popover.Dropdown>
    </Popover>
  );
}
