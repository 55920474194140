import { gql } from '@apollo/client';

export const VPC_PEERING_FRAGMENT = gql`
  fragment VPCPeeringFields on vpc_peering {
    name
    status
    id
    direction
    aws_customer_vpc_id
    gcp_project_id
    gcp_vpc_network
    output_variables
    azure_input
  }
`;

export const REGION_FRAGMENT = gql`
  fragment RegionFields on region {
    name
    nat_ip
    nat_ips
    cloud
  }
`;

export const DEDICATED_VPC_FRAGMENT = gql`
  ${REGION_FRAGMENT}
  fragment DedicateVPCFields on dedicated_vpc {
    id
    plan_name
    cloud
    created_at
    owner_id
    owner {
      id
      email
    }
    collaborators {
      collaborator_id
      privilege_slug
      status
      collaborator {
        email
      }
    }
    collaborator_invitations {
      status
      privilege_slug
      collaborator_email
      id
    }
    region
    region_info {
      ...RegionFields
    }
    name
    status
    gcp_network_name
    nat_ip
    cidr
    nat_ips
    vpc_id
    vpc_cidr
  }
`;

export const TENANT_FRAGMENT = gql`
  ${REGION_FRAGMENT}
  ${DEDICATED_VPC_FRAGMENT}
  fragment TenantFields on tenant {
    id
    created_at
    updated_at
    health_status
    region_info {
      ...RegionFields
    }
    config {
      status {
        is_active
        message
      }
      updated_at
    }
    major_version
    active
    active_status_reason
    custom_domains {
      id
      fqdn
    }
    tenant_public_status {
      endpoint
      type
      is_active
      message
    }
    dedicated_vpc_id
    dedicated_vpc {
      ...DedicateVPCFields
    }
    maintenance_mode
  }
`;

export const LABEL_FRAGMENT = gql`
  fragment LabelFields on label {
    id
    name
    color
    created_by
  }
`;

export const PROJECT_FRAGMENT = gql`
  ${TENANT_FRAGMENT}
  ${LABEL_FRAGMENT}
  fragment ProjectFields on projects {
    id
    name
    updated_at
    plan_name
    invoice_status
    invoice_attempt_count
    owner {
      id
      email
      enterprise_user {
        is_active
        is_exempt_from_billing
      }
    }
    endpoint
    created_at
    assigned_at
    tenant {
      ...TenantFields
    }
    metadata {
      connected_heroku_app_name
      heroku_managed_mode
      environment_name
      environment_color
    }
    collaborators(where: { status: { _eq: "active" } }) {
      id
      status
      project_collaborator_privileges {
        privilege_slug
      }
      collaborator_id
    }
    ownership_transfer_invites(
      where: {
        accepted_at: { _is_null: true }
        declined_at: { _is_null: true }
      }
    ) {
      invitee_email
      key
    }
    datadog_config {
      project_id
    }
    newrelic_config {
      project_id
    }
    azuremonitor_config {
      project_id
    }
    labels {
      label {
        ...LabelFields
      }
    }
    heroku_integrations {
      app_name
      var_name
    }
    billing_manager {
      email
      id
    }
    project_usages {
      usage
    }
    jobs(order_by: { updated_at: desc }) {
      id
      status
      job_type
    }
    vercel_integration_connections {
      id
      status
    }
    entitlements {
      entitlement_id
      id
      entitlement {
        config_is_enabled
        config_limit
        name
        type
        id
      }
    }
    current_month_data_usage
    number_of_requests_this_month
  }
`;

export const USER_FRAGMENT = gql`
  fragment UserFields on users {
    id
    email
    onboarding {
      details
      is_onboarded
      target
    }
    customer_id
    billing_address {
      id
      name
      customer_id
      address_line_1
      address_line_2
      pin_code
      city
      state
      country
      last_updated_to_stripe
      updated_at
    }
    feature_accesses {
      email
      feature
    }
    payment_methods {
      id
      status
    }
    providers {
      user_id
    }
    invoice_receipt_email
    invoices(
      where: { status: { _eq: "open" } }
      order_by: { created_at: desc }
    ) {
      attempt_count
      stripe_invoice_id
      invoice_url
      collection_method
      status
    }
    user_profile {
      name
      company
      title
      email
      phone_number
    }
    enterprise_users {
      is_active
    }
    entitlements {
      entitlement {
        name
        type
        config_is_enabled
        config_limit
      }
    }
    projects_aggregate(where: { plan_name: { _eq: "cloud_free_v2" } }) {
      aggregate {
        count
      }
    }
  }
`;

export const PROJECTS_LIST_FRAGMENT = gql`
  fragment ProjectsListFields on projects {
    assigned_at
    billing_manager {
      email
      id
    }
    collaborators(where: { status: { _eq: "active" } }) {
      collaborator_id
      id
      status
      project_collaborator_privileges {
        privilege_slug
      }
    }
    created_at
    current_month_data_usage
    endpoint
    entitlements {
      entitlement {
        config_is_enabled
        config_limit
        name
        type
        id
      }
      entitlement_id
      id
    }
    id
    invoice_attempt_count
    invoice_status
    jobs(order_by: { updated_at: desc }) {
      id
      job_type
      status
    }
    labels {
      label {
        ...LabelFields
      }
    }
    name
    number_of_requests_this_month
    owner {
      id
      email
      enterprise_user {
        is_active
        is_exempt_from_billing
      }
    }
    plan_name
    tenant {
      active
      active_status_reason
      config {
        updated_at
      }
      dedicated_vpc {
        name
        id
      }
      health_status
      id
      major_version
      maintenance_mode
      region_info {
        name
        cloud
      }
      updated_at
    }
    updated_at
    vercel_integration_connections {
      status
    }
  }
`;

export const PROJECT_LIST_BASE_FRAGMENT = gql`
  fragment ProjectsListBaseFields on projects {
    assigned_at
    billing_manager {
      email
      id
    }
    collaborators(where: { status: { _eq: "active" } }) {
      collaborator_id
      id
      status
      project_collaborator_privileges {
        privilege_slug
      }
    }
    created_at
    endpoint
    entitlements {
      entitlement {
        config_is_enabled
        config_limit
        name
        type
        id
      }
      entitlement_id
      id
    }
    id
    jobs(order_by: { updated_at: desc }) {
      id
      job_type
      status
    }
    labels {
      label {
        ...LabelFields
      }
    }
    name
    owner {
      id
      email
    }
    plan_name
    tenant {
      active
      active_status_reason
      config {
        updated_at
      }
      dedicated_vpc {
        name
        id
      }
      health_status
      id
      major_version
      maintenance_mode
      region_info {
        name
        cloud
      }
      updated_at
    }
    updated_at
    vercel_integration_connections {
      status
    }
  }
`;
