import React from 'react';
import { useQuery } from '@tanstack/react-query';

import {
  HASURA_CLOUD_STATUS_ENDPOINT,
  HASURA_CLOUD_STATUS_PAGE,
  TWO_MINUTES_MS,
} from '../../../utils/constants';
import { api } from '../../../utils/request';

type StatusResponse = {
  page: {
    id?: string;
    name?: string;
    url: string;
    time_zone?: string;
    updated_at?: string;
  };
  status: {
    description: string;
  };
};

const defaultResponse: StatusResponse = {
  page: {
    url: HASURA_CLOUD_STATUS_PAGE,
  },
  status: {
    description: 'Status Unknown',
  },
};

type StatusDescription =
  | 'all systems operational'
  | 'system downtime'
  | 'partial outage';

const colorMap: Record<StatusDescription, string> = {
  'all systems operational': '#39DAAA',
  'system downtime': '#EE4447',
  'partial outage': '#FB9147',
};

const getStatusCircleColor = (response?: StatusResponse) => {
  const currentDescription =
    response?.status.description.toLowerCase() as StatusDescription;
  return `${colorMap[currentDescription] ?? '#4D5761'}`;
};

const HasuraCloudStatus = (): JSX.Element => {
  const { data } = useQuery(
    ['cloud-status'],
    async () => {
      try {
        return await api
          .get<StatusResponse>(HASURA_CLOUD_STATUS_ENDPOINT)
          .then(resp => resp.data);
      } catch (err) {
        console.error('error occurred while fetching status data: ', err);
      }
      return defaultResponse;
    },

    {
      refetchIntervalInBackground: true,
      refetchInterval: TWO_MINUTES_MS,
    }
  );

  const onClickStatus = () => {
    window.open(data?.page.url ?? HASURA_CLOUD_STATUS_PAGE, '_blank');
  };

  const lastUpdatedAtString = React.useMemo(() => {
    if (!data?.page.updated_at) {
      return 'unknown';
    }
    return new Date(data.page.updated_at).toLocaleString();
  }, [data?.page.updated_at]);

  return (
    <div
      onClick={onClickStatus}
      className="flex h-1/3 items-center justify-center rounded-[64px] bg-white px-1 py-3 shadow-md hover:cursor-pointer"
      title={`Last updated at: ${lastUpdatedAtString}`}
    >
      <div
        style={{ backgroundColor: `${getStatusCircleColor(data)}` }}
        className={`ml-2 h-[16px] w-[16px] rounded-full`}
      />
      <p className="m-2 font-inter text-[14px] capitalize">
        {data?.status.description}
      </p>
    </div>
  );
};

export default HasuraCloudStatus;
