import React from 'react';

import { StyledSVG } from '../Icon';

export const RollbackIcon = props => {
  const { color } = props;

  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill={color}
      {...props}
    >
      <path
        className="st0"
        d="M13,3c-5,0-9,4-9,9H1l3.9,3.9L5,16l4-4H6c0-3.9,3.1-7,7-7s7,3.1,7,7s-3.1,7-7,7c-1.9,0-3.7-0.8-4.9-2.1
	l-1.4,1.4C8.3,20,10.5,21,13,21c5,0,9-4,9-9S18,3,13,3z M12,8v5l4.2,2.5l0.8-1.3l-3.5-2.1V8H12z"
      />
    </StyledSVG>
  );
};
