/*
  Design Tokens for dashboard
*/

// ********************************** //

const shadows = [
  0,
  '0px 1px 4px rgba(0, 0, 0, 0.2)',
  '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  '0 3px 10px 0 rgba(0, 0, 0, 0.16)',
  '0 7px 24px 0 rgba(0, 0, 0, 0.32)',
  '0px 0px 48px rgba(0, 0, 0, 0.64)',
  '0px 0px 26px rgba(0, 0, 0, 0.1)',
  '4px 0px 30px rgba(0, 0, 0, 0.2)',
  '-6px 0px 20px 1px rgba(0, 0, 0, 0.1)',
  '0px 4px 10px rgba(0, 0, 0, 0.24)',
  '0px 5px 14px rgba(0, 0, 0, 0.15)',
];

shadows.modal = shadows[5];
shadows.form = shadows[6];
shadows.sidebar = shadows[7];
shadows.rightSidebar = shadows[8];
shadows.button = shadows[9];
shadows.dropdown = shadows[10];

// ********************************** //

const borders = [
  0,
  '1px solid',
  '2px solid',
  '3px solid',
  '4px solid',
  '5px solid',
  '1px dashed #AAA',
];

borders.dashed = borders[6];

// ********************************** //

const zIndices = [0, 1, 2, 1000, 1085];

zIndices.card = zIndices[1]; // 1
zIndices.popover = zIndices[2];
zIndices.drawer = zIndices[3]; // 1000
zIndices.notification = zIndices[4]; // 1085

// ********************************** //

const colors = {
  red: {
    original: '#ff0000',
    primary: '#E65678',
    hover: 'rgba(229, 57, 53, 0.4)',
    light: '#f7e9e9',
    error: '#F47E7E',
    errordark: '#D14A4A',
    errorbg: '#FDF2F2',
  },
  green: {
    original: '#008000',
    primary: '#2EB67D',
    hover: 'rgba(123, 179, 66, 0.4)',
    light: '#CAF6D7',
    success: '#7ED321',
  },
  blue: {
    original: '#0000ff',
    primary: '#1EB4D4',
    bg: '#111B29',
    link: '#337ab7',
    linkdark: '#325a82',
    hover: 'rgba(52, 187, 216, 0.4)',
    text: '#5C94C8',
    light: '#D6EBFF',
    lightbg: '#D2F0F6',
    heroku: '#430098',
    intercom: '#2292EC',
    success: '#8DD6D0',
    successdark: '#6AB7B1',
    alert: '#3B5170',
    alertdark: '#1F3452',
    info: '#5C94C8',
    border: '#228dad',
    earlyAccess: 'rgb(202, 231, 246)',
  },
  orange: {
    original: '#ffa500',
    primary: '#FFC267',
    hover: 'rgba(253, 176, 44, 0.4)',
    light: '#FFEBCD',
    dark: '#CDA365',
    warningdark: '#FFC267',
  },
  grey: {
    original: '#888888',
    border: '#ededed',
    bg: '#E5E5E5',
    lightBg: '#EFEFEF',
    light: '#f8f8f8',
    text: '#A8A8A8',
    icon: '#D5D5D5',
    tooltip: '#cecece',
    dark: '#6c6c6c',
    muted: '#5B5B5B',
    mutedSubText: 'rgb(113, 119, 128)',
  },
  black: {
    original: '#000',
    secondary: '#484538',
    text: '#202020',
    hover: 'rgba(0, 0, 0, 0.16)',
  },
  violet: {
    light: 'rgb(240 230 250)',
    dark: 'rgb(124 44 180)',
  },
  white: '#fff',
  sidebar: {
    bg: '#111B29',
    active: '#1B2738',
    text: '#717780',
    footnote: '#3B434E',
  },
};

// ********************************** //

const button = {
  primary: {
    backgroundColor: colors.blue.primary,
    color: colors.white,
  },
  heroku: {
    backgroundColor: colors.white,
    color: colors.blue.heroku,
    borderColor: colors.blue.heroku,
    border: borders[1],
  },
  secondary: {
    backgroundColor: colors.white,
    color: colors.blue.primary,
    borderColor: colors.blue.primary,
    border: borders[1],
  },
  github_secondary: {
    backgroundColor: colors.white,
    color: colors.black.text,
    borderColor: colors.black.original,
    border: borders[1],
  },
  intercom: {
    backgroundColor: colors.white,
    color: colors.blue.intercom,
    borderColor: colors.blue.intercom,
    border: borders[1],
  },
  success: {
    backgroundColor: colors.green.primary,
    color: colors.white,
  },
  danger: {
    backgroundColor: colors.red.error,
    color: colors.white,
  },
  warning: {
    backgroundColor: colors.orange.primary,
    color: colors.white,
  },
  info: {
    backgroundColor: colors.blue.primary,
    color: colors.white,
  },
  cancel: {
    backgroundColor: colors.grey.text,
    color: colors.white,
  },
  usage: {
    backgroundColor: '#1B2738',
    color: colors.blue.primary,
    borderColor: colors.blue.primary,
    border: borders[1],
  },
  revoke: {
    backgroundColor: colors.white,
    color: colors.orange.primary,
    borderColor: colors.orange.primary,
    border: borders[1],
  },
  delete: {
    backgroundColor: colors.white,
    borderColor: colors.red.error,
    color: colors.red.error,
    border: borders[1],
  },
  github: {
    backgroundColor: colors.black.original,
    borderColor: colors.black.original,
    color: colors.white,
    border: borders[1],
  },
  pending: {
    backgroundColor: colors.grey.lightBg,
    color: colors.grey.dark,
  },
  default: {
    backgroundColor: colors.white,
    color: colors.black.text,
  },
};

// ********************************** //

const icon = {
  colors: {
    default: colors.grey.text,
    active: colors.blue.primary,
  },
};

// ********************************** //

const dropdown = {
  owner: {
    bg: colors.green.light,
    text: colors.green.primary,
    message: 'Owner',
  },
  admin: {
    bg: colors.orange.light,
    text: colors.orange.dark,
    message: 'Admin',
  },
  user: {
    bg: colors.blue.light,
    text: colors.blue.text,
    message: 'User',
  },
  billing_owner: {
    bg: colors.violet.light,
    text: colors.violet.dark,
    message: 'Billing Owner',
  },
  guest: {
    bg: colors.grey.bg,
    text: colors.sidebar.text,
    message: 'Invited',
  },
  revoke: {
    bg: '#FFE7EB',
    text: '#E65678',
    message: 'Revoke',
  },
  note: {
    bg: '#FFFFFF',
    text: '#F47E7E',
    message: 'Note',
  },
  heroku: {
    bg: '#FFFFFF',
    text: colors.blue.heroku,
    borderColor: colors.blue.heroku,
    border: borders[1],
    message: 'Heroku',
  },
  professional: {
    bg: colors.blue.light,
    text: colors.blue.text,
    message: 'Professional Tier',
  },
  standard: {
    bg: colors.blue.light,
    text: colors.blue.text,
    message: 'Standard Tier',
  },
  free: {
    bg: colors.blue.light,
    text: colors.blue.text,
    message: 'Free Tier',
  },
  enterprise: {
    bg: colors.blue.light,
    text: colors.blue.text,
    message: 'Enterprise',
  },
  requestEarlyAccess: {
    bg: colors.blue.earlyAccess,
    message: 'Request Early Access',
  },
  default: {
    bg: colors.green.light,
    text: colors.green.primary,
    message: 'Owner',
  },
  manager: {
    bg: colors.orange.light,
    text: colors.orange.dark,
    message: 'Manager',
  },
  viewer: {
    bg: colors.blue.light,
    text: colors.blue.text,
    message: 'Viewer',
  },
};

// ********************************** //

const badge = {
  scale: {
    small: {
      fontSize: '9px',
      width: '56px',
      height: '18px',
      borderRadius: '80px',
      px: '10px',
    },
    medium: {
      fontSize: '9px',
      width: '75px',
      height: '18px',
      borderRadius: '80px',
      px: '10px',
    },
    large: {
      fontSize: '12px',
      width: '104px',
      height: '32px',
      borderRadius: '80px',
      px: '10px',
    },
    sidebar: {
      fontSize: '10px',
      width: '40px',
      height: '20px',
      borderRadius: '4px',
      fontWeight: '700',
      ml: '8px',
    },
  },
  owner: {
    bg: colors.green.light,
    text: colors.green.primary,
  },
  admin: {
    bg: colors.orange.light,
    text: colors.orange.dark,
  },
  user: {
    bg: colors.blue.light,
    text: colors.blue.text,
  },
  billing_owner: {
    bg: colors.violet.light,
    text: colors.violet.dark,
    message: 'Billing Owner',
  },
  guest: {
    bg: colors.grey.bg,
    text: colors.sidebar.text,
  },
  revoke: {
    bg: '#FFE7EB',
    text: '#E65678',
  },
  beta: {
    text: '#fff',
    lightModeText: '#48b5d5',
    bg: '',
  },
  new: {
    text: '#fff',
    lightModeText: '#48b5d5',
    bg: '',
  },
  default: {
    bg: colors.green.light,
    text: colors.green.primary,
    lightModeText: '#48b5d5',
    borderColor: '#48b5d5',
  },
};

// ********************************* //

const breakpoints = ['50rem', '100rem', '121rem', '163rem'];

// 1600px ~ for a 1080 Monitor
breakpoints.lg = breakpoints[1];

// 1936px ~ for 2K / 4K Monitor
breakpoints.xl = breakpoints[2];

// Real 4K ~ (3840 x 2160) Resolution
breakpoints.xxl = breakpoints[3];

export const theme = {
  colors,
  shadows,
  borders,
  button,
  icon,
  dropdown,
  badge,
  zIndices,
  breakpoints,
};
