import { APP_NAME, AUTH_METHODS } from './constants';

export const projectPrefix = '/project';

export const vpcPrefix = '/vpc';
const accountsRoot = '/account-settings';
const supportRoot = '/support';

export type ProjectsTab =
  | 'details'
  | 'env-vars'
  | 'alerting'
  | 'team'
  | 'collaborators'
  | 'domains'
  | 'monitoring'
  | 'usage'
  | 'integrations'
  | 'uptime'
  | 'git-deployment';

export const projectTabs: Record<ProjectsTab, ProjectsTab> = {
  details: 'details',
  'env-vars': 'env-vars',
  alerting: 'alerting',
  team: 'team',
  collaborators: 'collaborators',
  domains: 'domains',
  monitoring: 'monitoring',
  usage: 'usage',
  integrations: 'integrations',
  uptime: 'uptime',
  'git-deployment': 'git-deployment',
};

export type VPCTab = 'peering' | 'projects' | 'details' | 'collaborators';

export type AccountsTab =
  | 'account-details'
  | 'invitations'
  | 'access-tokens'
  | 'connections';

export const accountTabs: Record<AccountsTab, AccountsTab> = {
  'account-details': 'account-details',
  connections: 'connections',
  invitations: 'invitations',
  'access-tokens': 'access-tokens',
};

export type SupportTab = 'tickets' | 'create-ticket';

export const supportTabs: Record<SupportTab, SupportTab> = {
  tickets: 'tickets',
  'create-ticket': 'create-ticket',
};

export const paths = {
  root: () => '/',
  rootWithChildren: () => '/*',
  projects: () => '/projects',
  project: (projectId: string) => {
    const prefix = `${projectPrefix}/${projectId}`;

    return {
      root: () => prefix,
      details: () => `${prefix}/${projectTabs.details}`,
      envVars: () => `${prefix}/${projectTabs['env-vars']}`,
      alerts: () => `${prefix}/${projectTabs.alerting}`,
      monitoring: () => `${prefix}/${projectTabs.monitoring}`,
      collaborators: () => `${prefix}/${projectTabs.collaborators}`,
      domains: () => `${prefix}/${projectTabs.domains}`,
      usage: () => `${prefix}/${projectTabs.usage}`,
      integrations: () => `${prefix}/${projectTabs.integrations}`,
      uptime: () => `${prefix}/${projectTabs.uptime}`,
      gitDeployment: () => `${prefix}/${projectTabs['git-deployment']}`,
      commitView: (jobId: string) =>
        `${prefix}/${projectTabs['git-deployment']}/${jobId}`,
    };
  },
  account: () => ({
    root: () => accountsRoot,
    details: () => `${accountsRoot}/${accountTabs['account-details']}`,
    accessTokens: () => `${accountsRoot}/${accountTabs['access-tokens']}`,
    connections: () => `${accountsRoot}/${accountTabs.connections}`,
  }),
  templates: () => ({
    project: () => `${projectPrefix}/:id/:activeTab/:jobId?`,
    account: () => `${accountsRoot}/:activeTab`,
    support: () => `${supportRoot}/:activeTab`,
    vpc: () => `${vpcPrefix}/:id/:activeTab`,
  }),
  dedicatedVPCS: () => '/vpcs',
  dedicatedVPC: (vpcId: string) => {
    const prefix = `${vpcPrefix}/${vpcId}`;
    return {
      root: () => prefix,
      projects: () => `${prefix}/projects`,
      settings: () => `${prefix}/settings`,
      peering: () => `${prefix}/peering`,
      vpcDetails: () => `${prefix}/details`,
      vpcCollaborators: () => `${prefix}/collaborators`,
    };
  },
  billing: () => '/billing',
  // support: () => '/support',
  support: () => ({
    root: () => supportRoot,
    tickets: () => `${supportRoot}/${supportTabs.tickets}`,
    createTicket: () => `${supportRoot}/${supportTabs['create-ticket']}`,
  }),
  yearReview: () => '/hasura-rewind',
  userAssumptionMenu: () => '/user-assumption',
  login: () => '/login',
  ssoLogin: () => '/login/sso',
  adminLogin: () => '/login/admin',
  signup: () => '/signup' as const,
  waitlist: () => '/waitlist',
  welcome: () => '/welcome',
  oneclickdeployment: () => '/deploy',
  oauthError: () => '/error',
  oauthConsent: () => '/oauth2/tnc-consent',
  newProject: () => '/new-project',
  newSelfHostedProject: () => '/new-self-hosted-project',
  recover: () => '/recover/end',
  callbacks: () => ({
    heroku: () => '/heroku-callback',
    neon: () => '/neon-integration/callback',
    slack: () => '/slack-integration/callback',
    confirm: () => '/confirm',
    consent: () => '/consent',
    emailChange: () => '/change-email',
    githubIntegrationOauth: () => '/github-integration-oauth-callback',
    githubPostInstallation: () => '/github-post-installation-callback',
    azureAuthorizationConfirmation: () => '/azure-authorization-confirmation',
  }),
  vercel: () => ({
    login: () => '/integrations/vercel/login',
    sso: () => '/integrations/vercel/sso',
    signup: () => '/integrations/vercel/signup',
    emailLogin: () => '/integrations/vercel/email-login',
    resetPassword: () => '/integrations/vercel/reset-password',
    setup: () => '/integrations/vercel/setup',
  }),
  preview: () => '/preview-apps-yaml-generator',
  ssoConfig: () => '/configure-sso',
  enterprise: () => '/enterprise',
  v3: {
    login: {
      sso: () => '/signup/sso' as const,
      forgotPassword: () => '/signup/forgot' as const,
      email: () => '/signup/email' as const,
    },
    register: {
      newUserCloud: () => '/signup/new_user' as const,
    },
  },
} as const;

export const getRouteHelmetTitle = (pathname: string) => {
  if (pathname === paths.projects()) {
    return `Projects - ${APP_NAME}`;
  }
  if (
    [
      paths.account().root(),
      paths.account().accessTokens(),
      paths.account().details(),
    ].includes(pathname)
  ) {
    return `Account Settings - ${APP_NAME}`;
  }
  if (pathname.startsWith(projectPrefix)) {
    return `Projects - ${APP_NAME}`;
  }
  if (pathname === paths.billing()) {
    return `Billing - ${APP_NAME}`;
  }
  if (
    pathname === paths.login() ||
    pathname === paths.signup() ||
    AUTH_METHODS === 'saml'
  ) {
    return `Login | Signup - ${APP_NAME}`;
  }
  if (pathname === paths.waitlist()) {
    return `Waitlist - ${APP_NAME}`;
  }
  if (pathname === paths.welcome()) {
    return `Welcome to ${APP_NAME}`;
  }
  if (pathname === paths.preview()) {
    return `Preview Project Generator`;
  }

  return APP_NAME;
};

export const getProjectHelmet = (projectName: string) =>
  `${projectName} - Projects - ${APP_NAME}`;

export const unProtectedRoutes = {
  v3: [
    paths.v3.login.sso(),
    paths.v3.register.newUserCloud(),
    paths.v3.login.forgotPassword(),
    paths.v3.login.email(),
    paths.signup(),
  ] as const,
  v2: [
    '/oauth2_error',
    paths.oauthError(),
    paths.login(),
    paths.recover(),
    paths.callbacks().confirm(),
    paths.ssoLogin(),
    paths.adminLogin(),
    paths.vercel().login(),
    paths.vercel().sso(),
    paths.vercel().signup(),
    paths.vercel().emailLogin(),
    paths.vercel().resetPassword(),
    paths.oauthConsent(),
  ],
};

// string union of all unProtectedRoutes
export type UnProtectedRoute = (typeof unProtectedRoutes)['v3'][number];

export const isUnProtectedRoute = (page: string) =>
  unProtectedRoutes.v2.includes(page) ||
  unProtectedRoutes.v3.includes(page as UnProtectedRoute);

export const createQueryParamString = (
  params: {
    name: string;
    override?: {
      value: any;
    };
  }[]
) => {
  return params
    .map(param => {
      if (param.override?.value)
        return `${param.name}=${encodeURI(param.override.value)}`;

      const param_value =
        new URLSearchParams(window.location.search)?.get(param.name) ?? null;

      if (!param_value) return null;

      return `${param.name}=${param_value}`;
    })
    .filter(Boolean)
    .join('&');
};
