import React from 'react';

import { StyledSVG } from '../Icon';

export const LeftArrowIcon = props => {
  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="17"
      viewBox="0 0 10 17"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.06065 8.5L9.53032 2.03033L8.46966 0.969666L0.939331 8.5L8.46966 16.0303L9.53032 14.9697L3.06065 8.5Z"
      />
    </StyledSVG>
  );
};

LeftArrowIcon.defaultProps = {
  fill: '#202020',
};
