import React, { useEffect, useState } from 'react';

import { StyledSlider, StyledSwitchButton } from './Switch';

export const Switch = props => {
  const { children, htmlFor, isEnabled } = props;
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(isEnabled);
  }, [isEnabled]);

  const changeHandler = () => {
    setIsChecked(!isChecked);

    if (isChecked && props.onDeactive) {
      props.onDeactive();
    } else if (!isChecked && props.onActive) {
      props.onActive();
    }
  };

  return (
    <StyledSwitchButton {...props}>
      <label htmlFor={htmlFor}>
        <input
          type="checkbox"
          name={htmlFor}
          checked={isChecked}
          onChange={changeHandler}
        />
        <StyledSlider checked={isChecked} />
        {children}
      </label>
    </StyledSwitchButton>
  );
};
