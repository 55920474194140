export const hasuraCon23BannerLS = {
  isDismissed: () =>
    window.localStorage.getItem('hasuracon-23-banner-dismissed') === 'true',
  dismiss: () =>
    window.localStorage.setItem('hasuracon-23-banner-dismissed', 'true'),
};

export const hasuraDdnLaunchBannerLS = {
  isDismissed: () =>
    window.localStorage.getItem('hasura-ddn-launch-banner-dismissed') ===
    'true',
  dismiss: () =>
    window.localStorage.setItem('hasura-ddn-launch-banner-dismissed', 'true'),
};
