/* eslint-disable no-useless-escape */

import { useEffect, useState } from 'react';
import queryString from 'query-string';
import { Location, NavigateFunction } from 'react-router-dom';

import { authEndpoints } from './constants';
import { paths } from './routeUtils';
import { UserInfo } from './types';

export const parseQueryString = (search: string) => {
  return queryString.parse(search);
};

export const parseOauthFlowError = (resp: any) => {
  let errMessage = '';
  if (
    'error' in resp &&
    typeof resp.error !== 'string' &&
    'Payload' in resp.error
  ) {
    errMessage =
      ('error_debug' in resp.error.Payload && resp.error.Payload.error_debug) ||
      resp.error.Payload.error;
  } else {
    errMessage = 'Unexpected error. Please try again!';
  }
  return errMessage;
};

const parseAuthResponse = (err: Record<string, any>) => {
  const errString = `Encountered following issues\n`;
  let respErr = '';
  Object.keys(err).forEach(key => {
    if (!respErr) {
      respErr = `${errString}\t - ${key} - ${err[key].join(',')}`;
    } else {
      respErr = `${respErr}\n\t - ${key} - ${err[key].join(',')}`;
    }
  });
  return respErr;
};

const acceptLoginRequest = (url: string) => {
  const options: RequestInit = {
    credentials: 'include',
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({}),
  };
  return fetch(url, options)
    .then(resp => {
      if (resp.status < 400) {
        // Mark as success
        return resp.json();
      }
      throw new Error('Sign in attempted with an invalid token');
    })
    .catch(err => {
      return Promise.reject(err);
    });
};

const verifyLoginRequest = (
  parsedQueryString: any,
  navigate: NavigateFunction
) => {
  acceptLoginRequest(
    authEndpoints.acceptLogin(parsedQueryString.login_challenge)
  )
    .then(resp => {
      if (resp.status !== 'failure') {
        if ('location' in resp) {
          window.location.href = resp.location;
        }
      } else {
        return Promise.reject(parseOauthFlowError(resp));
      }
      return null;
    })
    .catch(err => {
      navigate({
        pathname: paths.oauthError(),
        search: `?error_description=${err.message}`,
      });
    });
};

const authRedirect = (
  navigate: NavigateFunction,
  location: Location,
  url: string
) => {
  const { search } = location;
  const parsedQueryString = parseQueryString(search);
  if ('login_challenge' in parsedQueryString) {
    verifyLoginRequest(parsedQueryString, navigate);
  } else if ('redirect_url' in parsedQueryString) {
    /* Constructs a new url with url if it is absolute and otherwise uses the second parameter as BASE */
    if (url) {
      navigate(url);
    } else {
      const redirectTo: any = parsedQueryString.redirect_url;
      const absUrlChecker = /^https?:\/\//i;
      if (absUrlChecker.test(redirectTo)) {
        // TODO: Redirect to '/' for now
        navigate({
          pathname: paths.projects(),
        });
      } else {
        navigate(redirectTo);
      }
    }
  } else {
    navigate({
      pathname: paths.projects(),
    });
  }
};

export const isLoggedIn = (userInfo: UserInfo | null) => {
  if (!userInfo) return false;

  return (
    ('X-Hasura-Role' in userInfo &&
      userInfo['X-Hasura-Role'] !== 'anonymous') ??
    false
  );
};

export const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
};

export const isValidDomain = (domain: string) => {
  const re = new RegExp(
    /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,20})$/
  );
  return domain.match(re);
};

export const generateRandomString = (stringLength = 16) => {
  const allChars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let str = '';

  for (let i = 0; i < stringLength; i++) {
    const randomNum = Math.floor(Math.random() * allChars.length);
    str += allChars.charAt(randomNum);
  }
  return str;
};

export const validateEmail = (email: string) => {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !!email.match(emailRegex);
};

export const validatePostgresURL = (url: string) => {
  try {
    const parsed = new URL(url);
    if (parsed.protocol === 'postgres:' || parsed.protocol === 'postgresql:') {
      return true;
    }
    return false;
  } catch {
    return false;
  }
};

export const validateWildcardDomain = (domain: string) => {
  if (domain === '*') {
    return true;
  }
  const replacedWildcard = domain.replace('*', 'wildcard');
  if (!isValidUrl(replacedWildcard)) {
    return false;
  }
  return true;
};

export const validateJWT = (jwtString: string) => {
  try {
    const parsed = JSON.parse(jwtString);
    if (
      parsed.type &&
      typeof parsed.type === 'string' &&
      parsed.key &&
      typeof parsed.key === 'string'
    ) {
      return true;
    }
    if (parsed.jwk_url && isValidUrl(parsed.jwk_url)) {
      return true;
    }
  } catch {
    return false;
  }
  return false;
};

const stripDanglingCharacters = (str: string, char: string) => {
  let sanitised = str;
  if (sanitised && sanitised[sanitised.length - 1] === char) {
    sanitised = sanitised.substr(0, sanitised.length - 1);
  }
  if (sanitised && sanitised[0] === ',') {
    sanitised = sanitised.substr(1);
  }
  return sanitised;
};

export const stripDanglingCommas = (str: string) => {
  return stripDanglingCharacters(str, ',');
};

export const stripTrailingSlash = (str: string) => {
  return stripDanglingCharacters(str, '/');
};

export const getJWTValidationError = (jwtString: string) => {
  try {
    const parsed = JSON.parse(jwtString);
    if (parsed.jwk_url) {
      if (isValidUrl(parsed.jwk_url)) {
        return null;
      }
      return 'JWT_ERROR_JWK_URL';
    }

    if (parsed.type) {
      if (!parsed.key) {
        return 'JWT_ERROR_MISSING_KEY';
      }
      if (typeof parsed.type !== 'string') {
        return 'JWT_ERROR_INVALID_TYPE';
      }
    }

    if (parsed.key) {
      if (!parsed.type) {
        return 'JWT_ERROR_MISSING_TYPE';
      }
      if (typeof parsed.key !== 'string') {
        return 'JWT_ERROR_INVALID_KEY';
      }
    }

    if (!parsed.key && !parsed.type && !parsed.jwk_url) {
      return 'JWT_ERROR_MISSING_MANDATORY_FIELDS';
    }

    return null;
  } catch {
    return 'JWT_ERROR_INVALID_JSON';
  }
};

const BASE_PASSWORD_REGEX =
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()+-])';

export const MODERATE_LENGTH_PASSWORD_REGEX = `${BASE_PASSWORD_REGEX}(?=.{8,})`;

export const STRONG_PASSWORD_REGEX = `${BASE_PASSWORD_REGEX}(?=.{12,}$)`;

export const PASSWORD_VALIDATION = new RegExp(MODERATE_LENGTH_PASSWORD_REGEX);

export const STRONG_PASSWORD_VALIDATION = new RegExp(STRONG_PASSWORD_REGEX);

export const getPasswordStrength = (password: string) => {
  // too long password
  if (password.length > 64) {
    return 99;
  }

  // strong password
  if (STRONG_PASSWORD_VALIDATION.test(password)) {
    return 2;
  }

  // moderate password
  if (PASSWORD_VALIDATION.test(password)) {
    return 1;
  }

  return 0;
};

export const isValidPassword = (password: string) => {
  return [1, 2].includes(getPasswordStrength(password));
};

export const getComponentKeyFromIndex = (
  label: string,
  index: string | number
) => {
  return `${label}-${index}`;
};

export const getSearchParam = (search: string, param: string) => {
  try {
    const params = new URLSearchParams(search);
    return params.get(param) || null;
  } catch {
    return null;
  }
};

// sets a search Param and returns the new search string
export const setSearchParam = (
  existingSearch: string,
  param: string,
  value: string
) => {
  const params = new URLSearchParams(existingSearch);
  params.set(param, value);
  return params.toString();
};

// deletes a search param and returns the new search string
export const deleteSearchParam = (existingSearch: string, param: string) => {
  const params = new URLSearchParams(existingSearch);
  params.delete(param);
  return params.toString();
};

export const getPathWithSearchParam = (
  location: Location,
  param: string,
  value: string
): Location => {
  const search_params = new URLSearchParams();
  search_params.set(param, value);
  return {
    ...location,
    search: search_params.toString(),
    pathname: location.pathname,
  };
};

export const base64decode = (encoded?: string) => {
  if (!encoded) {
    return '';
  }
  try {
    const decoded = atob(encoded);
    return decoded;
  } catch {
    return '';
  }
};

export const refreshPage = () => {
  window.location.replace(window.location.href);
};

export const splitBy = <T = any>(
  items: T[],
  predicate: (item: T) => boolean
): T[][] => {
  if (!items?.length) {
    return [[], []];
  }

  return items.reduce(
    ([listT, listF], item) => [
      predicate(item) ? [...listT, item] : listT,
      !predicate(item) ? [...listF, item] : listF,
    ],
    [[], []] as T[][]
  );
};

export const getStringFromAnObject = (jsonValue: any): string => {
  return jsonValue
    ? JSON.stringify(jsonValue)
        .replace(/{/g, '')
        .replace(/}/g, '')
        .replace(/"/g, '')
    : '';
};

export const getObjectFromAString = (str: string) => {
  const regExCheck = /[{}]/;

  // Not allowing user to enter object {} format
  if (regExCheck.test(str)) {
    return null;
  }

  if (str.includes(':')) {
    let customObj: any = {};

    const filteredStr = str.replace(/"/g, '').replace(/'/g, '');

    const splitStringArr = filteredStr.split(',');

    splitStringArr.forEach(x => {
      if (x.trim()) {
        // It'll remove the first : from a string (LHS)
        const a = x.split(/:(.+)/);

        const key = a && a[0]?.trim();

        const value = a && a[1]?.trim();

        if (!key || !value) {
          customObj = null;

          return;
        }

        customObj[key] = value;
      }
    });

    return customObj;
  }

  return null;
};

export { parseAuthResponse, authRedirect };

export const scrollToTop = () => window.scrollTo(0, 0);

export function useWindowSize() {
  const [windowWidth, setWindowSize] = useState<number | null>(null);

  useEffect(() => {
    function handleResize() {
      setWindowSize(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowWidth;
}

export function requestDesktopSite() {
  const viewPortElement: HTMLMetaElement | null = document.querySelector(
    'meta[name="viewport"]'
  );

  if (viewPortElement && viewPortElement.content) {
    viewPortElement.content = `width=device-width, initial-scale=0.3`;
  }
}

export const getParentDomain = (domain: string) => {
  const splitArray = domain.split('.');
  const isTopLevelDomain = splitArray[splitArray.length - 1].length >= 3; // top level domains end with .com, .org, .net etc
  const isSecondLevelDomain = splitArray[splitArray.length - 2].length === 2;

  if (
    (splitArray.length > 2 && isTopLevelDomain) ||
    (splitArray.length > 3 && isSecondLevelDomain) || // for domains similar to .co.in
    (splitArray.length > 2 && !isTopLevelDomain && !isSecondLevelDomain) // for domains similar to api.hasura.io
  ) {
    return domain.split('.').slice(1).join('.');
  }
  return domain;
};

export function debounce(func: (...args: any[]) => void, wait: number) {
  let timeout: number;
  return function (...args: any[]) {
    clearTimeout(timeout);
    timeout = window.setTimeout(() => func(...args), wait);
  };
}

export function throttle(fn: (...args: any[]) => void, wait: number) {
  let lastHit: number;
  return function (...args: any[]) {
    const now = new Date().getTime();
    if (lastHit && now < lastHit + wait) {
      return;
    }
    lastHit = now;
    fn(...args);
  };
}

function kebabCasetoCamelCase(str: string) {
  if (str.startsWith('-')) str = str.substring(1);
  let res = '';
  let shouldUpperCase = false;
  for (const char of str) {
    if (shouldUpperCase) {
      res += char.toUpperCase();
      shouldUpperCase = false;
    } else if (char === '-') {
      shouldUpperCase = true;
    } else res += char;
  }
  return res;
}

export function styleFromString(str: string) {
  return Object.fromEntries(
    str
      .split(';')
      .map(pair => pair.trim())
      .map(pair =>
        pair
          .split(':')
          .map((word, i) =>
            (i === 0 ? kebabCasetoCamelCase(word) : word).trim()
          )
      )
  );
}

export const isHasuraDomain = (email: string) => {
  return email.endsWith('@hasura.io');
};

export function doesStringContainHTML(str: string) {
  const dangerousCharacters = /[ `&;'"\\,<>\/]/;
  return dangerousCharacters.test(str);
}
