import React, { CSSProperties } from 'react';
import { BarLoader } from 'react-spinners';
import styled from 'styled-components';

const overrides: CSSProperties = {
  display: 'block',
  position: 'fixed',
  top: 0,
};

const Loader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
`;

const NetworkLoader: React.FC<{ show?: boolean }> = ({ show = true }) => {
  const [width, setWidth] = React.useState<number | null>(null);
  const updateWindowWidth = () => {
    setWidth(window.innerWidth);
  };
  React.useEffect(() => {
    updateWindowWidth();
    window.addEventListener('resize', updateWindowWidth);
    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  return show ? (
    <Loader id="network-loader">
      <BarLoader
        cssOverride={overrides}
        height={5}
        color="#1DB4D4"
        loading
        width={width || 1920}
      />
    </Loader>
  ) : null;
};

export default NetworkLoader;
