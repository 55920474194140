import React from 'react';
import { useNavigate } from 'react-router-dom';

import { paths } from '../../utils/routeUtils';
import { parseGithubURL } from '../Dashboard/OneClickDeploy/utils';

export function useValidateOneClickDeploymentUrlAuth() {
  const navigate = useNavigate();

  const [oneClickDeployTitle, setOneClickDeployTitle] = React.useState('');
  const [oneClickDeployGithubURL, setOneClickDeployGithubURL] =
    React.useState('');
  const [oneClickDeployBranch, setOneClickDeployBranch] = React.useState('');
  const [oneClickDeployHasuraDir, setOneClickDeployHasuraDir] =
    React.useState('');

  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    const redirectURL = searchParams.get('redirect_url') || '/';

    const redirectURLPath = new URL(redirectURL, window.location.origin);
    const redirectURLPathSearchParams = new URLSearchParams(
      redirectURLPath.search
    );

    const oneClickDeployGithubURL =
      redirectURLPathSearchParams.get('github_repo') || '';
    const hasuraDirectory = redirectURLPathSearchParams.get('hasura_dir') || '';
    const repoBranch = redirectURLPathSearchParams.get('branch') || '';

    setOneClickDeployGithubURL(oneClickDeployGithubURL.replace(/^\/|\/$/g, ''));
    setOneClickDeployBranch(repoBranch);
    setOneClickDeployHasuraDir(hasuraDirectory.replace(/^\/|\/$/g, ''));

    const githubTitle = parseGithubURL(oneClickDeployGithubURL);

    // Check for valid One Click Deployment Params, redirect if not correct
    if (
      (oneClickDeployGithubURL && !hasuraDirectory) ||
      (!oneClickDeployGithubURL && hasuraDirectory) ||
      (oneClickDeployGithubURL && hasuraDirectory && !githubTitle)
    ) {
      navigate(paths.signup());
    }

    if (githubTitle && hasuraDirectory) {
      setOneClickDeployTitle(githubTitle);
    }
  }, []);

  return [
    oneClickDeployTitle,
    oneClickDeployGithubURL,
    oneClickDeployBranch,
    oneClickDeployHasuraDir,
  ];
}
