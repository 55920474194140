import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { css } from 'styled-components/macro';

import {
  AUTH_METHODS,
  authEndpoints,
  EE_LITE_MODE,
  EE_MODE,
  HASURA_PRIVACY_POLICY_LINK,
  HASURA_TERMS_AND_CONDITIONS_LINK,
} from '../../utils/constants';
import {
  getSearchParam,
  parseQueryString,
  scrollToTop,
} from '../../utils/helpers';
import { persistSkipConsoleOnboarding } from '../../utils/localStorage';
import NetworkLoader from '../../utils/NetworkLoader';
import { paths } from '../../utils/routeUtils';
import HasuraLogo from '../Dashboard/common/HasuraLogo';
import Building from '../images/building-blue.svg';
import Key from '../images/key.svg';
import { NotificationsContext } from '../Notifications';
import { ConfigSecretForm } from '../SSOConfig/ConfigSecretForm';
import { Box, Button, Flex, Heading, Icon, Text } from '../UIKit';
import { CloudPrivacyPolicy } from './CloudPrivacyPolicy';
import { CreateEmailAccount } from './CreateEmailAccount';
import { ForgotPassword } from './ForgotPassword';
import { HasuraFeatures } from './HasuraFeatures';
import { HasuraUsersHighlight } from './HasuraUsersHighlight';
import { LandingLayout, LoginLayout } from './Layout';
import { LoginForm } from './LoginForm';
import { LoginHeader } from './LoginHeader';
import { SignupForm } from './SignupForm';
import { SocialAuth } from './SocialAuth';
import SSOForm from './SSOForm';

export { LoginLayout };

interface LoginProps {
  loginCallback: (loginRedirectState: any) => void;
  isLoggedIn?: boolean;
}

interface OptionsProps {
  credentials: 'include';
  body: string;
  method: string;
  headers: {
    'content-type': string;
  };
}

const confirmEmailUrl = authEndpoints.confirmEmail;

export const LegacyLoginPage: React.FC<LoginProps> = ({
  loginCallback,
  isLoggedIn,
}) => {
  const navigate = useNavigate();

  const [isSignupFormActive, toggleSignupFormStatus] = useState<boolean>(false);

  const [isForgotPasswordFormActive, toggleForgotPasswordForm] =
    useState<boolean>(false);

  const [isTNCChecked, toggleIsTNCChecked] = useState<boolean>(false);

  // Saving email for forgot Password tab
  const [loginEmail, changeLoginEmail] = useState<string>('');

  const { pathname, search } = location;

  const isOnlySSOLogin = AUTH_METHODS === 'saml';

  const isLogin = pathname === paths.login();

  const isSignup = pathname === paths.signup();

  const isSSOLogin = pathname === paths.ssoLogin();

  const isAdminLogin = pathname === paths.adminLogin();

  const OAuthAnonymousId = getSearchParam(location.search, 'anonymousId');

  const { showErrorNotification, showSuccessNotification } =
    useContext(NotificationsContext);

  const parsed = parseQueryString(search);

  //for skipping console onboarding via URL params, the LS_KEY will get cleared from the console
  useEffect(() => {
    const skipOnboarding = parsed.skip_onboarding || 'false';
    if (skipOnboarding === 'true') {
      persistSkipConsoleOnboarding(skipOnboarding as string);
    }
  }, []); // passing an empty array as the second argument

  useEffect(() => {
    if (isLoggedIn) {
      if ('login_challenge' in parsed) {
        return;
      }
      navigate(paths.projects());
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (!isForgotPasswordFormActive) changeLoginEmail('');
    scrollToTop();
  }, [pathname, isForgotPasswordFormActive, isSignupFormActive]);

  const getRedirectUrl = () => {
    if ('redirect_url' in parsed) {
      return parsed.redirect_url;
    }
    if ('login_challenge' in parsed) {
      return new URL(window.location.href).toString();
    }
    return '/';
  };

  const uri = getRedirectUrl();

  const redir = window.encodeURIComponent(uri as 'string');

  const handleResendEmail = (
    email: string,
    setError: (err: string) => void
  ) => {
    const confirmBody = {
      email,
    };

    const options: OptionsProps = {
      credentials: 'include',
      body: JSON.stringify(confirmBody),
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
    };
    fetch(confirmEmailUrl, options)
      .then(resp => resp.json())
      .then(resp => {
        if ('status' in resp && resp.status === 'failure') {
          if (resp.message.includes('conformation mail limit is reached')) {
            setError(
              `You've reached the email confirmation limit. Please contact support to confirm your account.`
            );
          } else {
            showErrorNotification(
              `Error re-sending confirmation email: ${resp.message}`
            );
          }
        }
        if ('status' in resp && resp.status === 'success') {
          showSuccessNotification(
            `Successfully re-sent confirmation email.`,
            null,
            'center'
          );
        }
        return null;
      })
      .catch(err => {
        showErrorNotification(
          `Error re-sending confirmation email: ${err.message}`,
          null,
          'center'
        );
      });
  };

  const showSignupForm = () => {
    toggleSignupFormStatus(true);
  };

  if (isLoggedIn) {
    return <NetworkLoader />;
  }

  const getSocialLoginAuth = () => {
    const handleSignInWithEmail = () => {
      const redirectTo = `${paths.login()}?redirect_url=${redir}`;

      navigate(redirectTo);
    };

    const handleSignInWithSSO = () => {
      const redirectTo = `${paths.ssoLogin()}?redirect_url=${redir}`;

      navigate(redirectTo);
    };

    const handleSignInAsAdmin = () => {
      const redirectTo = `${paths.adminLogin()}?redirect_url=${redir}`;

      navigate(redirectTo);
    };

    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems={EE_LITE_MODE ? 'center' : 'flex-start'}
        pt="5%"
        css={css`
          /* Small Mobile */
          @media screen and (max-width: 340px) {
            p {
              font-size: 0.6875rem;
            }
          }

          @media screen and (max-width: 750px) {
            #m-mobile-heading {
              font-size: 1.35rem;
              width: 100%;
              text-align: center;
            }
          }
        `}
      >
        {/* Mobile UI */}
        <Flex
          width="100%"
          justifyContent="center"
          margin="25px 0 35px"
          id="m-hasura-logo"
        >
          <HasuraLogo />
        </Flex>
        {EE_LITE_MODE ? (
          <div className="mb-3 w-full">
            <label className="text-2xl font-semibold">
              Configure Hasura SSO
            </label>
          </div>
        ) : (
          <Heading
            fontFamily="Poppins"
            mb="5%"
            width="auto"
            fontSize={{ _: '1.5em', lg: '1.1em', xxl: '0.8em' }}
            id="m-mobile-heading"
          >
            Start Your Hasura Project
          </Heading>
        )}

        {EE_LITE_MODE && (
          <>
            <button
              onClick={handleSignInAsAdmin}
              type="button"
              className="mb-3 h-16 w-full rounded border border-cloudBlue py-sm text-base font-semibold text-cloudBlue outline-none hover:border-brandBlue-400 hover:shadow focus:border-brandBlue-400 focus:shadow-none focus:ring-1 focus:ring-brandBlue-400"
            >
              <img
                src={Key}
                className="fa-solid fa-wand-magic-sparkles mr-1.5 inline-block h-3.5 w-3.5 text-cloudBlue"
              ></img>
              Sign in with config secret
            </button>

            <button
              type="button"
              onClick={handleSignInWithSSO}
              className="mb-3 h-16 w-full rounded border border-cloudBlue text-base font-semibold text-cloudBlue outline-none hover:border-brandBlue-400 hover:shadow focus:border-brandBlue-400 focus:shadow-none focus:ring-1 focus:ring-brandBlue-400 disabled:cursor-not-allowed disabled:border-slate-400 disabled:text-gray-400 disabled:text-opacity-50"
            >
              <img
                src={Building}
                alt="building_icon"
                className="fa-solid fa-wand-magic-sparkles mr-1.5 inline-block h-3.5 w-3.5 disabled:text-gray-400"
              />
              Sign in with SSO
            </button>
          </>
        )}

        {!EE_LITE_MODE && (
          <>
            <Flex width="100%">
              <SocialAuth redir={redir} />
            </Flex>
            <Flex width="100%" justifyContent="center" mb="3%">
              <Icon type="enterprise" color="black" size="16px" mr="2px" />
              <Flex width="auto">
                <Text fontSize="0.8rem" nowrap>
                  Want to continue with your org?&nbsp;
                </Text>
                <Button
                  variant="div"
                  id="sign-in-with-sso-btn"
                  onClick={handleSignInWithSSO}
                >
                  <Text
                    color="red.primary"
                    fontSize="0.8rem"
                    hover="underline"
                    mr="-6px"
                  >
                    Sign in with SSO
                  </Text>
                </Button>
              </Flex>
            </Flex>
            <Flex
              justifyContent="space-between"
              borderTop={1}
              borderColor="grey.bg"
              pt="5px"
              mt="25px"
            >
              <CreateEmailAccount
                showSignupForm={showSignupForm}
                fontSize="0.8rem"
              />
              <Flex width="auto">
                <Text fontSize="0.8rem">Have an email account? </Text>
                &nbsp;
                <Button
                  variant="div"
                  id="sign-in-with-email-btn"
                  onClick={handleSignInWithEmail}
                >
                  <Text
                    color="red.primary"
                    fontSize="0.8rem"
                    hover="underline"
                    mr="-6px"
                  >
                    Sign In
                  </Text>
                </Button>
              </Flex>
            </Flex>
          </>
        )}
      </Flex>
    );
  };

  const getLoginForm = () => {
    if (isForgotPasswordFormActive) {
      return <ForgotPassword loginEmail={loginEmail} />;
    }

    return (
      <LoginForm
        redir={redir}
        loginCallback={loginCallback}
        handleResendEmail={handleResendEmail}
        toggleForgotPasswordForm={toggleForgotPasswordForm}
        toggleSignupFormStatus={toggleSignupFormStatus}
        changeLoginEmail={changeLoginEmail}
      />
    );
  };

  const getSocialLoginTextSection = () => {
    const handleSocialLoginText = () => {
      if (!isSignup) {
        navigate({
          pathname: paths.signup(),
          search: search,
        });
        // NOTE: to re-load the page
        navigate(0);
      }
    };

    if (!isForgotPasswordFormActive) {
      return (
        <Flex width="100%" justifyContent="center" mb="10px">
          <Text fontSize="0.875rem" pr="5px" noWrap>
            Or continue with
          </Text>
          <Text
            role="button"
            fontSize="0.875rem"
            color="red.primary"
            hover="underline"
            onClick={handleSocialLoginText}
          >
            Github, Google or SSO
          </Text>
        </Flex>
      );
    }

    return null;
  };

  const handleBackButton = () => {
    if (isForgotPasswordFormActive) {
      toggleForgotPasswordForm(false);
    }

    navigate({ pathname: paths.login(), search: location.search });
  };

  if (isSSOLogin || isOnlySSOLogin) {
    return (
      <LoginLayout plainBackground={EE_LITE_MODE}>
        <Flex flexDirection="column" width="100%">
          <LoginHeader
            isBackButtonActive={!isOnlySSOLogin}
            handleBackButton={() => {
              navigate({
                search: window.location.search,
                pathname: paths.signup(),
              });
            }}
            showFullBlackLogo={EE_LITE_MODE}
          />
          <SSOForm redir={redir} />
        </Flex>
      </LoginLayout>
    );
  }

  if (isAdminLogin) {
    return (
      <LoginLayout>
        <Flex flexDirection="column" width="100%">
          <LoginHeader
            isBackButtonActive={true}
            handleBackButton={() => {
              navigate({
                search: window.location.search,
                pathname: paths.signup(),
              });
            }}
          />
          <ConfigSecretForm loginCallback={loginCallback} redir={redir} />
        </Flex>
      </LoginLayout>
    );
  }

  if (isLogin) {
    return (
      <LoginLayout>
        <Flex flexDirection="column" width="100%">
          <LoginHeader
            isBackButtonActive={isForgotPasswordFormActive}
            handleBackButton={handleBackButton}
          />
          {getLoginForm()}
          {getSocialLoginTextSection()}
        </Flex>
      </LoginLayout>
    );
  }

  const getFooterSection = () => {
    if (EE_MODE) {
      return null;
    }

    return (
      <Box width="100%">
        <HasuraUsersHighlight />
        <CloudPrivacyPolicy />
      </Box>
    );
  };

  const handleTNCCheckboxInput = (checked: boolean) => {
    toggleIsTNCChecked(checked);
  };

  const handleOAuthConsentCancel = () => {
    navigate(paths.signup());
  };

  const handleOAuthConsentAccept = () => {
    const authConsentEndpoint = `${authEndpoints.consentOAuthUrl}?anonymousId=${OAuthAnonymousId}&redir=${redir}`;
    window.location.replace(authConsentEndpoint);
  };

  const getOAuthTermsAndConditionConsent = () => {
    const consentButtonClass = `w-2/5 h-10 text-sm font-semibold rounded-md opacity-100
    border focus:border-brandBlue-400 focus:ring-1 focus:ring-brandBlue-400
    disabled:opacity-60`;

    return (
      <div className="flex-column w-9/10 items-start">
        <p className="mb-4 text-3xl font-bold text-black">
          Accept our Terms of Service and Privacy Policy
        </p>
        <p className="text-xl leading-8 text-gray-500">
          Please acknowledge the following terms and conditions to finish
          creating your account.
        </p>
        <div className="mt-4 flex items-start">
          <input
            id="tnc-checkbox"
            checked={isTNCChecked}
            name="tnc-checkbox"
            type="checkbox"
            className="mt-0.5 mr-2 h-6 w-6 cursor-pointer rounded border border-gray-300 shadow-sm hover:border-gray-400 focus:ring-primary-brand"
            onChange={e => handleTNCCheckboxInput(e.target.checked)}
            data-testid="tnc-checkbox-test-id"
          />
          <label
            htmlFor="tnc-checkbox"
            className="ml-1.5 cursor-pointer text-[14px] leading-[150%] text-gray-400 sm:text-[15px]"
          >
            By signing up, you agree to our{' '}
            <a
              href={HASURA_TERMS_AND_CONDITIONS_LINK}
              target="_blank"
              rel="noopener noreferrer"
              className="text-inherit underline"
            >
              Terms of Service
            </a>
            {' and '}
            <a
              href={HASURA_PRIVACY_POLICY_LINK}
              target="_blank"
              rel="noopener noreferrer"
              className="text-inherit underline"
            >
              Privacy Policy
            </a>
            . Keep me updated on news and offers.
          </label>
        </div>
        <div className="my-12 flex justify-center space-x-8">
          <button
            onClick={handleOAuthConsentCancel}
            type="button"
            data-testid="oauth-consent-accept-btn"
            id="oauth-consent-accept-btn"
            className={`${consentButtonClass} border-gray-400 bg-white text-black`}
          >
            Cancel SignUp
          </button>
          <button
            onClick={handleOAuthConsentAccept}
            type="button"
            data-testid="oauth-consent-accept-btn"
            id="oauth-consent-accept-btn"
            className={`${consentButtonClass} border-brandBlue-400 bg-cloudBlue text-white`}
            disabled={!isTNCChecked}
          >
            Accept
          </button>
        </div>
      </div>
    );
  };

  return (
    <LandingLayout>
      <Flex
        minWidth="966px"
        width={{ _: '67%' }}
        maxWidth="1200px"
        minHeight="680px"
        height="86vh"
        maxHeight="736px"
        justifyContent="center"
        boxShadow={4}
        my="20px"
        borderRadius="8px"
        css={css`
          /* Mobile */
          @media screen and (max-width: 750px) {
            min-width: 300px;
            height: 100%;
            max-height: 100%;
            width: 100%;
            box-shadow: none;
            margin: 0;
          }

          /* Tablet */
          @media screen and (min-width: 751px) and (max-width: 991px) {
            box-shadow: none;
          }
        `}
      >
        <HasuraFeatures />
        <Flex
          width="48%"
          height="100%"
          px={{ _: '6%', lg: '5%' }}
          pt="4%"
          pb="5.5%"
          bg="white"
          flexDirection="column"
          justifyContent="space-between"
          borderTopRightRadius="8px"
          borderBottomRightRadius="8px"
          overflowY="auto"
          css={css`
            @media screen and (min-width: 751px) and (max-width: 991px) {
              border-radius: 0;
              overflow-y: auto;
            }

            @media screen and (max-width: 750px) {
              width: 100%;
              max-width: 550px;
              border-radius: 0;

              #m-hasura-users {
                margin: 50px 0;
              }
            }
          `}
        >
          {OAuthAnonymousId ? (
            getOAuthTermsAndConditionConsent()
          ) : (
            <>
              <Flex flexDirection="column" width="100%">
                {isSignupFormActive ? (
                  <>
                    <Flex
                      width="100%"
                      justifyContent="center"
                      margin="40px 0 35px"
                      id="m-hasura-logo"
                    >
                      <HasuraLogo />
                    </Flex>
                    <SignupForm
                      handleResendEmail={handleResendEmail}
                      signInURL={paths.login()}
                    />
                    {getSocialLoginTextSection()}
                  </>
                ) : (
                  getSocialLoginAuth()
                )}
              </Flex>
              {getFooterSection()}
            </>
          )}
        </Flex>
      </Flex>
    </LandingLayout>
  );
};
