// Disable for just this line
// eslint-disable-next-line import/no-named-as-default
import posthog from 'posthog-js';

import { IS_PRODUCTION, POSTHOG_TOKEN } from './constants';

export const initPosthog = () => {
  if (IS_PRODUCTION) {
    posthog.init(POSTHOG_TOKEN, {
      api_host: 'https://analytics-posthog.hasura-app.io/',
    });
  }
};

export const trackUser = (userId: string, userEmail: string) => {
  if (IS_PRODUCTION) {
    posthog.identify(userId, { email: userEmail });
  }
};

export const resetPosthog = () => {
  if (IS_PRODUCTION) {
    posthog.reset();
  }
};
