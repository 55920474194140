import React from 'react';

import { Flex } from '../../atoms/Flex';
import { CheckIcon } from '../Check';

export const SuccessIcon = () => (
  <Flex as="span" size="18px" borderRadius="50%" bg="green.success" pl="3px">
    <CheckIcon size="10px" color="#fff" ml="1px" />
  </Flex>
);
