import React from 'react';

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

type OnChangeHandler = NonNullable<Pick<InputProps, 'onChange'>['onChange']>;
type InputValue = Pick<InputProps, 'value'>['value'] | boolean;
type InputValidator = (value: InputValue) => boolean;
type ResetFormFunction = () => void;
export interface FormConfigValue {
  default: InputValue;
  validator?: InputValidator;
}

export type FormConfig = Record<string, FormConfigValue>;

type FormState<T extends FormConfig> = {
  [P in keyof T]: {
    value: T[P]['default'];
    isValid: boolean;
  };
};

type FormOnChange<T extends FormConfig> = (
  stateName: keyof T
) => OnChangeHandler;

const getDefaultState = <T extends FormConfig>(config: T): FormState<T> => {
  return Object.keys(config).reduce((defaultStates, currentKey) => {
    return {
      ...defaultStates,
      [currentKey]: { value: config[currentKey].default, isValid: true },
    };
  }, {} as FormState<T>);
};

const getFormOnChange = <T extends FormConfig>(
  config: T,
  updateFormState: React.Dispatch<React.SetStateAction<FormState<T>>>
): FormOnChange<T> => {
  const computeValidity = (stateName: keyof T, value: InputValue) => {
    if (!config[stateName].validator) {
      return true;
    }

    return (config[stateName]['validator'] as unknown as InputValidator)(value);
  };

  return (stateName: keyof T) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.type !== 'checkbox') {
        event.preventDefault();
      }

      let currentValue: InputValue;

      if (event.target.type === 'checkbox') {
        currentValue = event.target.checked;
      } else {
        currentValue = event.target.value;
      }

      updateFormState(prevState => ({
        ...prevState,
        [stateName]: {
          value: currentValue,
          isValid: computeValidity(stateName, currentValue),
        },
      }));
    };
  };
};

const useForm = <T extends FormConfig>(
  formConfig: T
): [FormState<T>, FormOnChange<T>, ResetFormFunction] => {
  const formStartState = getDefaultState(formConfig);
  const [formState, updateFormState] = React.useState(formStartState);
  const formOnChange = getFormOnChange(formConfig, updateFormState);
  const resetStateFunction = () => updateFormState(formStartState);

  return [formState, formOnChange, resetStateFunction];
};

export default useForm;
