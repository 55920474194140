import styled from 'styled-components';

import { Box } from '../../atoms/Box';

export const StyledPopupModal = styled(Box)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  background-color: ${props =>
    props.opaqueMask ? '#e5e7eb' : 'rgba(0, 0, 0, 0.65)'};

  .modal-content-div {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10000;
    overflow: auto;
    text-align: center;
    cursor: pointer;
    padding-bottom: 5%;
  }

  .modal-content-div:after {
    display: inline-block;
    height: 100%;
    margin-left: -0.05em;
    content: '';
    vertical-align: middle;
  }

  .modal-dialog-div {
    position: relative;
    outline: 0;
    width: auto;
    display: inline-block;
    box-sizing: border-box;
    cursor: default;
    background-color: white;
    vertical-align: middle;
    max-width: auto;
    border-radius: 8px;
  }

  @media only screen and (max-width: 1200px) {
    .modal-content-div {
      padding-bottom: 0px;
      padding-left: 0px;
    }
  }

  @media only screen and (max-width: 400px) {
    .modal-dialog-div {
      vertical-align: bottom;
      width: 100%;
      animation: 10s slide-up;
    }

    @keyframes slide-up {
      from {
        height: 0%;
      }

      to {
        height: 500px;
      }
    }
  }
`;
