import React from 'react';

import { StyledSVG } from '../Icon';

export const WarnIcon = props => {
  const { color } = props;

  return (
    <StyledSVG
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.55 12.35H10.45V14.25H8.55V12.35ZM8.55 4.75H10.45V10.45H8.55V4.75ZM9.5 0C4.2465 0 0 4.275 0 9.5C0 12.0196 1.00089 14.4359 2.78249 16.2175C3.66464 17.0997 4.71191 17.7994 5.86451 18.2769C7.0171 18.7543 8.25244 19 9.5 19C12.0196 19 14.4359 17.9991 16.2175 16.2175C17.9991 14.4359 19 12.0196 19 9.5C19 8.25244 18.7543 7.0171 18.2769 5.86451C17.7994 4.71191 17.0997 3.66464 16.2175 2.78249C15.3354 1.90033 14.2881 1.20056 13.1355 0.723144C11.9829 0.245725 10.7476 0 9.5 0ZM9.5 17.1C7.48435 17.1 5.55126 16.2993 4.12599 14.874C2.70071 13.4487 1.9 11.5156 1.9 9.5C1.9 7.48435 2.70071 5.55126 4.12599 4.12599C5.55126 2.70071 7.48435 1.9 9.5 1.9C11.5156 1.9 13.4487 2.70071 14.874 4.12599C16.2993 5.55126 17.1 7.48435 17.1 9.5C17.1 11.5156 16.2993 13.4487 14.874 14.874C13.4487 16.2993 11.5156 17.1 9.5 17.1Z"
        fill="currentColor"
      />
    </StyledSVG>
  );
};
