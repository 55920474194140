import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import AuthCheck from './AuthCheck';
import NotificationsProvider from './components/Notifications';
import { GlobalStyles } from './components/styles/GlobalStyles';
import { theme } from './components/UIKit';
import { paths } from './utils/routeUtils';

const queryClient = new QueryClient();

const MakeMainRoutes: React.FC = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <React.StrictMode>
          <QueryClientProvider client={queryClient}>
            <GlobalStyles />
            <NotificationsProvider>
              <BrowserRouter>
                <Routes>
                  <Route
                    path={paths.rootWithChildren()}
                    element={<AuthCheck />}
                  />
                </Routes>
              </BrowserRouter>
            </NotificationsProvider>
          </QueryClientProvider>
        </React.StrictMode>
      </ThemeProvider>
    </>
  );
};

export default MakeMainRoutes;
