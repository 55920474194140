import { TextInput } from '@mantine/core';

export const StyledTextInput = TextInput.withProps({
  size: 'lg',
  styles: {
    input: {
      fontSize: 'var(--mantine-font-size-md)',
    },
  },
});
