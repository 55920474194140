type CallbackFn = () => void;

async function request<TResponse>(
  endpoint: string,
  config: RequestInit,
  callback?: CallbackFn
): Promise<{ data: TResponse; response: Response }> {
  const response = await fetch(endpoint, config);
  const data = await response.json();

  if (callback) {
    callback();
  }

  return { data, response };
}

export type APIRequestParams = Omit<RequestInit, 'method'>;

export const api = {
  get: <TResponse>(endpoint: string, callback?: CallbackFn) =>
    request<TResponse>(endpoint, { method: 'get' }, callback),
  post: <TResponse, TBody = APIRequestParams>(
    endpoint: string,
    config: TBody,
    callback?: CallbackFn
  ) => request<TResponse>(endpoint, { ...config, method: 'post' }, callback),
  put: <TResponse, TBody = APIRequestParams>(
    endpoint: string,
    config: TBody,
    callback?: CallbackFn
  ) => request<TResponse>(endpoint, { ...config, method: 'put' }, callback),
  delete: <TResponse, TBody = APIRequestParams>(
    endpoint: string,
    config: TBody,
    callback?: CallbackFn
  ) => request<TResponse>(endpoint, { ...config, method: 'delete' }, callback),
};
