import React, { SVGProps } from 'react';

const Logo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={121}
    height={37}
    viewBox="0 0 121 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M34.84 12.558c1.052-3.308.42-9.907-1.619-12.343a.61.61 0 0 0-.985.079l-2.51 3.98a1.92 1.92 0 0 1-2.58.423A17.114 17.114 0 0 0 17.67 1.85c-3.496 0-6.75 1.046-9.474 2.847a1.925 1.925 0 0 1-2.58-.423L3.106.294a.613.613 0 0 0-.987-.08C.082 2.656-.55 9.255.501 12.559a7.04 7.04 0 0 1 .239 3.39C.534 17.07.329 18.422.329 19.364c0 9.674 7.765 17.514 17.338 17.514 9.577 0 17.338-7.844 17.338-17.514 0-.942-.206-2.295-.411-3.416a7.093 7.093 0 0 1 .246-3.39Zm-17.173 20.41c-7.404 0-13.423-6.084-13.423-13.562a13.569 13.569 0 0 1 7.687-12.252 13.173 13.173 0 0 1 5.74-1.303c2.054 0 3.997.465 5.74 1.307a13.574 13.574 0 0 1 7.686 12.252c-.004 7.474-6.027 13.558-13.43 13.558Z"
      fill="#3970FD"
    />
    <path
      d="m23.483 24.627-3.43-6.01-2.942-5.009a.384.384 0 0 0-.333-.19h-2.81a.39.39 0 0 0-.333.59l2.814 4.776-3.775 5.818a.395.395 0 0 0-.017.398.385.385 0 0 0 .341.204h2.831a.382.382 0 0 0 .325-.179l2.041-3.22 1.833 3.208c.07.12.197.195.333.195h2.79c.14 0 .266-.075.332-.195a.382.382 0 0 0 0-.386Z"
      fill="#3970FD"
    />
    <path
      d="M49.892 9.22h3.586v19.664h-3.586v-8.379h-4.05v8.383h-3.586V9.22h3.586v8.539h4.05v-8.54Zm14.646 19.668-.749-4.085h-4.301l-.688 4.085h-3.585L59.17 9.224h4.828l4.145 19.664h-3.606Zm-4.581-6.798h3.334l-1.712-9.412-1.622 9.412Zm16.173 3.212v-4.208c0-.334-.061-.556-.184-.671-.124-.116-.355-.173-.688-.173h-2.523c-2.14 0-3.211-1.038-3.211-3.117v-4.825c0-2.06 1.12-3.084 3.363-3.084h3.429c2.243 0 3.363 1.029 3.363 3.083v2.743h-3.614v-2.244c0-.334-.062-.556-.185-.671-.124-.116-.354-.173-.688-.173h-1.185c-.354 0-.593.057-.717.173-.123.115-.185.337-.185.67v3.957c0 .334.062.556.185.671.124.116.363.174.717.174h2.461c2.182 0 3.273 1.016 3.273 3.055v5.142c0 2.059-1.132 3.084-3.396 3.084h-3.367c-2.264 0-3.396-1.03-3.396-3.084v-2.713h3.58v2.21c0 .334.063.557.186.672.124.115.362.173.716.173h1.186c.333 0 .56-.058.687-.173.128-.115.194-.338.194-.671ZM89.308 9.22h3.58V25.8c0 2.058-1.131 3.084-3.395 3.084h-3.8c-2.264 0-3.396-1.03-3.396-3.084V9.22h3.586v16.082c0 .333.061.556.185.67.123.116.354.174.687.174h1.651c.354 0 .593-.058.716-.173.124-.115.186-.338.186-.671V9.219Zm10.126 12.001v7.667h-3.581V9.219h7.228c2.264 0 3.396 1.03 3.396 3.084v5.83c0 1.705-.757 2.701-2.276 2.994l3.273 7.76h-3.866l-2.992-7.666h-1.182Zm0-9.26v6.609h2.585c.334 0 .56-.058.688-.173.123-.115.185-.338.185-.671v-4.92c0-.334-.062-.556-.185-.671-.124-.116-.354-.173-.688-.173h-2.585Zm17.886 16.927-.749-4.085h-4.302l-.687 4.085H108l3.956-19.664h4.829l4.145 19.664h-3.61Zm-4.578-6.798h3.335l-1.713-9.412-1.622 9.412Z"
      fill="#1F2A37"
    />
  </svg>
);

export default Logo;
