import React from 'react';

import { RightArrowIcon } from '../UIKit/icons/RightArrow';
import { hasuraDdnLaunchBannerLS } from './utils';

const registrationUrl = 'https://hasura.io/events/launch/dev-day';

export const HasuraDDNLaunchBanner = () => {
  const [dismissed, setDismissed] = React.useState(false);

  function dismissBanner() {
    setDismissed(true);
    hasuraDdnLaunchBannerLS.dismiss();
  }

  if (!hasuraDdnLaunchBannerLS.isDismissed() && !dismissed)
    return (
      <div className="w-full">
        <div
          style={{
            background: '#111927',
          }}
          className="border-bottom border-bottom-gray-200 top-0 flex min-h-[60px] items-center justify-between px-[6%] "
        >
          <div className="flex w-full items-center justify-center">
            <a
              target="_blank"
              href={registrationUrl}
              rel="noopener noreferrer"
              className="decoration-none font-semibold text-white hover:underline"
            >
              <p className="flex items-center gap-sm ">
                Launch Day: Hasura DDN - the grand unveil. Register now{' '}
                <RightArrowIcon color="#80A3FF" width={12} />
              </p>
            </a>
          </div>
          <button
            data-testid="dismiss-hasuracon23-banner"
            onClick={dismissBanner}
          >
            <svg
              className="cursor-pointer"
              fill="#80A3FF"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="16px"
              height="16px"
            >
              <path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z" />
            </svg>
          </button>
        </div>
      </div>
    );

  return null;
};
