import { HerokuSession } from './heroku';

const LS_HEROKU_SESSION = 'HEROKU_SESSION';
const PROJECTS_METADATA = 'PROJECTS_METADATA';
const HEROKU_CALLBACK_SEARCH = 'HEROKU_CALLBACK_SEARCH';
const NEON_CALLBACK_SEARCH = 'neon:authCallbackSearch';
const SLACK_CALLBACK_SEARCH = 'slack:authCallbackSearch';
const GITHUB_INTEGRATION_CALLBACK_SEARCH = 'GITHUB_INTEGRATION_CALLBACK_SEARCH';
const GITHUB_INSTALLATION_CALLBACK_SEARCH =
  'GITHUB_INSTALLATION_CALLBACK_SEARCH';
const HAS_DISMISSED_GCP_REQUEST_ALERT = 'HAS_DISMISSED_GCP_OPEN_REQUEST_ALERT';
const HAS_DISMISSED_PREVIEW_APP_BANNER = 'HAS_DISMISSED_PREVIEW_APP_BANNER';
const IS_ONBOARDING_THROUGH_USE_CASE_EXPERIMENT =
  'onboarding:useCaseExperiment';
const SKIP_CLOUD_ONBOARDING = 'SKIP_CLOUD_ONBOARDING';
const HAS_DISMISSED_SCHEMA_REGISTRY_REQUEST =
  'HAS_DISMISSED_SCHEMA_REGISTRY_REQUEST';
const USER_ASSUMPTION_SESSION_END = 'USER_ASSUMPTION_SESSION_END';

export const clearPersistedHerokuSession = () => {
  window.localStorage.removeItem(LS_HEROKU_SESSION);
};

export const persistHerokuSession = (session: HerokuSession) => {
  window.localStorage.setItem(LS_HEROKU_SESSION, JSON.stringify(session));
};

export const getPersistedHerokuSession = () => {
  const stringified = window.localStorage.getItem(LS_HEROKU_SESSION);
  if (stringified) {
    try {
      const session: HerokuSession = JSON.parse(stringified);
      return session;
    } catch {
      clearPersistedHerokuSession();
      return null;
    }
  }
  return null;
};

type PersistedProject = {
  id: string;
  changed_plan_at?: number;
  moved_region_at?: number;
};

export const getPersistedProjectMetadata = () => {
  const persistedProjectsMetadata =
    window.localStorage.getItem(PROJECTS_METADATA);
  if (persistedProjectsMetadata) {
    let allProjects: PersistedProject[] = [];
    try {
      allProjects = JSON.parse(persistedProjectsMetadata);
      return allProjects;
    } catch {
      return [];
    }
  }
  return [];
};

export const getPersistedProject = (id: string) => {
  const allProjects = getPersistedProjectMetadata();
  return allProjects.find(p => p.id === id);
};

export const isProjectRecentlyMoved = (id: string, currentTime: Date) => {
  const persistedProject = getPersistedProject(id);
  if (persistedProject && persistedProject.moved_region_at) {
    if (currentTime.getTime() - persistedProject.moved_region_at < 30000) {
      return true;
    }
  }
  return false;
};

export const isProjectRecentlyUpgraded = (id: string, currentTime: Date) => {
  const allProjects = getPersistedProjectMetadata();
  const persistedProject = getPersistedProject(id);
  if (persistedProject && persistedProject.changed_plan_at) {
    if (currentTime.getTime() - persistedProject.changed_plan_at < 20000) {
      return true;
    }
    window.localStorage.setItem(
      PROJECTS_METADATA,
      JSON.stringify(allProjects.filter(p => p.id !== id))
    );
  }
  return false;
};

export const persistProjectRegionChange = (id: string) => {
  let allProjects = getPersistedProjectMetadata();
  const persistedProject = getPersistedProject(id);

  if (persistedProject) {
    allProjects = allProjects.map(p => {
      if (p.id === id) {
        return {
          ...p,
          moved_region_at: new Date().getTime(),
        };
      }
      return p;
    });
  } else {
    allProjects.push({
      id,
      moved_region_at: new Date().getTime(),
    });
  }
  window.localStorage.setItem(PROJECTS_METADATA, JSON.stringify(allProjects));
};

export const persistProjectUpgrade = (id: string) => {
  let allProjects = getPersistedProjectMetadata();
  const persistedProject = getPersistedProject(id);

  if (persistedProject) {
    allProjects = allProjects.map(p => {
      if (p.id === id) {
        return {
          ...p,
          changed_plan_at: new Date().getTime(),
        };
      }
      return p;
    });
  } else {
    allProjects.push({
      id,
      changed_plan_at: new Date().getTime(),
    });
  }

  window.localStorage.setItem(PROJECTS_METADATA, JSON.stringify(allProjects));
};

export const clearPersistedHerokuCallbackSearch = () => {
  window.localStorage.removeItem(HEROKU_CALLBACK_SEARCH);
};

export const persistHerokuCallbackSearch = (value: string) => {
  window.localStorage.setItem(HEROKU_CALLBACK_SEARCH, value);
};

export const getPersistedHerokuCallbackSearch = () => {
  return window.localStorage.getItem(HEROKU_CALLBACK_SEARCH);
};

export const clearPersistedGithubIntegrationOauthSearch = () => {
  window.localStorage.removeItem(GITHUB_INTEGRATION_CALLBACK_SEARCH);
};

export const persistGithubIntegrationOauthSearch = (value: string) => {
  window.localStorage.setItem(GITHUB_INTEGRATION_CALLBACK_SEARCH, value);
};

export const getPersistedGithubIntegrationOauthSearch = () => {
  return window.localStorage.getItem(GITHUB_INTEGRATION_CALLBACK_SEARCH);
};

export const clearPersistedGithubInstallationOauthSearch = () => {
  window.localStorage.removeItem(GITHUB_INSTALLATION_CALLBACK_SEARCH);
};

export const persistGithubInstallationOauthSearch = (value: string) => {
  window.localStorage.setItem(GITHUB_INSTALLATION_CALLBACK_SEARCH, value);
};

export const getPersistedGithubInstallationOauthSearch = () => {
  return window.localStorage.getItem(GITHUB_INSTALLATION_CALLBACK_SEARCH);
};

export const persistGCPAccessAlertDismissal = () => {
  window.localStorage.setItem(HAS_DISMISSED_GCP_REQUEST_ALERT, 'true');
};

export const hasDismissedGCPAccessAlert = () => {
  return (
    window.localStorage.getItem(HAS_DISMISSED_GCP_REQUEST_ALERT) === 'true'
  );
};

export const persistPreviewAppsBannerDismissal = () => {
  window.localStorage.setItem(HAS_DISMISSED_PREVIEW_APP_BANNER, 'true');
};

export const hasDismissedPreviewAppsBanner = () => {
  return (
    window.localStorage.getItem(HAS_DISMISSED_PREVIEW_APP_BANNER) === 'true'
  );
};

export const persistNeonCallbackSearch = (value: string) => {
  window.localStorage.setItem(NEON_CALLBACK_SEARCH, value);
};

export const persistSlackCallbackSearch = (value: string) => {
  window.localStorage.setItem(SLACK_CALLBACK_SEARCH, value);
};

export const persistSkipConsoleOnboarding = (value: string) => {
  window.localStorage.setItem(SKIP_CLOUD_ONBOARDING, value);
};

export const clearPersistedSkipConsoleOnboarding = () => {
  window.localStorage.removeItem(SKIP_CLOUD_ONBOARDING);
};

export const persistUseCaseOnboardingFlowInLocalStorage = () => {
  window.localStorage.setItem(
    IS_ONBOARDING_THROUGH_USE_CASE_EXPERIMENT,
    'true'
  );
};

export const persistSchemaRegistryRequestBannerDismissal = () => {
  window.localStorage.setItem(HAS_DISMISSED_SCHEMA_REGISTRY_REQUEST, 'true');
};

export const hasDismissedSchemaRegistryRequestBanner = () => {
  return (
    window.localStorage.getItem(HAS_DISMISSED_SCHEMA_REGISTRY_REQUEST) ===
    'true'
  );
};

export const clearPersistedUASessionEnd = () => {
  window.localStorage.removeItem(USER_ASSUMPTION_SESSION_END);
};

export const persistUASessionEnd = (timestamp: number) => {
  window.localStorage.setItem(
    USER_ASSUMPTION_SESSION_END,
    JSON.stringify(timestamp)
  );
};

export const getPersistedUASessionEnd = () => {
  return window.localStorage.getItem(USER_ASSUMPTION_SESSION_END);
};

export const getPersistedSlackCallbackSearch = () => {
  return window.localStorage.getItem(SLACK_CALLBACK_SEARCH);
};

export const clearPersistedSlackCallbackSearch = () => {
  window.localStorage.removeItem(SLACK_CALLBACK_SEARCH);
};
