import React from 'react';

import { Flex } from '../Flex';
import { StyledTag } from './Tag';

export const Tag = props => {
  const { children } = props;

  return (
    <StyledTag width="fit-content" height="25px" {...props} background="white">
      <Flex height="16px">{children}</Flex>
    </StyledTag>
  );
};

Tag.defaultProps = {
  justifyContent: 'center',
  border: '1px solid #E4E4E4',
  borderRadius: '80px',
  paddingTop: '2px',
  paddingBottom: '2px',
};
