import { VercelIntegrationParams } from './types';

const CHANGED_EMAIL = 'HASURA_CLOUD_CHANGED_EMAIL';

const VERCEL_PARAMS = 'VERCEL_INTEGRATION_PARAMS';

export const GCP_TRY_OUT = 'GCP_TRY_OUT';
export const ONE_CLICK_DEPLOY_REDIRECT = 'ONE_CLICK_DEPLOY_REDIRECT';

export const setChangedEmailInSessionStrg = (email: string) => {
  window.sessionStorage.setItem(CHANGED_EMAIL, email);
};

export const getChangedEmailFromSessionStrg = () => {
  return window.sessionStorage.getItem(CHANGED_EMAIL);
};

export const clearChangedEmailInSessionStrg = () => {
  window.sessionStorage.removeItem(CHANGED_EMAIL);
};

export const setVercelParamsInSessionStrg = (
  vercelParams: VercelIntegrationParams
) => {
  window.sessionStorage.setItem(VERCEL_PARAMS, JSON.stringify(vercelParams));
};

export const getVercelParamsFromSessionStrg = () => {
  return JSON.parse(window.sessionStorage.getItem(VERCEL_PARAMS) || '{}');
};

export const clearVercelParamsInSessionStrg = () => {
  window.sessionStorage.removeItem(VERCEL_PARAMS);
};

export const tryGCP = () => {
  window.sessionStorage.setItem(GCP_TRY_OUT, 'true');
};

export const isTryingGCP = () => {
  return window.sessionStorage.getItem(GCP_TRY_OUT) === 'true';
};

export const dismissGCPTrialState = () => {
  window.sessionStorage.removeItem(GCP_TRY_OUT);
};
