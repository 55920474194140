import React from 'react';

import { Icon } from '../UIKit';

type OneClickDeploymentAuthCardProps = {
  oneClickDeployTitle?: string;
  oneClickDeployGithubURL?: string;
  oneClickDeployBranch?: string;
  oneClickDeployHasuraDir?: string;
};

export const OneClickDeploymentAuthCard: React.FC<
  OneClickDeploymentAuthCardProps
> = ({
  oneClickDeployTitle,
  oneClickDeployGithubURL,
  oneClickDeployBranch,
  oneClickDeployHasuraDir,
}) => {
  let repoPath = oneClickDeployGithubURL;
  if (oneClickDeployBranch) {
    repoPath =
      repoPath +
      '/tree/' +
      oneClickDeployBranch +
      '/' +
      oneClickDeployHasuraDir;
  }

  return (
    <div className="h-auto flex-col items-center justify-center rounded-sm border border-black p-4 shadow-lg">
      <p className="my-2 text-sm">Sign-in to create:</p>
      <div className="flex justify-start">
        <Icon size="40px" mr="-4px" mt="2px" type="github" color="#000000" />
        <p className="text-2xl font-medium tracking-wide text-black">
          {oneClickDeployTitle}
        </p>
      </div>
      <p className="my-2 text-sm">
        Hasura app from the above GitHub repository will be deployed in your
        Hasura Cloud account.
      </p>
      <a
        href={repoPath}
        target="_blank"
        rel="noopener noreferrer"
        className="text-inherit no-underline"
      >
        <div className="flex justify-start">
          <p className="text-sm font-semibold">View Repo</p>
          <Icon size="20px" ml="4px" type="launch" color="#000000" />
        </div>
      </a>
    </div>
  );
};
