import React, { useState } from 'react';

import { authEndpoints } from '../../utils/constants';
import { createQueryParamString } from '../../utils/routeUtils';
import { Button, Flex, Heading, Icon, Input, Label, Text } from '../UIKit';

const recoverUrl = authEndpoints.recover;

interface ForgotPasswordProps {
  loginEmail: string;
}

export const ForgotPassword: React.FC<ForgotPasswordProps> = ({
  loginEmail,
}) => {
  const [message, updateMessage] = useState(null);
  const [email, updateEmail] = useState<string>(loginEmail);
  const [error, updateError] = useState(null);
  const [loading, updateLoader] = useState(false);

  const handleEmailLink = () => {
    const emailBody = {
      email,
    };

    const options = {
      credentials: 'include',
      body: JSON.stringify(emailBody),
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
    };

    const queryParamStringForRecoverUrl = createQueryParamString([
      { name: 'redirect_url' },
      { name: 'login_challenge' },
    ]);

    const url = queryParamStringForRecoverUrl
      ? `${recoverUrl}?${queryParamStringForRecoverUrl}`
      : recoverUrl;

    return fetch(url, options as any)
      .then(resp => {
        return resp.json();
      })
      .then(resp => {
        updateLoader(false);
        updateMessage(resp.message);
      })
      .catch(err => {
        updateError(err);
      });
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    updateLoader(true);
    handleEmailLink();
  };

  const getChildren = () => {
    if (message) {
      return (
        <Text color="green.primary" pl="30px">
          If an account exists with the given email address, an email has been
          sent to it with further instructions on how to reset your password.
        </Text>
      );
    }

    return (
      <Flex flexDirection="column" as="form" onSubmit={handleSubmit}>
        <Text mb="20px">
          Please enter your email address. We will send you an email to reset
          your password.
        </Text>
        <Label>
          <Icon type="email" mr="12px" isActive={email || ''} />
          <Input
            type="email"
            required
            data-field-name="email"
            placeholder="Email"
            value={email}
            onChange={(
              e: React.SyntheticEvent & { target: HTMLInputElement }
            ) => {
              updateEmail(e.target.value);
            }}
          />
        </Label>
        <Button
          variant="primary"
          type="submit"
          size="large"
          width="100%"
          mt="8px"
          disabled={loading || !email.trim()}
          id="recover-password-btn"
        >
          Recover Password
        </Button>
        <Flex width="100%" mt="16px" fontSize="0.875rem">
          <Text color="red.primary" fontSize="0.875rem">
            {error && `* ${error}`}
          </Text>
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex flexDirection="column" width="100%">
      <Flex width="100%" justifyContent="center" mb="30px">
        <Heading fontSize="1.5rem" fontFamily="Poppins">
          Recover Password
        </Heading>
      </Flex>
      {getChildren()}
    </Flex>
  );
};
