import React, { SVGProps } from 'react';

export const Incognito = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.2166 9.83343C11.6666 9.83343 10.3666 10.9418 10.0333 12.4168C9.24163 12.0751 8.51663 12.1668 7.96663 12.4084C7.62496 10.9251 6.32496 9.83343 4.78329 9.83343C2.97496 9.83343 1.49996 11.3251 1.49996 13.1668C1.49996 15.0084 2.97496 16.5001 4.78329 16.5001C6.49996 16.5001 7.89996 15.1501 8.03329 13.4334C8.31663 13.2334 9.05829 12.8584 9.96663 13.4501C10.1166 15.1584 11.5 16.5001 13.2166 16.5001C15.025 16.5001 16.5 15.0084 16.5 13.1668C16.5 11.3251 15.025 9.83343 13.2166 9.83343ZM4.78329 15.5501C3.48329 15.5501 2.44163 14.4834 2.44163 13.1668C2.44163 11.8501 3.49163 10.7834 4.78329 10.7834C6.08329 10.7834 7.12496 11.8501 7.12496 13.1668C7.12496 14.4834 6.08329 15.5501 4.78329 15.5501ZM13.2166 15.5501C11.9166 15.5501 10.875 14.4834 10.875 13.1668C10.875 11.8501 11.9166 10.7834 13.2166 10.7834C14.5166 10.7834 15.5666 11.8501 15.5666 13.1668C15.5666 14.4834 14.5083 15.5501 13.2166 15.5501ZM17.3333 7.7501H0.666626V9.0001H17.3333V7.7501ZM11.9416 1.19176C11.7583 0.78343 11.2916 0.566763 10.85 0.70843L8.99996 1.3251L7.14163 0.70843L7.09996 0.700097C6.65829 0.575097 6.19163 0.80843 6.02496 1.23343L3.99996 6.5001H14L11.9666 1.23343L11.9416 1.19176Z"
      fill="#1EB4D4"
    />
  </svg>
);
