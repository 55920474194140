import React from 'react';

import { StyledSVG } from '../Icon';

export const CheckCircle = props => {
  const { color, width, height } = props;

  return (
    <StyledSVG
      width={width || '24px'}
      height={height || width || '24px'}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
      {...props}
    >
      <path d="M22,5.2L10.6,16.6l-4.2-4.2L7.8,11l2.8,2.8l10-10L22,5.2z M19.8,10.2c0.1,0.6,0.2,1.2,0.2,1.8c0,4.4-3.6,8-8,8  s-8-3.6-8-8s3.6-8,8-8c1.6,0,3,0.5,4.3,1.2l1.4-1.4C16.1,2.7,14.1,2,12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10  c0-1.2-0.2-2.3-0.6-3.4L19.8,10.2z" />
    </StyledSVG>
  );
};
