import React from 'react';

import { StyledSVG } from '../Icon';

export const GSTIcon = props => {
  const { color } = props;

  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill={color}
      {...props}
    >
      <path d="M9 0L0 4V10C0 15.55 3.84 20.74 9 22C14.16 20.74 18 15.55 18 10V4L9 0ZM9 2.18L16 5.3V10.22C16 11.92 15.5 13.65 14.65 15.17C13 13.94 10.26 13.5 9 13.5C7.74 13.5 5 13.94 3.35 15.17C2.5 13.65 2 11.92 2 10.22V5.3L9 2.18ZM9 5C8.07174 5 7.1815 5.36875 6.52513 6.02513C5.86875 6.6815 5.5 7.57174 5.5 8.5C5.5 9.42826 5.86875 10.3185 6.52513 10.9749C7.1815 11.6313 8.07174 12 9 12C9.92826 12 10.8185 11.6313 11.4749 10.9749C12.1313 10.3185 12.5 9.42826 12.5 8.5C12.5 7.57174 12.1313 6.6815 11.4749 6.02513C10.8185 5.36875 9.92826 5 9 5ZM9 7C9.39782 7 9.77936 7.15804 10.0607 7.43934C10.342 7.72064 10.5 8.10218 10.5 8.5C10.5 8.89782 10.342 9.27936 10.0607 9.56066C9.77936 9.84196 9.39782 10 9 10C8.60218 10 8.22064 9.84196 7.93934 9.56066C7.65804 9.27936 7.5 8.89782 7.5 8.5C7.5 8.10218 7.65804 7.72064 7.93934 7.43934C8.22064 7.15804 8.60218 7 9 7ZM9 15.5C10.57 15.5 12.64 16.11 13.53 16.84C12.29 18.38 10.7 19.55 9 20C7.3 19.55 5.71 18.38 4.47 16.84C5.37 16.11 7.43 15.5 9 15.5Z" />
    </StyledSVG>
  );
};
