import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { Icon } from '../../icons';
import { StyledTooltip } from './Tooltip';

const tooltipGenerator = message => {
  return (
    <Tooltip show id={message}>
      {message}
    </Tooltip>
  );
};

export const ToolTip = props => {
  const {
    message,
    placement,
    children,
    iconSize,
    iconColor,
    defaultTooltipView,
    hide,
  } = props;

  const target = React.useRef(null);

  React.useEffect(() => {
    if (!defaultTooltipView) {
      document.body.click();
    }
  }, [defaultTooltipView]);

  const childElement = children || (
    <Icon type="info" color={iconColor} size={iconSize} />
  );

  if (hide) {
    return childElement;
  }

  return (
    <OverlayTrigger
      placement={placement}
      rootClose
      overlay={tooltipGenerator(message)}
      defaultShow={defaultTooltipView}
    >
      <StyledTooltip
        ref={target}
        aria-hidden="true"
        {...props}
        ml={!children ? '8px' : ''}
      >
        {childElement}
      </StyledTooltip>
    </OverlayTrigger>
  );
};

ToolTip.defaultProps = {
  placement: 'right',
  iconColor: '#cecece',
  defaultTooltipView: false,
};
