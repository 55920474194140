export type SignupFormState = {
  isSignupByEmail: boolean;
  isSigningUp: boolean;
  hasAcceptedTnC: boolean;
  signupErr: string;
  signupSuccess: boolean;
  signupSuccessMessage: string;
  firstName: string;
  lastName: string;
  email: string;
  organization: string;
  password: string;
};

const SIGNING_UP = '@signup/SIGNING_UP';
const SIGNUP_FAIL = '@signup/SIGNUP_FAIL';
const SIGNUP_SUCCESS = '@signup/SIGNUP_SUCCESS';

export type SignupFormAction =
  | {
      type: typeof SIGNUP_FAIL;
      data: string;
    }
  | {
      type: typeof SIGNING_UP;
    }
  | {
      type: typeof SIGNUP_SUCCESS;
      data: string;
    };

const signupReducer = (state: SignupFormState, action: SignupFormAction) => {
  switch (action.type) {
    case SIGNING_UP:
      return {
        ...state,
        isSigningUp: true,
        signupErr: '',
        signupSuccessMessage: '',
      };
    case SIGNUP_FAIL:
      return {
        ...state,
        isSigningUp: false,
        signupErr: action.data,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        signupSuccess: true,
        isSigningUp: false,
        signupSuccessMessage: action.data,
      };
    default:
      return {
        ...state,
      };
  }
};

export { SIGNING_UP, SIGNUP_FAIL, SIGNUP_SUCCESS };
export default signupReducer;
