import React from 'react';

import { StyledSVG } from '../Icon';

export const WebIcon = props => {
  const { color } = props;

  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill={color}
      {...props}
    >
      <path d="M12.924 10.8C12.996 10.206 13.05 9.612 13.05 9C13.05 8.388 12.996 7.794 12.924 7.2H15.966C16.11 7.776 16.2 8.379 16.2 9C16.2 9.621 16.11 10.224 15.966 10.8H12.924ZM11.331 15.804C11.871 14.805 12.285 13.725 12.573 12.6H15.228C14.364 14.085 12.987 15.237 11.331 15.804ZM11.106 10.8H6.894C6.804 10.206 6.75 9.612 6.75 9C6.75 8.388 6.804 7.785 6.894 7.2H11.106C11.187 7.785 11.25 8.388 11.25 9C11.25 9.612 11.187 10.206 11.106 10.8ZM9 16.164C8.253 15.084 7.65 13.887 7.281 12.6H10.719C10.35 13.887 9.747 15.084 9 16.164ZM5.4 5.4H2.772C3.627 3.906 5.013 2.754 6.66 2.196C6.12 3.195 5.715 4.275 5.4 5.4ZM2.772 12.6H5.4C5.715 13.725 6.12 14.805 6.66 15.804C5.013 15.237 3.627 14.085 2.772 12.6ZM2.034 10.8C1.89 10.224 1.8 9.621 1.8 9C1.8 8.379 1.89 7.776 2.034 7.2H5.076C5.004 7.794 4.95 8.388 4.95 9C4.95 9.612 5.004 10.206 5.076 10.8H2.034ZM9 1.827C9.747 2.907 10.35 4.113 10.719 5.4H7.281C7.65 4.113 8.253 2.907 9 1.827ZM15.228 5.4H12.573C12.285 4.275 11.871 3.195 11.331 2.196C12.987 2.763 14.364 3.906 15.228 5.4ZM9 0C4.023 0 0 4.05 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C3.47177 16.1997 4.46392 16.8626 5.55585 17.3149C6.64778 17.7672 7.8181 18 9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.3869 18 9C18 7.8181 17.7672 6.64778 17.3149 5.55585C16.8626 4.46392 16.1997 3.47177 15.364 2.63604C14.5282 1.80031 13.5361 1.13738 12.4442 0.685084C11.3522 0.232792 10.1819 0 9 0Z" />
    </StyledSVG>
  );
};
