import { authEndpoints } from '../../../../utils/constants';
import { parseAuthResponse } from '../../../../utils/helpers';

export type RegisterData = {
  firstName: string;
  lastName: string;
  email: string;
  organization: string;
  password: string;
  confirmPassword: string;
};

function formatData(data: RegisterData) {
  return {
    first_name: data.firstName.trim(),
    last_name: data.lastName.trim(),
    email: data.email.toLowerCase().trim(),
    organization: data.organization.trim(),
    password: data.password,
    confirm_password: data.confirmPassword,
  };
}

export function registerNewUser({
  data,
  search,
}: {
  data: RegisterData;
  search: string;
}): Promise<{ status: string; message: string }> {
  const options: RequestInit = {
    credentials: 'include',
    body: JSON.stringify(formatData(data)),
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
  };

  let requestUrl = authEndpoints.signup;

  const params = new URLSearchParams(search);

  const redirectUrl = params.get('redirect_url');

  const loginChallenge = params.get('login_challenge');

  if (
    redirectUrl &&
    (redirectUrl.includes('console.hasura.io') ||
      redirectUrl.includes('console.arusah.com'))
  ) {
    const queryStr = new URLSearchParams({ redirect_url: redirectUrl });

    const platform = redirectUrl.includes('promptql')
      ? 'promptql_v3_console'
      : 'hasura_v3_console';

    requestUrl = `${requestUrl}?${queryStr}?platform=${platform}`;
  }

  if (loginChallenge) {
    const queryStr = new URLSearchParams({ login_challenge: loginChallenge });
    requestUrl = `${requestUrl}?${queryStr}`;
  }

  return fetch(requestUrl, options)
    .then(resp => {
      return resp.json();
    })
    .then(resp => {
      if ('status' in resp && resp.status === 'failure') {
        throw new Error(`Sign Up Error: ${parseAuthResponse(resp.errors)}`);
      }
      if ('status' in resp && resp.status === 'success') {
        return {
          status: 'success',
          message:
            'Sign Up success! Check your email to confirm your account and login!',
        };
      } else {
        throw new Error('Unexpected error. Get in touch with us for more info');
      }
    })
    .catch(err => {
      throw Error(`Sign Up Error: ${err.message ?? err.toString()}`);
    });
}
