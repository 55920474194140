import { Button } from '@mantine/core';

export const AuthButton = Button.withProps({
  size: 'lg',
  fullWidth: true,
  variant: 'light',
  color: 'gray',
  fz: 'md',
  fw: 500,
  c: 'var(--mantine-color-text)',
});
