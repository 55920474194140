import { gql } from '@apollo/client';

export const GET_FEATURE_ACCESS = gql`
  query getFeatureAccessDetails($feature: feature_enum) {
    feature_access {
      feature
    }
  }
`;

/**
 * GraphQl query to fetch all surveys related data
 */
export const FETCH_ALL_SURVEYS_DATA = gql(`
query fetchAllSurveysData($currentTime: timestamptz!) {
  survey_v2(where: {_or: [{ended_at: {_gte: $currentTime}}, {ended_at: {_is_null: true}}]}) {
    survey_name
    survey_title
    survey_description
    survey_questions(where: {_or: [{ended_at: {_gte: $currentTime}}, {ended_at: {_is_null: true}}]}) {
      id
      position
      question
      kind
      is_mandatory
      survey_question_options(where: {_or: [{ended_at: {_gte: $currentTime}}, {ended_at: {_is_null: true}}]}) {
        id
        position
        option
        template_config
        additional_info_config {
          info_description
          is_mandatory
        }
      }
    }
    template_config
  }
}
`);
