import React from 'react';
import { css } from 'styled-components/macro';

import { StyledLink } from './Link';

export const Link = props => {
  const { children, underline, href, hover, fontSize, color, id } = props;

  return (
    <StyledLink
      {...props}
      href={href}
      id={id}
      borderBottom={underline ? 1 : 'none'}
      rel="noopener noreferrer"
      fontSize={fontSize || '14px'}
      css={
        hover === 'underline' &&
        css`
          &:hover {
            text-decoration: underline !important;
            color: ${color};
          }
        `
      }
    >
      {children}
    </StyledLink>
  );
};

Link.defaultProps = {
  fontWeight: 'medium',
};
