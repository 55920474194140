import React from 'react';
import { Modal, Paper } from '@mantine/core';

export function LoginWidgetContainer({
  fullScreen,
  children,
  aboveMobileContent,
}: {
  fullScreen: boolean;
  children: React.ReactNode;
  aboveMobileContent: React.ReactNode;
}) {
  if (fullScreen) {
    return (
      <Modal
        fullScreen
        withCloseButton={false}
        closeOnClickOutside={false}
        opened
        onClose={() => {}}
        styles={{ header: { display: 'none' }, body: { padding: 0 } }}
      >
        {aboveMobileContent}
        {children}
      </Modal>
    );
  } else {
    return (
      <Paper
        flex={0}
        w={'100%'}
        maw={475}
        p={'4rem'}
        pos={'relative'}
        withBorder
        shadow="xs"
      >
        {children}
      </Paper>
    );
  }
}
