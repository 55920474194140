import * as React from 'react';

import { Flex, Text } from '../UIKit';

type Props = {
  errorMessage?: string;
  successMessage?: string;
  onClick?: VoidFunction;
};

export const AuthAlert: React.FC<Props> = ({
  errorMessage,
  successMessage,
  onClick,
}) => {
  let alertMessage;
  let alertMessageColor;

  if (errorMessage) {
    alertMessage = `* ${errorMessage}`;
    alertMessageColor = 'red.primary';
  }

  if (successMessage) {
    alertMessage = successMessage;
    alertMessageColor = 'green.primary';
  }

  if (!alertMessage) {
    return null;
  }

  return (
    <Flex
      width="100%"
      minHeight="32px"
      height="auto"
      mb="16px"
      onClick={onClick}
      pointer={onClick}
      data-testid="auth-alert"
    >
      <Text fontSize="0.875rem" color={alertMessageColor}>
        {alertMessage}
      </Text>
    </Flex>
  );
};
