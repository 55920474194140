import { Location } from 'react-router-dom';

import { EE_MODE, IS_PRODUCTION, IS_TESTING } from './constants';
import { getRouteHelmetTitle } from './routeUtils';
import { Window } from './types';

export const segmentEvents = {
  click: 'click',
  cloud: 'cloud',
  support: 'support',
  payment: 'cloud payment',
  attemptLogin: 'attempt login',
  login: 'logged in',
  logout: 'logged out',
};

const CLOUD_USER = 'cloud user';

const w = window as Window;

// Segmnent Tracking calls are only enabled for:
// - Production/Testing Environment
// - Non EE_MODE
const isSegmentEnabled = !EE_MODE && (IS_PRODUCTION || IS_TESTING);

export const segmentIdentify = (
  email: string,
  additionalInfo?: Record<string, string | string[]>
) => {
  if (w.analytics && isSegmentEnabled) {
    w.analytics.identify(email, additionalInfo || {});
  }
};

export const segmentTrack = (
  event: string,
  properties?: Record<string, any>,
  options?: SegmentAnalytics.SegmentOpts,
  callback?: () => void
) => {
  if (w.analytics && isSegmentEnabled) {
    const parentProperties = {
      ...properties,
      category: CLOUD_USER,
      action: (properties && properties.action) || event,
    };
    w.analytics.track(event, parentProperties, options, callback);
  }
};

export const segmentPage = (location: Location) => {
  if (w.analytics && isSegmentEnabled) {
    const title = getRouteHelmetTitle(location.pathname);
    const path = location.pathname;
    const searchParams = location.search;
    w.analytics.page(path, {
      Title: title,
      url: w.location.href,
      path,
      Search: searchParams,
    });
  }
};
