import React from 'react';

import { AUTH_METHODS } from './utils/constants';
import { debounce } from './utils/helpers';
import { paths } from './utils/routeUtils';

// a hook that can be used to store the scroll state in session storage.
// returns a function that can be used to set the initial scroll position;
// why is this required?
//   because most components use dynamic data and the scroll position can be set only after
//   the data has been loaded and rendered
export default function useScrollState(
  ref: React.RefObject<HTMLElement> | null,
  storageKey: string
) {
  const [scrollY, setScrollY] = React.useState(
    parseInt(window.sessionStorage.getItem(storageKey) || '0')
  );
  const [initial] = React.useState(
    parseInt(window.sessionStorage.getItem(storageKey) || '0')
  );

  // experimented with throttle and debounce with different delays;
  // short debounce gave the best UX
  const scrollHandler = React.useCallback(
    debounce(() => {
      if (ref?.current) setScrollY(ref.current.scrollTop);
    }, 100),
    [ref]
  );

  React.useEffect(() => {
    if (ref?.current !== null) {
      ref?.current.addEventListener('scroll', scrollHandler);
      return () => ref?.current?.removeEventListener('scroll', scrollHandler);
    }
  }, [ref]);

  React.useLayoutEffect(() => {
    window.sessionStorage.setItem(storageKey, String(scrollY));
  }, [scrollY]);

  return () => {
    ref?.current?.scrollTo({
      top: initial,
    });
  };
}

export function useTimer(seconds: number) {
  const [timeLeft, setTimeLeft] = React.useState(seconds);

  React.useEffect(() => {
    let timeout: NodeJS.Timeout; // in the browser, this is a number; but TS checks with Node runtime for type
    if (timeLeft) {
      timeout = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
    }
    return () => clearTimeout(timeout);
  }, [timeLeft]);

  return timeLeft;
}

export type AuthMode = 'password' | 'google' | 'github' | 'saml';

const getAuthModes = () => {
  const authMethods = AUTH_METHODS;
  if (authMethods) {
    return authMethods?.split(',') as AuthMode[];
  }

  return [];
};

// Get the AuthModes from window._env.authMethods
// sample usage in a component:
// const { isAuthSuppported } = useAuthModes();
// if (isAuthSuppported('email')) {}
// if (isAuthSuppported('github')) {}
export const useAuthModes = () => {
  const authModes = getAuthModes();
  const isAuthModeSuppported = (mode: AuthMode): boolean =>
    authModes.includes(mode);

  const isSocialSignInSupported =
    isAuthModeSuppported('google') || isAuthModeSuppported('github');

  const isEmailSignInSupported = isAuthModeSuppported('password');

  const isSamlAuthSupported = isAuthModeSuppported('saml');
  const authModeToRouteMap = {
    password: paths.v3.login.email(),
    saml: paths.v3.login.sso(),
    github: paths.signup(),
    google: paths.signup(),
  };

  return {
    allowedAuthModes: authModes.map(authMode => ({
      name: authMode,
      route: authModeToRouteMap[authMode],
    })),
    isEmailSignInSupported,
    isSocialSignInSupported,
    isSamlAuthSupported,
    isAuthModeSuppported,
  };
};
