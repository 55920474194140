import React from 'react';

import { StyledSVG } from '../Icon';

export const PlayIcon = props => {
  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="black"
      {...props}
      style={{ enableBackground: 'new 0 0 24 24' }}
    >
      <path className="st0" style={{ fill: 'none' }} d="M0,0h24v24H0V0z" />
      <path className="st1" style={{ fill: '#1EB4D4' }} d="M8,5v14l11-7L8,5z" />
    </StyledSVG>
  );
};
