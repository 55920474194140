import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

import 'bootstrap/dist/css/bootstrap.min.css';

require('typeface-poppins');

export const GlobalStyles = createGlobalStyle`
  ${normalize}

  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

    body {
    margin: 0;
    padding: 0;
    height: 100%;
     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
  }
  
   form {
    transition: all 0.5s;
    letter-spacing: -0.02em;
  }

  a,
  a:hover,
  a:active,
  a:visited,
  a:focus {
    text-decoration: none;
  }
`;
