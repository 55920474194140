import * as React from 'react';

import { StyledSVG } from '../Icon';

export const ExclamationTriangle = props => {
  const { color } = props;

  return (
    <StyledSVG
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="github-icon-alt"
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="13"
      viewBox="0 0 13 13"
      fill={color}
      {...props}
    >
      <path
        d="M0.583328 11.7501L6.99999 0.666748L13.4167 11.7501H0.583328ZM2.09999 10.8751H11.9L6.99999 2.41675L2.09999 10.8751ZM7.05833 10.0438C7.18472 10.0438 7.28923 10.0025 7.37187 9.91987C7.45451 9.83723 7.49583 9.73272 7.49583 9.60633C7.49583 9.47994 7.45451 9.37543 7.37187 9.29279C7.28923 9.21015 7.18472 9.16883 7.05833 9.16883C6.93194 9.16883 6.82743 9.21015 6.74479 9.29279C6.66215 9.37543 6.62083 9.47994 6.62083 9.60633C6.62083 9.73272 6.66215 9.83723 6.74479 9.91987C6.82743 10.0025 6.93194 10.0438 7.05833 10.0438ZM6.62083 8.42508H7.49583V5.15841H6.62083V8.42508Z"
        fill="#F59E0B"
      />
    </StyledSVG>
  );
};
