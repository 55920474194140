import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { ApolloProvider } from '@apollo/client';
import { Helmet } from 'react-helmet';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import makeApolloClient from './apollo';
import { UserContextProvider } from './components/Dashboard/store/UserContext';
import { LegacyLoginPage } from './components/Login';
import LoginPage from './components/Signup/DdnLoginPage';
import { LoginCallback } from './components/Signup/new/types';
import { authRedirect } from './utils/auth';
import { authEndpoints, GRAPHQL_URL, WS_URL } from './utils/constants';
import { heapIdentify } from './utils/heapAnalytics';
import { isLoggedIn, parseQueryString } from './utils/helpers';
import { lazyLoadSafely } from './utils/loadErrorHander';
import NetworkLoader from './utils/NetworkLoader';
import { tracker, updateOpenReplayUserId } from './utils/openReplay';
import { trackUser } from './utils/posthog';
import requestAuthServer from './utils/requestAuthServer';
import {
  getRouteHelmetTitle,
  isUnProtectedRoute,
  paths,
  unProtectedRoutes,
} from './utils/routeUtils';
import { segmentEvents, segmentTrack } from './utils/segment';
import { sentryIdentify } from './utils/sentry';
import { UserInfo } from './utils/types';

const App = lazyLoadSafely(() => import('./App'));
const ResetPassword = lazyLoadSafely(
  () => import('./components/Login/ResetPassword')
);
const Confirm = lazyLoadSafely(() => import('./components/Callbacks/Confirm'));
const Consent = lazyLoadSafely(() => import('./components/Callbacks/Consent'));
const HydraError = lazyLoadSafely(
  () => import('./components/HydraError/HydraError')
);
const VercelIntegrationLogin = lazyLoadSafely(
  () => import('./components/VercelIntegration/Login')
);
// commenting out SSO temporarily untill related issues are fixed
// const VercelSSO = lazyLoadSafely(
//   import('./components/VercelIntegration/SSO').catch(importErrorHandler)
// );
const VercelSignup = lazyLoadSafely(
  () => import('./components/VercelIntegration/Signup')
);
const VercelEmailLogin = lazyLoadSafely(
  () => import('./components/VercelIntegration/EmailLogin')
);
const VercelResetPassword = lazyLoadSafely(
  () => import('./components/VercelIntegration/ResetPassword')
);

// This is the function that checks the logged-in status of the user.
// We accomplish this by making a call to the user info API.
// `requestAuthServer` here is simply a wrapper around fetch that calls the auth server. It throws
// errors on receiving unexpected HTTP status code. (Refer to the function's
// documentation for more info.)
//
// If user is logged in we setup the Apollo client and gmetrics tracking.
// Otherwise, we redirect to the login/signup page.
const checkUserLogin = () => {
  const options = {
    credentials: 'include',
    method: 'GET',
    headers: {
      'content-type': 'application/json',
    },
  };

  return requestAuthServer(authEndpoints.userInfo, options);
};

// TODO: Remove any from the following type
export type AuthState = {
  isAuthenticated: Boolean;
  hasLoadedOnce: Boolean;
  fetchUserInProcess: Boolean;
  userInfo: UserInfo | null;
  loginRedirectLocation: string | null;
  isRedirecting: Boolean;
};

const defaultState: AuthState = {
  isAuthenticated: false,
  hasLoadedOnce: false,
  fetchUserInProcess: false,
  userInfo: null,
  loginRedirectLocation: null,
  isRedirecting: false,
};

const AuthenticatedRoutes: React.FC = () => {
  const client = React.useMemo(() => {
    return makeApolloClient(GRAPHQL_URL, WS_URL);
  }, []);

  return (
    <ApolloProvider client={client}>
      <Suspense fallback={<NetworkLoader />}>
        <UserContextProvider>
          <App />
        </UserContextProvider>
      </Suspense>
    </ApolloProvider>
  );
};

const AuthCheck: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [authState, setAuthState] = useState<AuthState>(defaultState);

  const { pathname, search } = location;

  const retriveUser = useCallback(() => {
    const parsedQueryString = parseQueryString(search);
    const parseLength = Object.keys(parsedQueryString).length;

    setAuthState(prevState => ({
      ...prevState,
      fetchUserInProcess: true,
    }));
    checkUserLogin()
      .then(resp => {
        if (isLoggedIn(resp)) {
          const userId = resp['X-Hasura-User-Id'];
          segmentTrack(segmentEvents.cloud, {
            email: resp['X-Hasura-User-Email'],
            userId,
            label: 'User Authenticated',
          });
          trackUser(userId, resp['X-Hasura-User-Email']);
          updateOpenReplayUserId(resp['X-Hasura-User-Email']);
          heapIdentify(userId);
          sentryIdentify(userId);
          setAuthState(prevState => ({
            ...prevState,
            isAuthenticated: true,
            fetchUserInProcess: false,
            userInfo: resp,
            hasLoadedOnce: true,
          }));
        } else {
          throw new Error('Unauthenticated');
        }
      })
      .catch(() => {
        /* Redirect */
        if (!isUnProtectedRoute(pathname)) {
          if (parseLength === 0 || !('redirect_url' in parsedQueryString)) {
            navigate({
              pathname: paths.signup(),
              search: `?redirect_url=${encodeURIComponent(
                `${pathname}${search ? `${search}` : ''}`
              )}`,
            });
          } else {
            navigate({
              pathname: paths.signup(),
            });
          }
        }
        setAuthState(prevState => ({
          ...prevState,
          isAuthenticated: false,
          isRedirecting: false,
          fetchUserInProcess: false,
          hasLoadedOnce: true,
          userInfo: null,
        }));
      });
  }, [navigate, pathname, search]);

  const afterLogin: LoginCallback = React.useCallback(
    ({ loginRedirectLocation }) => {
      setAuthState(prevState => ({
        ...prevState,
        loginRedirectLocation,
        isRedirecting: true,
      }));
      if (tracker) {
        if (
          !loginRedirectLocation &&
          (!loginRedirectLocation.toLowerCase().includes('http://') ||
            !loginRedirectLocation.toLowerCase().includes('https://'))
        ) {
          // Stop the OpenReplay session for internal redirects or staying on the dashboard
          tracker.stop();
        }
      }
      retriveUser();
    },
    [retriveUser]
  );

  useEffect(() => {
    retriveUser();
  }, []);

  useEffect(() => {
    if (authState.isAuthenticated) {
      const redirectCallback = () =>
        setAuthState(s => ({ ...s, isRedirecting: false }));
      authRedirect(
        navigate,
        location,
        authState.loginRedirectLocation,
        redirectCallback
      );
    }
  }, [authState.isAuthenticated]);

  if (
    !authState.hasLoadedOnce ||
    authState.fetchUserInProcess ||
    authState.isRedirecting
  ) {
    return <NetworkLoader />;
  }

  return (
    <div key="auth-check-wrapper">
      <Helmet title={getRouteHelmetTitle(pathname)} />
      <Suspense fallback={<NetworkLoader />}>
        <Routes>
          <Route
            key="reset-password"
            path={paths.recover()}
            element={<ResetPassword />}
          />
          <Route
            key="confirm-auth"
            path={paths.callbacks().confirm()}
            element={<Confirm />}
          />
          <Route
            key="consent-auth"
            path={paths.callbacks().consent()}
            element={<Consent />}
          />
          <Route path={paths.oauthError()} element={<HydraError />} />
          <Route
            key="vercel-login"
            path={paths.vercel().login()}
            element={
              authState.isAuthenticated &&
              new URLSearchParams(window.location.search).get('next') !==
                null ? (
                <Navigate
                  to={`${paths.vercel().setup()}${window.location.search}`}
                />
              ) : (
                <VercelIntegrationLogin />
              )
            }
          />
          <Route
            key="vercel-email-signup"
            path={paths.vercel().signup()}
            element={
              !authState.isAuthenticated ? (
                <VercelSignup />
              ) : (
                <Navigate
                  to={`${paths.vercel().setup()}${window.location.search}`}
                />
              )
            }
          />
          <Route
            key="vercel-email-login"
            path={paths.vercel().emailLogin()}
            element={
              !authState.isAuthenticated ? (
                <VercelEmailLogin />
              ) : (
                <Navigate
                  to={`${paths.vercel().setup()}${window.location.search}`}
                />
              )
            }
          />
          <Route
            key="vercel-reset-password"
            path={paths.vercel().resetPassword()}
            element={
              !authState.isAuthenticated ? (
                <VercelResetPassword />
              ) : (
                <Navigate
                  to={`${paths.vercel().setup()}${window.location.search}`}
                />
              )
            }
          />
          {!isLoggedIn(authState.userInfo) ? (
            <>
              <Route
                path={paths.login()}
                element={
                  <Navigate to={{ pathname: paths.signup(), search }} replace />
                }
              />
              {unProtectedRoutes.v3.map(path => (
                <Route
                  key={`v3-unprotected-${path}`}
                  path={path}
                  element={
                    <LoginPage
                      {...authState}
                      loginCallback={afterLogin}
                      isLoggedIn={isLoggedIn(authState.userInfo)}
                    />
                  }
                />
              ))}
              {unProtectedRoutes.v2
                // /login is handled above
                .filter(p => p !== paths.login())
                .map(path => (
                  <Route
                    key={`v2-unprotected-${path}`}
                    path={path}
                    element={
                      <LegacyLoginPage
                        {...authState}
                        loginCallback={afterLogin}
                        isLoggedIn={isLoggedIn(authState.userInfo)}
                      />
                    }
                  />
                ))}
            </>
          ) : (
            <Route
              key="rest-app"
              path={paths.rootWithChildren()}
              element={<AuthenticatedRoutes />}
            />
          )}
        </Routes>
      </Suspense>
    </div>
  );
};

export default AuthCheck;
