import React from 'react';
import { Stack } from '@mantine/core';
import {
  AnimatePresence,
  AnimationProps,
  motion,
  MotionStyle,
  Transition,
} from 'motion/react';

import { useHasMounted } from '../hooks/useHasMounted';

const delay = 0.75;
const duration = 0.2;
const transition: Transition = { duration, ease: 'easeInOut' };
const delayedAnimationProps: AnimationProps = {
  initial: { opacity: 0, height: 0 },
  animate: { opacity: 1, height: 'auto', transition: { delay } },
  transition,
  exit: { opacity: 0, height: 0 },
};
const animationProps: AnimationProps = {
  initial: { opacity: 0, height: 0 },
  animate: { opacity: 1, height: 'auto' },
  transition,
  exit: { opacity: 0, height: 0 },
};

export function AnimateInAndOut({
  visible,
  children,
  delay = false,
  style,
  skipFirstRender = false,
}: {
  visible: boolean;
  children: React.ReactNode;
  delay?: boolean;
  style?: MotionStyle;
  skipFirstRender?: boolean;
}) {
  const hasMounted = useHasMounted();

  const props = delay ? delayedAnimationProps : animationProps;

  return (
    <AnimatePresence>
      {visible && (
        <Stack
          component={motion.div}
          {...props}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          initial={skipFirstRender && !hasMounted ? false : props.initial}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          style={style}
        >
          {children}
        </Stack>
      )}
    </AnimatePresence>
  );
}
