import React from 'react';
import { Divider, Group, Paper, Text } from '@mantine/core';

export function OrDivider() {
  return (
    <Group justify="center" my={'lg'} pos={'relative'} w={'100%'}>
      <Paper shadow="none" style={{ zIndex: 1 }} px={8}>
        <Text fw={'600'} fz={'sm'} c={'gray'} tt={'uppercase'}>
          OR
        </Text>
      </Paper>
      <Divider pos={'absolute'} w="100%" />
    </Group>
  );
}
