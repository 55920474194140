import React from 'react';

import { StyledSVG } from '../Icon';

export const Speedometer = props => {
  const { color } = props;
  return (
    <StyledSVG
      stroke-width="0"
      viewBox="0 0 24 24"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      fill={color}
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M20.38 8.57l-1.23 1.85a8 8 0 01-.22 7.58H5.07A8 8 0 0115.58 6.85l1.85-1.23A10 10 0 003.35 19a2 2 0 001.72 1h13.85a2 2 0 001.74-1 10 10 0 00-.27-10.44zm-9.79 6.84a2 2 0 002.83 0l5.66-8.49-8.49 5.66a2 2 0 000 2.83z" />
    </StyledSVG>
  );
};
