import React from 'react';

import { StyledSVG } from '../Icon';

export const RunIcon = props => {
  const { color } = props;

  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={color}
      {...props}
    >
      <path style={{ fill: 'none' }} className="st0" d="M0,0h24v24H0V0z" />
      <path
        style={{ fill: '#1EB4D4' }}
        className="st1"
        d="M10,16.5l6-4.5l-6-4.5V16.5z M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M12,20
	c-4.41,0-8-3.59-8-8s3.59-8,8-8s8,3.59,8,8S16.41,20,12,20z"
      />{' '}
    </StyledSVG>
  );
};
