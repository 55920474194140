import React from 'react';
import { css } from 'styled-components/macro';

import { EE_MODE } from '../../utils/constants';
import HasuraLogo from '../Dashboard/common/HasuraLogo';
import { Flex, Heading, Icon, Text } from '../UIKit';
import { useValidateOneClickDeploymentUrlAuth } from './hooks';
import { OneClickDeploymentAuthCard } from './OneClickDeploymentAuthCard';

export const HasuraFeatures: React.FC = () => {
  const [
    oneClickDeployTitle,
    oneClickDeployGithubURL,
    oneClickDeployBranch,
    oneClickDeployHasuraDir,
  ] = useValidateOneClickDeploymentUrlAuth();

  const featuresList = [
    {
      id: 1,
      feature: 'Always-free developer tier',
      render: !EE_MODE,
    },
    {
      id: 2,
      feature: 'Enterprise grade security',
      render: EE_MODE,
    },
    {
      id: 3,
      feature: 'Single Sign On',
      render: EE_MODE,
    },
    {
      id: 4,
      feature: '24*7*365 dedicated support',
      render: EE_MODE,
    },
    {
      id: 5,
      feature: 'Enhanced performance and reliability features over CE',
      render: EE_MODE,
    },
    {
      id: 6,
      feature: 'Compatible with all GraphQL tooling',
    },
    {
      id: 7,
      feature: 'Works with Postgres and SQL Server',
    },
    {
      id: 8,
      feature: 'Create or connect your own database',
      render: !EE_MODE,
    },
  ];

  return (
    <Flex
      width="52%"
      height="100%"
      overflowY="auto"
      flex={1}
      bg="#F8F8F8"
      px={{ _: '6%', lg: '5%' }}
      flexDirection="column"
      borderTopLeftRadius="8px"
      borderBottomLeftRadius="8px"
      border="1px solid #e4e4e4"
      css={css`
        @media screen and (max-width: 750px) {
          display: none;
        }

        @media screen and (min-width: 751px) and (max-width: 991px) {
          display: none;
        }
      `}
    >
      <Flex width="100%" flexDirection="column" alignItems="flex-start">
        <Flex
          width="100%"
          justifyContent="space-between"
          margin={{ _: '12% 0 13.5%', lg: '12% 0 10%' }}
          alignItems="flex-start"
        >
          <HasuraLogo />
        </Flex>
        {oneClickDeployTitle ? (
          <OneClickDeploymentAuthCard
            oneClickDeployTitle={oneClickDeployTitle}
            oneClickDeployGithubURL={oneClickDeployGithubURL}
            oneClickDeployBranch={oneClickDeployBranch}
            oneClickDeployHasuraDir={oneClickDeployHasuraDir}
          />
        ) : (
          <>
            <Heading
              fontSize={{ _: '1.5rem', lg: '2rem' }}
              fontWeight="bold"
              color="#1B2738"
              whiteSpace="wrap"
              fontFamily="Poppins"
              lineHeight={1.43}
              letterSpacing="0.01em"
              mb={{ _: '4.5%', lg: '4%' }}
            >
              Instant GraphQL APIs for your data within minutes
              {EE_MODE ? '!' : ' for free!'}
            </Heading>
            <Text
              fontSize={{ _: '0.875rem', lg: '1rem' }}
              fontFamily="Poppins"
              lineHeight={2.1}
              letterSpacing="0.01em"
            >
              {EE_MODE ? 'Hasura EE' : 'Hasura Cloud'} instantly lets your apps
              query, update, and receive real-time notifications of your data
              with GraphQL.
            </Text>
          </>
        )}
        {!EE_MODE && (
          <Flex
            flexDirection="column"
            alignItems="flex-start"
            width="auto"
            my={{ _: '10%', lg: '9%' }}
          >
            <Text
              color="#1B2738"
              fontWeight={600}
              fontSize={{ _: '1.1rem', lg: '1.2rem' }}
              fontFamily="Poppins"
            >
              No Credit Card Required
            </Text>
            <Text
              color="#1B2738"
              fontWeight={600}
              fontSize={{ _: '0.875rem', lg: '0.95rem' }}
              fontFamily="Poppins"
            >
              <h1>Try Hasura today, it’s absolutely free</h1>
            </Text>
          </Flex>
        )}
        {EE_MODE && <Flex width="auto" my={{ _: '3%', lg: '3%' }} />}
        {featuresList
          .filter(data => data.render !== false)
          .map(({ id, feature }) => {
            return (
              <Flex key={id} mb="6%">
                <Icon type="check" color="green.primary" mr="3.5%" />
                <Text
                  fontSize={{ _: '0.875rem', lg: '1rem' }}
                  fontFamily="Poppins"
                >
                  {feature}
                </Text>
              </Flex>
            );
          })}
      </Flex>
    </Flex>
  );
};
