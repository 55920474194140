import React from 'react';
import { PiThumbsUp } from 'react-icons/pi';
import { useLocation } from 'react-router-dom';

import { useAuthModes } from '../../hooks';
import { paths } from '../../utils/routeUtils';
import { Banner } from '../Banners';
import { Tabs } from './components';
import { Logo } from './images';
import { NewLoginPage } from './new/NewLoginPage';
import { LandingPageProps } from './new/types';
import { SignUp } from './pages';
import { twjoin } from './utils';

const onClickLogo = () => {
  if (window?.open) {
    window.open('https://hasura.io/', '_blank', 'noopener')?.focus();
  }
};
const NoCreditCardIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g id="credit-card-x">
      <path
        id="Icon"
        d="M10.9987 10.6667L14.332 14M14.332 10.6667L10.9987 14M14.6654 6.66671H1.33203M14.6654 8.00004V5.46671C14.6654 4.71997 14.6654 4.3466 14.52 4.06139C14.3922 3.81051 14.1882 3.60653 13.9374 3.4787C13.6521 3.33337 13.2788 3.33337 12.532 3.33337H3.46537C2.71863 3.33337 2.34526 3.33337 2.06004 3.4787C1.80916 3.60653 1.60519 3.8105 1.47736 4.06139C1.33203 4.3466 1.33203 4.71997 1.33203 5.46671V10.5334C1.33203 11.2801 1.33203 11.6535 1.47736 11.9387C1.60519 12.1896 1.80916 12.3936 2.06004 12.5214C2.34526 12.6667 2.71863 12.6667 3.46536 12.6667H7.9987"
        stroke="#6C737F"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
);

const CheckedIcon = () => (
  <svg
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.599998" width="20.8" height="20.8" rx="10.4" fill="#80A3FF" />
    <path
      d="M14.6661 7.8L8.79948 13.6667L6.13281 11"
      stroke="white"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const V3SignUpHeader = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <h5 className="mb-4 font-inter text-[17.5px] font-semibold leading-[32px] sm:text-[20px] md:self-center">
      {children}
    </h5>
  );
};

// until we are 100% confident in the new login page, leaving the old code untouched for reference:
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const OldLoginPage = (props: LandingPageProps): JSX.Element => {
  const { pathname } = useLocation();

  const showSignInContentHeading = React.useMemo(() => {
    return (
      pathname !== paths.v3.register.newUserCloud() &&
      pathname !== paths.v3.login.forgotPassword()
    );
  }, [pathname]);

  const showSignUpContentHeading = React.useMemo(() => {
    return (
      pathname !== paths.v3.login.forgotPassword() &&
      pathname === paths.v3.register.newUserCloud()
    );
  }, [pathname]);

  const CheckBadge = () => {
    return (
      <div className="flex h-6 w-6 items-center justify-center rounded-full">
        <CheckedIcon />
      </div>
    );
  };
  type Feature = {
    Icon: React.ComponentType;
    text: string;
  };
  const features: Feature[] = [
    {
      Icon: CheckBadge,
      text: 'Ship incredible APIs - in minutes',
    },
    {
      Icon: CheckBadge,
      text: 'Run at scale - we handle the ops',
    },
    {
      Icon: CheckBadge,
      text: 'Generous free tier with no API usage limits',
    },
  ];

  const { allowedAuthModes } = useAuthModes();

  const getValidPathName = () => {
    if (
      pathname === paths.v3.register.newUserCloud() ||
      pathname === paths.v3.login.forgotPassword()
    )
      return pathname;

    if (!allowedAuthModes.length) return null;

    const isCurrentPathNameAllowed = !!allowedAuthModes.find(
      a => a.route === pathname
    );

    if (isCurrentPathNameAllowed) return pathname;

    const firstValidPathname = allowedAuthModes[0].route;

    return firstValidPathname;
  };

  const validPathName = getValidPathName();

  const FeatureItem = ({ feature }: { feature: Feature }) => {
    return (
      <div className="xl:text-md inline-flex justify-start gap-3 self-stretch text-xs sm:text-xs md:text-xs lg:text-xs">
        <div className="mt-1">
          <feature.Icon />
        </div>
        <div className="shrink grow basis-0 font-['Inter'] text-xl font-normal leading-normal text-[#18222f]">
          {feature.text}
        </div>
      </div>
    );
  };
  const AuthSection = () => (
    <div className="inline-flex h-full w-full flex-col items-center justify-center gap-3 rounded-tr-3xl rounded-br-3xl px-4 1080p:max-w-[500px]">
      <div
        className={twjoin(
          'relative z-0 h-full w-full bg-white',
          'flex w-full flex-col border border-v3-menuBorder px-5 py-5 sm:px-8 sm:py-8 md:max-w-lg lg:px-16 lg:py-14 ',
          'min-h-0 justify-center rounded-2xl md:justify-start'
          // pathname === paths.v3.register.newUserCloud()
          //   ? 'lg:px-16 '
          //   : 'lg:p-16'
        )}
      >
        {showSignInContentHeading ? (
          <V3SignUpHeader>Sign in to your account</V3SignUpHeader>
        ) : null}
        {showSignUpContentHeading ? (
          <V3SignUpHeader>Create a new Hasura Account</V3SignUpHeader>
        ) : null}
        {validPathName ? (
          <>
            {showSignInContentHeading ? (
              <Tabs currentRoute={validPathName} />
            ) : null}
            <SignUp currentRoute={validPathName} landingProps={props} />
          </>
        ) : (
          <div className="items-center justify-start gap-2 rounded-md bg-red-100 px-4 py-3 text-sm">
            No <code className="display-inline">AUTH_METHODS</code> detected in
            your configuration.
            <br />
            Please contact you administrator
          </div>
        )}
      </div>
      <div className="mb-1 mt-4 flex w-full flex-wrap items-start justify-between gap-2 sm:gap-6 lg:mt-4">
        {[
          {
            text: 'Generous free tier',
            Icon: PiThumbsUp,
          },
          {
            text: 'No credit card required',
            Icon: NoCreditCardIcon,
          },
        ].map(({ text, Icon }) => (
          <div className="flex h-6 items-center justify-start gap-6">
            <div className="flex items-center justify-start gap-1">
              <div className="flex items-center justify-center gap-1 rounded-[32px] p-1">
                <Icon />
              </div>
              <div className="font-['Inter'] text-sm font-medium leading-tight text-[#18222F]">
                {text}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const ContentSection = () => (
    <div
      className={twjoin(
        'flex w-full flex-col items-center 1080p:w-3/5 1080p:items-start',
        'mb-5 md:mb-0'
      )}
    >
      <div className="pb-1 hover:cursor-pointer md:pb-12" onClick={onClickLogo}>
        <Logo />
      </div>
      <div className="hidden flex-col items-start justify-start gap-10 self-stretch 1080p:flex ">
        <div className="flex flex-col items-start justify-start gap-6 self-stretch">
          <div className="mt-4 flex flex-col items-center md:mt-0 md:block md:flex-row md:items-start">
            <div className="inline-flex items-center justify-start gap-2">
              <div className="font-['Inter'] font-semibold leading-loose text-[#000615]">
                <h1 className="max-w-[347px] text-4xl leading-snug tracking-tight">
                  Try Hasura today. It’s absolutely free 🫰🏼
                </h1>
              </div>
            </div>
          </div>
          <div className="flex h-44 flex-col items-start justify-start gap-4 self-stretch">
            {features.map((feature, index) => (
              <FeatureItem key={index} feature={feature} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="h-screen bg-v3-background">
      <div
        className="z-0 flex w-screen flex-col items-center justify-center bg-v3-background py-6 md:h-screen md:py-0"
        id="new-sign-up-page-root"
      >
        <Banner />
        <div
          className={twjoin(
            'z-[1] font-inter',
            'mt-0  flex max-h-[900px] w-screen max-w-[1280px] flex-col justify-between gap-8 md:w-[95%] md:rounded-[24px] lg:w-5/6 lg:gap-10 1080p:flex-row',
            pathname === paths.v3.register.newUserCloud()
              ? 'h-[90%]'
              : 'h-[90%]'
          )}
        >
          <ContentSection />
          <AuthSection />
        </div>
        <div className="fixed left-0 bottom-[10%] z-[0] hidden 1080p:block smHeight:hidden lgHeight:bottom-[5%]">
          <img
            className="max-w-[42%] lg:max-w-[48%] xl:max-w-[50%] mdHeight:max-w-[60%]"
            src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1726146122/cloud/migration_ftn9c4.png"
            alt="Illustration"
          />
        </div>
      </div>
    </div>
  );
};

// export the new page:
export default NewLoginPage;
