import React from 'react';
import { css } from 'styled-components/macro';

import { StyledTextArea } from './TextArea';

export const TextArea = props => {
  const {
    children,
    type,
    name,
    placeholder,
    value,
    onChange,
    minHeight,
    disabled,
    border,
  } = props;

  return (
    <StyledTextArea
      {...props}
      name={name}
      type={type}
      placeholder={placeholder}
      value={value}
      width="100%"
      minHeight={minHeight || '132px'}
      maxHeight="300px"
      lineHeight="1.5"
      onChange={onChange}
      fontSize="14px"
      border={border || `1px solid #E5E5E5`}
      borderRadius="4px"
      css={
        !disabled
          ? css`
              &:hover {
                box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.1);
              }
            `
          : ''
      }
    >
      {children}
    </StyledTextArea>
  );
};
