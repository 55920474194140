import styled from 'styled-components';

export const StyledListItem = styled.li`
  list-style-type: square;
`;

export const StyledUnorderedList = styled.ul`
  text-align: left;
  padding-left: 20px;
  font-size: 14px;
  line-height: 1.8;
  margin-bottom: 0;
`;
