import styled from 'styled-components';
import {
  background,
  border,
  color,
  layout,
  position,
  shadow,
  space,
  typography,
} from 'styled-system';

import { Flex } from '../Flex';

export const StyledNotification = styled(Flex)`
  position: absolute;
  bottom: 5%;
  min-height: 57px;
  height: auto;
  border-radius: 8px;
  justify-content: space-between;

  #notification-close-btn {
    transition: all 0.1s ease;
    -webkit-transition: all 0.1s ease;

    &:hover {
      box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
    }
  }

  ${color}
  ${border}
  ${typography}
  ${layout}
  ${space}
  ${shadow}
  ${background}
  ${position}
`;
