import React from 'react';

import airbusLogo from '../images/airbus_logo.svg';
import atlassianLogo from '../images/atlassian_logo.svg';
import bbvaLogo from '../images/bbva_logo.svg';
import netlifyLogo from '../images/netlify_logo.svg';
import pipeLogo from '../images/pipe_logo.svg';
import virginaUniLogo from '../images/virginia_uni_logo.svg';
import { Flex, Image } from '../UIKit';

export const HasuraUsersHighlight: React.FC = () => (
  <Flex width="100%" flexDirection="column" mt="1%" id="m-hasura-users" mb="4%">
    <Flex justifyContent="space-between">
      <Image src={netlifyLogo} alt="Netlify" minWidth="64px" width="19.7%" />
      <Image
        src={bbvaLogo}
        alt="BBVA"
        minWidth="64px"
        width="15%"
        maxHeight="44px"
      />
      <Image src={airbusLogo} alt="Airbus" minWidth="64px" width="19.7%" />
    </Flex>
    <Flex justifyContent="space-between">
      <Image
        src={virginaUniLogo}
        alt="University of Virginia"
        minWidth="64px"
        width="16.7%"
      />
      <Image src={atlassianLogo} alt="Atlassian" minWidth="86px" width="28%" />
      <Image src={pipeLogo} alt="Pipe" minWidth="60px" width="19.7%" />
    </Flex>
  </Flex>
);
