import React from 'react';
import { Anchor, Box, BoxProps, Button, Group } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';

import HasuraLogo from '../../images/HasuraLogo';
import { LINKS } from '../constants';
import { AnimateInAndOut } from './AnimateInOut';

export function LoginTopBar({
  hideLogo,
  ...rest
}: {
  hideLogo?: boolean;
} & BoxProps) {
  const { search } = useLocation();
  return (
    <Group w={'100%'} justify="space-between" {...rest}>
      <Box style={{ height: 35 }}>
        <AnimateInAndOut visible={!hideLogo}>
          <Anchor
            href={LINKS.homePage}
            style={{ outline: 'none' }}
            target="_blank"
          >
            <HasuraLogo height={34} />
          </Anchor>
        </AnimateInAndOut>
      </Box>
      <Group gap={'xs'}>
        <Button
          onClick={() => window.open(LINKS.contact, '_blank')}
          variant="subtle"
          color="light-dark(#444, var(--mantine-color-gray-text))"
          size="xs"
        >
          Contact
        </Button>
        <Button
          size="xs"
          variant={'default'}
          component={Link}
          to={{ pathname: '/signup/new_user', search }}
        >
          Sign up
        </Button>
      </Group>
    </Group>
  );
}
