import React from 'react';
import { css } from 'styled-components/macro';

import { Box, Flex, Text } from '..';
import { Icon } from '../../icons';

type CardSelectOption = {
  title: string;
  description: string;
  value: string;
};

interface CardSelectProps {
  options: CardSelectOption[];
  value: string;
  onSelect: (value: string) => void;
}

export function CardSelect({ options, value, onSelect }: CardSelectProps) {
  return (
    <Flex
      width="100%"
      borderTop={1}
      borderColor="grey.border"
      mt="16px"
      pt="16px"
      mb="2px"
      style={{ gap: '8px' }}
      alignItems="stretch"
    >
      {options.map(option => (
        <Box
          key={option.value}
          pointer
          onClick={() => onSelect(option.value)}
          position="relative"
          value={option.value}
          padding={value === option.value ? '15px' : '16px'}
          border={
            value === option.value
              ? '2px solid rgb(72, 181, 213);'
              : 'thin solid gray;'
          }
          borderRadius="5px"
          css={css`
            &:hover {
              box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.1);
              transition: box-shadow 0.2s ease;
            }
          `}
        >
          {value === option.value && (
            <Icon
              type="checkCircleFilled"
              color="rgb(72, 181, 213)"
              style={{ position: 'absolute', right: '0.5em', top: '0.5em' }}
            />
          )}
          <Text fontSize="16px">{option.title}</Text>
          <Text color="#757575" fontSize="14px">
            {option.description}
          </Text>
        </Box>
      ))}
    </Flex>
  );
}
