import React from 'react';

import { StyledInput } from './Input';

const getInputPattern = inputType => {
  switch (inputType) {
    case 'number':
      return '[0-9]+';
    default:
      return null;
  }
};

export const Input = props => {
  const { children, type, name, placeholder, value, onChange } = props;

  const inputPattern = getInputPattern(type);

  return (
    <StyledInput
      {...props}
      name={name}
      type={type}
      placeholder={placeholder}
      value={value}
      border="none"
      width="100%"
      lineHeight="1.5"
      letterSpacing="- 0.02em"
      pattern={inputPattern}
      onChange={onChange}
    >
      {children}
    </StyledInput>
  );
};

Input.defaultProps = {
  fontSize: '16px',
};
