import styled from 'styled-components';
import { flexbox } from 'styled-system';

import { Box } from '../Box';

export const Flex = styled(Box)`
  transition: all 0.1s ease;
  -webkit-transition: all 0.1s ease;

  ${flexbox}

  &:hover {
    background: ${props => props.hoverColor};
  }
`;

Flex.defaultProps = {
  alignItems: 'center',
  display: 'flex',
};
