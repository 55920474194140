import React from 'react';

import { StyledSVG } from '../Icon';

export const DeleteIcon = props => {
  const { color, width, height } = props;

  return (
    <StyledSVG
      width={width}
      height={height}
      focusable="false"
      data-prefix="fas"
      data-icon="trash"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 18"
      fill={color}
      {...props}
    >
      <path d="M5 0V1H0V3H1V16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H13C13.5304 18 14.0391 17.7893 14.4142 17.4142C14.7893 17.0391 15 16.5304 15 16V3H16V1H11V0H5ZM3 3H13V16H3V3ZM5 5V14H7V5H5ZM9 5V14H11V5H9Z" />
    </StyledSVG>
  );
};

DeleteIcon.defaultProps = {
  width: '16px',
  height: '18px',
};
