import styled from 'styled-components';
import {
  background,
  border,
  color,
  layout,
  position,
  shadow,
  space,
  typography,
} from 'styled-system';

export const StyledImage = styled.img`
  cursor: ${props => (props.pointer ? 'pointer' : '')};
  ${color}
  ${border}
  ${typography}
  ${layout}
  ${space}
  ${shadow}
  ${background}
  ${position}
`;
