import React from 'react';

import { StyledSVG } from '../Icon';

export const NetworkIcon = props => {
  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <rect style={{ fill: 'none' }} width="24" height="24" />
      </g>
      <polygon
        style={{ fill: '#00B7D7' }}
        points="22,11 22,3 15,3 15,6 9,6 9,3 2,3 2,11 9,11 9,8 11,8 11,18 15,18 15,21 22,21 22,13 15,13 15,16 
	13,16 13,8 15,8 15,11 "
      />
    </StyledSVG>
  );
};
