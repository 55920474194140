import React from 'react';

import { StyledSVG } from '../Icon';

export const SparkleIcon = props => {
  const { color } = props;

  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="13"
      viewBox="0 0 13 13"
      fill={color}
      {...props}
    >
      <path d="M6.5 0L4.72727 4.72727L0 6.5L4.72727 8.27273L6.5 13L8.27273 8.27273L13 6.5L8.27273 4.72727L6.5 0Z" />
    </StyledSVG>
  );
};
