import React from 'react';

import { StyledSVG } from '../Icon';

export const LogoutIcon = props => {
  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    >
      <path
        d="M17.3333 8.99996L14 5.66663V8.16663H7.33332V9.83329H14V12.3333L17.3333 8.99996ZM15.6667 14C14.6172 15.3992 13.1542 16.4327 11.4847 16.9542C9.81526 17.4757 8.02403 17.4587 6.36476 16.9057C4.70549 16.3526 3.26231 15.2914 2.23964 13.8725C1.21697 12.4537 0.666656 10.749 0.666656 8.99996C0.666656 7.25094 1.21697 5.54625 2.23964 4.12737C3.26231 2.70849 4.70549 1.64735 6.36476 1.09427C8.02403 0.541176 9.81526 0.524179 11.4847 1.04568C13.1542 1.56718 14.6172 2.60074 15.6667 3.99996H13.3917C12.4294 3.15135 11.2428 2.59841 9.97405 2.40751C8.70535 2.2166 7.40851 2.39584 6.23915 2.92371C5.06979 3.45158 4.07759 4.30565 3.3816 5.38345C2.68561 6.46124 2.3154 7.71698 2.3154 8.99996C2.3154 10.2829 2.68561 11.5387 3.3816 12.6165C4.07759 13.6943 5.06979 14.5483 6.23915 15.0762C7.40851 15.6041 8.70535 15.7833 9.97405 15.5924C11.2428 15.4015 12.4294 14.8486 13.3917 14H15.6667Z"
        fill="#717780"
      />
    </StyledSVG>
  );
};
