import * as React from 'react';

import { StyledSVG } from '../Icon';

type Props = any & {
  color: string;
  width: string;
  height: string;
};

export const HasuraIcon: React.FC<Props> = props => {
  const { color, width, height } = props;

  return (
    <StyledSVG
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={width || '15'}
      height={height || '15'}
      viewBox="0 0 85 95"
      fill={color}
    >
      <g id="Layer_2" />
      <g id="Layer_1">
        <g>
          <path
            fill={color}
            d="M79.9,31.8c2.6-6.3,2.6-19.1-0.8-28.9l0,0c-0.9-1.8-3.6-1.2-3.7,0.7v0.7c-0.6,9.4-4,14.5-9.1,17
            c-0.8,0.4-2.1,0.4-3-0.2c-6-3.8-13.2-6-20.9-6s-14.8,2.3-20.9,6c-0.8,0.5-1.8,0.6-2.6,0.2c-5.2-1.9-8.8-7.6-9.4-17.1V3.5
            C9.5,1.6,6.7,1,5.9,2.8c-3.4,10-3.3,22.8-0.8,29c1.3,3.1,1.3,6.6,0.2,9.8c-1.4,4-2.1,8.5-2,13c0.5,20.6,18,38.2,38.7,38.5
            C63.8,93.5,81.5,76,81.5,54.1c0-4.4-0.7-8.5-2-12.4C78.5,38.5,78.6,34.9,79.9,31.8z M41.9,83.9C26.1,83.5,13,70.6,12.8,54.7
            C12.4,37.8,26.2,24,43,24.4c15.9,0.2,28.9,13.3,29.2,29.2C72.5,70.4,58.8,84.1,41.9,83.9z"
          />
          <path
            fill={color}
            d="M47,50.9l-7-10.8c-1.1-1.8-3.4-2.3-5.2-1.2c-1.1,0.7-1.8,1.9-1.8,3.2c0,0.7,0.2,1.4,0.6,2l4.7,7.4
            c0.4,0.6,0.2,1.3-0.1,1.8l-7.4,8.1c-1.4,1.5-1.3,3.9,0.2,5.3c0.7,0.6,1.7,0.9,2.6,0.9c1.1,0,2-0.5,2.7-1.2l5.5-6.4
            c0.5-0.5,1.2-0.5,1.5,0.1l3.9,5.6c0.2,0.5,0.6,0.8,1.1,1.1c1.3,0.9,3,0.9,4.3,0.1l0,0c1.1-0.7,1.8-1.9,1.8-3.2
            c0-0.7-0.2-1.4-0.6-2L47,50.9z"
          />
        </g>
      </g>
    </StyledSVG>
  );
};
