import React from 'react';

import { StyledSVG } from '../Icon';

export const CheckCircleFilled = props => {
  const { color, width, height } = props;

  return (
    <StyledSVG
      width={width || '24px'}
      height={height || width || '24px'}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
      {...props}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M10,17l-5-5l1.4-1.4l3.6,3.6l7.6-7.6L19,8  L10,17z"
      />
    </StyledSVG>
  );
};
