import React from 'react';

import { StyledSVG } from '../Icon';

export const LearnIcon = props => {
  const { color } = props;

  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill={color}
      {...props}
    >
      <path d="M10 0L0 5.33333L3.63636 7.27111V12.6044L10 16L16.3636 12.6044V7.27111L18.1818 6.30222V12.4444H20V5.33333L10 0ZM16.2 5.33333L10 8.64L3.8 5.33333L10 2.02667L16.2 5.33333ZM14.5455 11.5556L10 13.9733L5.45455 11.5556V8.24L10 10.6667L14.5455 8.24V11.5556Z" />
    </StyledSVG>
  );
};
