import React from 'react';

import { StyledSVG } from '../Icon';

export const CheckIcon = props => {
  const { color } = props;

  return (
    <StyledSVG
      width="16"
      height="13"
      viewBox="0 0 16 13"
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
      {...props}
    >
      <path
        d="M16 1.36689L5.02857 13L0 7.66816L1.28914 6.30127L5.02857 10.2565L14.7109 0L16 1.36689Z"
        fill="currentColor"
      />
    </StyledSVG>
  );
};
