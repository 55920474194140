import React from 'react';

import {
  CLOUD_TERMS_OF_SERVICE,
  HASURA_PRIVACY_POLICY,
} from '../../utils/references';
import { Flex, Link, Text } from '../UIKit';

export const CloudPrivacyPolicy: React.FC = () => (
  <Flex width="100%" justifyContent="center" flexWrap="wrap">
    <Text fontSize="0.75rem" color="grey.text" nowrap mr="4px">
      By signing up, you agree to our
    </Text>
    <Link
      color="grey.original"
      underline
      fontSize="0.75rem"
      href={CLOUD_TERMS_OF_SERVICE}
      target="_blank"
      id="terms-conditions-link"
      nowrap
    >
      Terms of Service
    </Link>
    <Text px="4px" fontSize="0.75rem" color="grey.text">
      and
    </Text>
    <Link
      id="privacy-policy-link"
      color="grey.original"
      underline
      fontSize="0.75rem"
      href={HASURA_PRIVACY_POLICY}
      target="_blank"
      nowrap
    >
      Privacy Policy
    </Link>
  </Flex>
);
