import styled from 'styled-components';
import { layout, space } from 'styled-system';

export const StyledPopover = styled.span`
  display: inline-block;
  position: relative;

  ${space}
  ${layout}
`;
