import { UnProtectedRoute } from '../../../utils/routeUtils';

export const LINKS = {
  termsOfService: 'https://hasura.io/legal/hasura-cloud-terms-of-service/',
  privacyPolicy: 'https://hasura.io/legal/hasura-privacy-policy/',
  homePage: 'https://hasura.io/',
  contact: 'https://hasura.io/contact-us',
};

export const ICON_SIZE = 24;
export const PageTitle: Record<UnProtectedRoute | 'unknown', string> = {
  '/signup': 'Log in to Hasura',
  '/signup/email': 'Log in to Hasura',
  '/signup/new_user': 'Sign up for Hasura',
  '/signup/forgot': 'Reset your password',
  '/signup/sso': 'Log in with SSO',
  unknown: 'Log in to Hasura',
};
export const SubmitButtonTitle: Record<UnProtectedRoute | 'unknown', string> = {
  '/signup': 'Log in',
  '/signup/email': 'Log in',
  '/signup/new_user': 'Register',
  '/signup/forgot': 'Continue',
  '/signup/sso': 'SSO Log in',
  unknown: 'Log in',
};
export const ShouldShowSubmitButton: Record<
  UnProtectedRoute | 'unknown',
  boolean
> = {
  '/signup': false,
  '/signup/email': true,
  '/signup/new_user': true,
  '/signup/forgot': true,
  '/signup/sso': true,
  unknown: false,
};
