import { lazy } from 'react';

import { DynamicImportType, LazyComponentType } from './types';

export function reloadIfFailed(componentImportFn: DynamicImportType) {
  return new Promise(resolve => {
    componentImportFn()
      .then(comp => resolve(comp))
      .catch(() => setTimeout(() => window.location.reload(), 700));
  });
}

export const lazyLoadSafely = (
  componentImportFn: DynamicImportType
): LazyComponentType => {
  return lazy(() => reloadIfFailed(componentImportFn) as any);
};
