import React from 'react';

import { StyledSVG } from '../Icon';

export const DocumentIcon = props => {
  const { color } = props;

  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill={color}
      {...props}
    >
      <path d="M10.6667 0H1.77778C1.30628 0 0.854097 0.187301 0.520699 0.520699C0.187301 0.854097 0 1.30628 0 1.77778V14.2222C0 14.6937 0.187301 15.1459 0.520699 15.4793C0.854097 15.8127 1.30628 16 1.77778 16H14.2222C14.6937 16 15.1459 15.8127 15.4793 15.4793C15.8127 15.1459 16 14.6937 16 14.2222V5.33333L10.6667 0ZM14.2222 14.2222H1.77778V1.77778H9.77778V6.22222H14.2222V14.2222ZM12.4444 9.77778H3.55556V8H12.4444V9.77778ZM9.77778 12.4444H3.55556V10.6667H9.77778" />
    </StyledSVG>
  );
};

DocumentIcon.defaultProps = {
  size: '16px',
};
