export function isSSOError(search: string): boolean {
  const searchParams = new URLSearchParams(
    search.substring(search.indexOf('?'))
  );
  const redirectUrlValue = searchParams.get('redirect_url');

  if (
    redirectUrlValue !== null &&
    (redirectUrlValue ===
      '/oauth2-error?error_reason=SAML/SSO+is+enabled+for+the+user' ||
      redirectUrlValue.includes('SAML') ||
      redirectUrlValue.includes('SSO') ||
      redirectUrlValue ===
        '/oauth2-error?error_reason=error+with+log+in+or+register')
  ) {
    return true;
  } else {
    return false;
  }
}
