import React from 'react';

import { StyledSVG } from '../Icon';

export const BillIcon = props => {
  const { color } = props;

  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill={color}
      {...props}
    >
      <path d="M1.89474 0C0.843158 0 0 0.85 0 1.88889V15.1111C0 15.6121 0.199623 16.0925 0.554955 16.4468C0.910288 16.801 1.39222 17 1.89474 17H15.1579C15.6604 17 16.1423 16.801 16.4977 16.4468C16.853 16.0925 17.0526 15.6121 17.0526 15.1111V12.9578C17.6116 12.6272 18 12.0322 18 11.3333V5.66667C18 4.96778 17.6116 4.37278 17.0526 4.04222V1.88889C17.0526 1.38792 16.853 0.907478 16.4977 0.553243C16.1423 0.199007 15.6604 0 15.1579 0H1.89474ZM1.89474 1.88889H15.1579V3.77778H9.47368C8.97117 3.77778 8.48924 3.97678 8.1339 4.33102C7.77857 4.68526 7.57895 5.1657 7.57895 5.66667V11.3333C7.57895 11.8343 7.77857 12.3147 8.1339 12.669C8.48924 13.0232 8.97117 13.2222 9.47368 13.2222H15.1579V15.1111H1.89474V1.88889ZM9.47368 5.66667H16.1053V11.3333H9.47368V5.66667ZM12.3158 7.08333C11.9389 7.08333 11.5775 7.23259 11.311 7.49826C11.0445 7.76394 10.8947 8.12428 10.8947 8.5C10.8947 8.87572 11.0445 9.23606 11.311 9.50173C11.5775 9.76741 11.9389 9.91667 12.3158 9.91667C12.6927 9.91667 13.0541 9.76741 13.3206 9.50173C13.5871 9.23606 13.7368 8.87572 13.7368 8.5C13.7368 8.12428 13.5871 7.76394 13.3206 7.49826C13.0541 7.23259 12.6927 7.08333 12.3158 7.08333Z" />
    </StyledSVG>
  );
};
