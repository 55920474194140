import React from 'react';
import { css } from 'styled-components/macro';

import { StyledLabel } from './Label';

export const Label = props => {
  const { children, disabled, pr, borderCol } = props;

  return (
    <StyledLabel
      {...props}
      border={1}
      borderColor={borderCol ?? 'grey.bg'}
      as="label"
      borderRadius="4px"
      width="100%"
      pl="16px"
      pr={pr || '21px'}
      css={
        !disabled
          ? css`
              &:hover {
                box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.1);
              }
            `
          : ''
      }
    >
      {children}
    </StyledLabel>
  );
};

Label.defaultProps = {
  height: '56px',
  mb: '16px',
};
