import { gql } from '@apollo/client';

export const GET_CONFIGURATION_STATUS_SUBSCRIPTION = gql`
  subscription GetVercelConfigurationStatusSubscription(
    $configurationId: String!
  ) {
    vercel_integration(where: { configuration_id: { _eq: $configurationId } }) {
      status
      vercel_integration_connections(
        where: {
          _and: [
            { status: { _neq: "disconnected" } }
            { status: { _neq: "outofscope" } }
            { status: { _neq: "failed" } }
          ]
        }
      ) {
        status
        hasura_cloud_project_id
        vercel_project_id
        project {
          id
          name
        }
      }
    }
  }
`;

export const GET_VERCEL_PROJECTS = gql`
  query VercelProjects($configurationId: String!, $teamId: String) {
    vercelGetProjects(configuration_id: $configurationId, team_id: $teamId) {
      vercelProjects {
        projectId
        projectName
      }
    }
  }
`;

export const GET_CONFIGURATION_STATUS = gql`
  query GetVercelConfigurationStatus($configurationId: String!) {
    vercel_integration(where: { configuration_id: { _eq: $configurationId } }) {
      status
      vercel_integration_connections(where: { status: { _eq: "connected" } }) {
        id
        hasura_cloud_project_id
        vercel_project_id
        status
      }
    }
  }
`;

export const GET_HASURA_PROJECTS = gql`
  query GetHasuraProjects {
    projects(where: { plan_name: { _neq: "pro" } }) {
      id
      name
    }
  }
`;
