import React from 'react';

import { StyledSVG } from '../Icon';

export const CreditCardIcon = props => {
  const { color } = props;

  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill={color}
      {...props}
    >
      <path d="M16.3636 3.33333V1.66667H4.90909V3.33333H16.3636ZM16.3636 10V5.83333H4.90909V10H16.3636ZM16.3636 0C16.7976 0 17.2138 0.175595 17.5207 0.488155C17.8276 0.800716 18 1.22464 18 1.66667V10C18 10.442 17.8276 10.8659 17.5207 11.1785C17.2138 11.4911 16.7976 11.6667 16.3636 11.6667H4.90909C4.00091 11.6667 3.27273 10.9167 3.27273 10V1.66667C3.27273 0.741667 4.00091 0 4.90909 0H16.3636ZM1.63636 13.3333H13.9091V15H1.63636C1.20237 15 0.786158 14.8244 0.47928 14.5118C0.172402 14.1993 0 13.7754 0 13.3333V4.16667H1.63636V13.3333Z" />
    </StyledSVG>
  );
};
