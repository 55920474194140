/*
 * Action constants
 * */

const CHANGE_INPUT = '@login/CHANGE_INPUT';
const LOGGING_IN = '@login/LOGGING_IN';
const LOGIN_FAIL = '@login/LOGIN_FAIL';
const LOGIN_SUCCESS = '@login/LOGIN_SUCCESS';
const RESET = '@login/RESET';

export type LoginState = {
  isLoggingIn: boolean;
  loginErr: any;
  loginForm: {
    email: string;
    password: string;
  };
  hasClickedForgotPassword: boolean;
  loginSuccess: boolean;
  loginRedirectURL: string;
};

const defaultState: LoginState = {
  isLoggingIn: false,
  loginErr: '',
  loginForm: {
    email: '',
    password: '',
  },
  hasClickedForgotPassword: false,
  loginSuccess: false,
  loginRedirectURL: '',
};

export type LoginAction = {
  type: string;
  data?: any;
};

const loginReducer = (
  state = defaultState,
  action: LoginAction
): LoginState => {
  switch (action.type) {
    case CHANGE_INPUT:
      return {
        ...state,
        loginForm: {
          ...state.loginForm,
          [action.data.key]: action.data?.value,
        },
      };
    case LOGGING_IN:
      return {
        ...state,
        isLoggingIn: true,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggingIn: false,
        loginErr: action.data,
      };
    case RESET:
      return {
        ...defaultState,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loginSuccess: true,
      };
    default:
      return {
        ...state,
      };
  }
};

export const fieldNames = {
  email: 'email',
  password: 'password',
};

export {
  CHANGE_INPUT,
  LOGGING_IN,
  LOGIN_FAIL,
  RESET,
  LOGIN_SUCCESS,
  defaultState,
};

export default loginReducer;
