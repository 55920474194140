import { z } from 'zod';

import { authEndpoints } from '../../../../utils/constants';
import { getRedir } from '../../utils';

function formatCredentials({
  email,
  password,
}: {
  email: string;
  password: string;
}) {
  return {
    email: email.toLowerCase().trim(),
    password: password.trim(),
  };
}

const responseSchema = z.object({
  location: z.string(),
});

export function signInWithEmail({
  email,
  password,
  search,
}: {
  email: string;
  password: string;
  search: string;
}): Promise<z.infer<typeof responseSchema>> {
  const redir = getRedir(search);

  const options: RequestInit = {
    credentials: 'include',
    body: JSON.stringify(formatCredentials({ email, password })),
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
  };

  let requestUrl = authEndpoints.login;

  const params = new URLSearchParams(window.location.search);

  const redirectUrl = params.get('redirect_url');

  if (
    redirectUrl &&
    (redirectUrl.includes('console.hasura.io') ||
      redirectUrl.includes('console.arusah.com'))
  ) {
    const platform = redirectUrl.includes('promptql')
      ? 'promptql_v3_console'
      : 'hasura_v3_console';

    requestUrl = `${requestUrl}?redir=${redir}?platform=${platform}`;
  } else {
    requestUrl = `${requestUrl}?redir=${redir}`;
  }

  return fetch(`${requestUrl}`, options)
    .then(resp => {
      return resp.json();
    })
    .then(resp => {
      if ('status' in resp && resp.status === 'failure') {
        if ('error' in resp) {
          throw new Error(resp.error);
        }
        if ('message' in resp) {
          throw new Error(resp.message);
        }
        throw new Error('Unexpected error. Get in touch with us for more info');
      }

      const response = responseSchema.safeParse(resp);

      if (!response.success) {
        console.warn(
          'Login response failed validation. Falling back to default location. Error:',
          response.error
        );
      }

      return response.success ? response.data : { location: '/' };
    })
    .catch(err => {
      if (err && err.message && err.message.includes('Failed to fetch')) {
        throw new Error(
          'Sign in failed: Please check your internet connection'
        );
      } else {
        throw new Error(`Sign in failed: ${err.message ?? err}`);
      }
    });
}
