import * as React from 'react';

import { StyledSVG } from '../Icon';

type Props = {
  color: string;
};

export const HelpIcon: React.FC<Props> = props => {
  const { color } = props;

  return (
    <StyledSVG
      viewBox="0 0 18 18"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      {...props}
    >
      <path
        d="M16.011 12.069C16.866 10.116 16.866 7.875 16.011 5.931L13.545 7.047C14.085 8.289 14.085 9.702 13.554 10.953L16.011 12.069ZM12.078 1.989C10.125 1.134 7.884 1.134 5.931 1.989L7.047 4.446C8.298 3.915 9.711 3.915 10.962 4.455L12.078 1.989ZM1.989 5.922C1.134 7.884 1.134 10.116 1.989 12.078L4.455 10.953C3.915 9.711 3.915 8.289 4.455 7.038L1.989 5.922ZM5.931 16.011C7.884 16.866 10.125 16.866 12.078 16.002L10.962 13.545C9.72 14.085 8.298 14.085 7.056 13.554L5.931 16.011ZM9 0C10.1819 0 11.3522 0.232792 12.4442 0.685084C13.5361 1.13738 14.5282 1.80031 15.364 2.63604C16.1997 3.47177 16.8626 4.46392 17.3149 5.55585C17.7672 6.64778 18 7.8181 18 9C18 11.3869 17.0518 13.6761 15.364 15.364C13.6761 17.0518 11.3869 18 9 18C7.8181 18 6.64778 17.7672 5.55585 17.3149C4.46392 16.8626 3.47177 16.1997 2.63604 15.364C0.948211 13.6761 0 11.3869 0 9C0 6.61305 0.948211 4.32387 2.63604 2.63604C4.32387 0.948211 6.61305 0 9 0ZM9 5.4C8.04522 5.4 7.12955 5.77928 6.45442 6.45442C5.77928 7.12955 5.4 8.04522 5.4 9C5.4 9.95478 5.77928 10.8705 6.45442 11.5456C7.12955 12.2207 8.04522 12.6 9 12.6C9.95478 12.6 10.8705 12.2207 11.5456 11.5456C12.2207 10.8705 12.6 9.95478 12.6 9C12.6 8.04522 12.2207 7.12955 11.5456 6.45442C10.8705 5.77928 9.95478 5.4 9 5.4Z"
        fill="#1EB4D4"
      />
    </StyledSVG>
  );
};
