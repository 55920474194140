import React from 'react';
import { rem } from '@mantine/core';
import { PiCheck, PiInfo, PiWarning, PiX } from 'react-icons/pi';

const iconSize = 20;
const iconStyle = { width: rem(iconSize), height: rem(iconSize) };
export const xIcon = <PiX style={iconStyle} />;
export const checkIcon = <PiCheck style={iconStyle} />;
export const infoIcon = <PiInfo style={iconStyle} />;
export const exclamationIcon = <PiWarning style={iconStyle} />;
