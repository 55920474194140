import React from 'react';

import { StyledSVG } from '../Icon';

export const CompleteIcon = props => {
  const { completeIconColor } = props;

  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill={completeIconColor}
      {...props}
    >
      <path d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM14.59 5.58L8 12.17L5.41 9.59L4 11L8 15L16 7L14.59 5.58Z" />
    </StyledSVG>
  );
};

CompleteIcon.defaultProps = {
  size: '20px',
  completeIconColor: '#1EB4D4',
};
