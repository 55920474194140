import React from 'react';

import { StyledCheckBox } from './Checkbox';

export const Checkbox = props => {
  const { children, name, checked, onChange, type, disabled = false } = props;

  return (
    <StyledCheckBox {...props}>
      <input
        id={name}
        type={type || 'checkbox'}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label htmlFor={name}>{children}</label>
    </StyledCheckBox>
  );
};

Checkbox.defaultProps = {
  mb: '-6px',
};
