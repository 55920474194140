import React from 'react';
import {
  ColorSchemeScript,
  createTheme,
  MantineProvider as Provider,
} from '@mantine/core';
import { Notifications } from '@mantine/notifications';

const mantineTheme = createTheme({
  fontFamily: 'Inter, ui-sans-serif, system-ui, sans-serif',
  primaryColor: 'indigo',
  primaryShade: 6,
  defaultRadius: 'xl',
  cursorType: 'pointer',
  headings: {
    fontWeight: '500',
  },
});

// because wrapping the entire Lux app with this provider disrupts styles
// this component can be used to wrap components that need Mantine
export default function MantineProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  console.log('MantineProvider');
  return (
    <>
      <link rel="stylesheet" type="text/css" href="/css/mantine-core.css" />
      <link
        rel="stylesheet"
        type="text/css"
        href="/css/mantine-notifications.css"
      />
      <link rel="stylesheet" type="text/css" href="/css/mantine-custom.css" />
      <ColorSchemeScript />
      <Provider theme={mantineTheme} defaultColorScheme="auto">
        <Notifications />
        {children}
      </Provider>
    </>
  );
}
