// import fetch from 'isomorphic-fetch';

// Our goal here is to create a custom `fetch` that throws on
// HTTP status code 4XX and 500 with custom error messages.
// To do so, we wrap fetch in another promise that handles the
// response like what we want.
//
// TODO: Simplify the function body to `return fetch(url, options).then(//handlers)`
const requestAuthServer = (url, options) => {
  const p1 = new Promise(resolve => {
    fetch(url, options).then(
      response => {
        /**
         * The auth server returns 401 if the user is not logged in.
         * This is handled as an expected response and not as an error
         * Ref: https://github.com/hasura/lux/pull/7397/files
         */
        if (response.ok || response.status === 401) {
          return response.json().then(results => {
            return resolve(results);
          });
        }
        if (response.status >= 400 && response.status < 500) {
          return response.json().then(errorMsg => {
            throw new Error(errorMsg);
          });
        }
        if (response.status === 500) {
          return response
            .json()
            .then(() => {
              throw new Error('Unexpected error');
            })
            .catch(() => {
              throw new Error('Unexpected error');
            });
        }
        throw new Error('Unexpected error');
      },
      error => {
        throw error;
      }
    );
  });
  return p1;
};

export default requestAuthServer;
