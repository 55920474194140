import { gql } from '@apollo/client';

export const GET_COLLABORATORS = gql`
  query fetchProjectAndPrivilegesCollaborators($id: uuid!) {
    projectCollaboratorsMetadata: projects_by_pk(id: $id) {
      id
      invitations(
        where: {
          accepted_at: { _is_null: true }
          declined_at: { _is_null: true }
        }
        distinct_on: collaborator_email
        order_by: [{ collaborator_email: desc }, { id: desc }]
      ) {
        id
        collaborator_email
        privileges
        key
      }
      collaborators(where: { status: { _eq: "active" } }) {
        id
        status
        user: collaborator {
          id
          email
        }
        project_collaborator_allowed_schemas {
          schema
        }
        privileges: project_collaborator_privileges {
          slug: privilege_slug
        }
      }
      project_billing_manager_invitations(
        where: {
          accepted_at: { _is_null: true }
          declined_at: { _is_null: true }
        }
        distinct_on: manager_email
        order_by: [{ manager_email: desc }, { id: desc }]
      ) {
        manager_email
        key
      }
      billing_manager {
        email
      }
    }
  }
`;

export const FETCH_INVITATION_PROJECT_COLLABORATION_STATUS = gql`
  query fetchInvitationProjectCollaborationStatus(
    $key: String!
    $userEmail: String!
    $projectId: uuid!
  ) {
    project_collaborator_invitations(where: { key: { _eq: $key } }) {
      accepted_at
    }
    users(where: { email: { _eq: $userEmail } }) {
      email
      collaborated_projects(
        where: { status: { _eq: "active" }, project_id: { _eq: $projectId } }
      ) {
        project_id
        status
      }
    }
  }
`;

export const FETCH_BILLING_OWNER_STATUS = gql`
  subscription fetchBillingOwnerStatus($projectId: uuid!) {
    projects(where: { id: { _eq: $projectId } }) {
      billing_manager_id
    }
  }
`;
