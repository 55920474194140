import { User } from '../../../../utils/types';
import { UserEnabledEntitlements } from './types';

/**
 * hasExceededFreeProjectsLimit is true if
 * a user has more than 3 projects on the cloud_free_v2 plan
 * always returns false for enterprise users
 * @returns boolean
 */
export const getHasExceededFreeProjectsLimit = (user: User): boolean => {
  if (!user) {
    return false;
  }

  // always return false for enterprise users
  if (user?.enterprise_users?.is_active) {
    return false;
  }

  if (!user?.projects_aggregate?.aggregate) {
    return false;
  }

  return user.projects_aggregate.aggregate.count >= 3;
};

export const getUserEntitlents = (user: User): UserEnabledEntitlements => {
  if (!user?.entitlements?.length) {
    return {};
  }

  return user.entitlements.reduce((acc, e) => {
    if (!e.entitlement) {
      return acc;
    }

    acc[e?.entitlement?.name || ''] = true;
    return acc;
  }, {} as UserEnabledEntitlements);
};
