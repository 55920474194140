import React from 'react';

import { StyledSVG } from '../Icon';

export const SettingIcon = props => {
  const { color } = props;

  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 17"
      fill={color}
      {...props}
    >
      <path d="M8.00182 11.475C7.23838 11.475 6.50621 11.1616 5.96637 10.6036C5.42654 10.0457 5.12327 9.28902 5.12327 8.5C5.12327 7.71098 5.42654 6.95428 5.96637 6.39636C6.50621 5.83844 7.23838 5.525 8.00182 5.525C8.76525 5.525 9.49743 5.83844 10.0373 6.39636C10.5771 6.95428 10.8804 7.71098 10.8804 8.5C10.8804 9.28902 10.5771 10.0457 10.0373 10.6036C9.49743 11.1616 8.76525 11.475 8.00182 11.475ZM14.1126 9.3245C14.1455 9.0525 14.1701 8.7805 14.1701 8.5C14.1701 8.2195 14.1455 7.939 14.1126 7.65L15.8479 6.2645C16.0042 6.137 16.0453 5.9075 15.9466 5.7205L14.3017 2.7795C14.203 2.5925 13.981 2.516 13.8 2.5925L11.7522 3.4425C11.3245 3.111 10.8804 2.822 10.3622 2.6095L10.0579 0.357C10.025 0.153 9.85231 0 9.6467 0H6.35693C6.15132 0 5.97861 0.153 5.94571 0.357L5.64141 2.6095C5.12327 2.822 4.67915 3.111 4.25148 3.4425L2.2036 2.5925C2.02266 2.516 1.8006 2.5925 1.70191 2.7795L0.0570212 5.7205C-0.0498963 5.9075 -0.00054964 6.137 0.155714 6.2645L1.89107 7.65C1.85817 7.939 1.8335 8.2195 1.8335 8.5C1.8335 8.7805 1.85817 9.0525 1.89107 9.3245L0.155714 10.7355C-0.00054964 10.863 -0.0498963 11.0925 0.0570212 11.2795L1.70191 14.2205C1.8006 14.4075 2.02266 14.4755 2.2036 14.4075L4.25148 13.549C4.67915 13.889 5.12327 14.178 5.64141 14.3905L5.94571 16.643C5.97861 16.847 6.15132 17 6.35693 17H9.6467C9.85231 17 10.025 16.847 10.0579 16.643L10.3622 14.3905C10.8804 14.1695 11.3245 13.889 11.7522 13.549L13.8 14.4075C13.981 14.4755 14.203 14.4075 14.3017 14.2205L15.9466 11.2795C16.0453 11.0925 16.0042 10.863 15.8479 10.7355L14.1126 9.3245Z" />
    </StyledSVG>
  );
};

SettingIcon.defaultProps = {
  size: '17px',
};
