import { css } from 'styled-components/macro';

export const textEllipses = ({ ellipsis }) => {
  if (ellipsis) {
    return css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `;
  }

  return '';
};

export const getCursorType = ({ pointer }) => {
  if (pointer) {
    return css`
      cursor: pointer;
    `;
  }

  return '';
};

export const hoverStyles = ({ hoverColor }) => {
  if (hoverColor) {
    return css`
      &:hover {
        color: ${hoverColor};
      }
    `;
  }

  return '';
};
