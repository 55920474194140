import styled from 'styled-components/macro';
import {
  border,
  color,
  layout,
  shadow,
  space,
  typography,
} from 'styled-system';

export const StyledSpinner = styled.div`
  position: relative;

  ${color}
  ${border}
	  ${typography}
	  ${layout}
	  ${space}
	  ${shadow}
`;
