import styled from 'styled-components';
import {
  border,
  color,
  flexbox,
  layout,
  space,
  typography,
} from 'styled-system';

import { Flex } from '../Flex';

export const StyledDropdown = styled(Flex)`
  ${layout}
  ${space}
  ${typography}
  ${color}
  ${border}
  ${flexbox}
`;

export const StyledArrow = styled.div`
  box-sizing: border-box;
  height: 7px;
  width: 7px;
  transform: rotate(45deg);
  margin-left: 12px;
  margin-bottom: 3px;
  border-style: solid;

  border-color: ${props => props.color};
  border-width: 0px 1.25px 1px 0px;
`;
