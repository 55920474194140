import React from 'react';

import { theme } from '../theme';
import { AddIcon } from './Add';
import { AddCircleIcon } from './AddCircle';
import { AngleRightIcon } from './AngleRight';
import { AnnouncementIcon } from './Announcement';
import { BellIcon } from './Bell';
import { BillIcon } from './Bill';
import { CreditCardIcon } from './Card';
import { CaretRight } from './CaretRight';
import { CheckIcon } from './Check';
import { CheckCircle } from './CheckCircle';
import { CheckCircleFilled } from './CheckCircleFilled';
import { ClockIcon } from './Clock';
import { CloseIcon } from './Close';
import { CompanyIcon } from './Company';
import { CompleteIcon } from './Complete';
import { CopyIcon } from './Copy';
import { CouponIcon } from './Coupon';
import { DeleteIcon } from './Delete';
import { DocumentIcon } from './Document';
import { DownloadIcon } from './Download';
import { EditIcon } from './Edit';
import { EmailIcon } from './Email';
import { Enterprise } from './Enterprise';
import { ErrorIcon } from './Error';
import { ExclamationHexagon } from './ExclamationHexagon';
import { ExclamationTriangle } from './ExclamationTriangle';
import { ExternalLink } from './ExternalLink';
import { GithubIcon } from './Github';
import { GSTIcon } from './GST';
import { HasuraIcon } from './Hasura';
import { HelpIcon } from './Help';
import { Incognito } from './Incognito';
import { InfoIcon } from './Info';
import { KeyIcon } from './Key';
import { KeyboardIcon } from './Keyboard';
import { LaunchIcon } from './Launch';
import { LearnIcon } from './Learn';
import { LeftArrowIcon } from './LeftArrow';
import { LinkIcon } from './Link';
import { LocationIcon } from './Location';
import { LogoutIcon } from './Logout';
import { MaintenanceIcon } from './Maintenance';
import { NetworkIcon } from './Network';
import { OpenIcon } from './Open';
import { PasswordIcon } from './Password';
import { PlayIcon } from './Play';
import { PlusIcon } from './Plus';
import { Pound } from './Pound';
import { ProfileIcon } from './Profile';
import { ProjectIcon } from './Project';
import { RefreshIcon } from './Refresh';
import { ResendIcon } from './Resend';
import { RightArrowIcon } from './RightArrow';
import { RollbackIcon } from './Rollback';
import { RunIcon } from './Run';
import { SearchIcon } from './Search';
import { SettingIcon } from './Settings';
import { Snowflake } from './Snowflake';
import { SparkleIcon } from './Sparkle';
import { Speedometer } from './Speedometer';
import { SuccessIcon } from './Success';
import { TableIcon } from './Table';
import { Tada } from './Tada';
import { UpdateIcon } from './Update';
import { VercelIcon } from './Vercel';
import { ViewIcon } from './View';
import { WandIcon } from './Wand';
import { WarnIcon } from './Warn';
import { WebIcon } from './Web';

const iconReferenceMap = {
  wand: WandIcon,
  project: ProjectIcon,
  edit: EditIcon,
  link: LinkIcon,
  bill: BillIcon,
  password: PasswordIcon,
  email: EmailIcon,
  document: DocumentIcon,
  profile: ProfileIcon,
  learn: LearnIcon,
  search: SearchIcon,
  settings: SettingIcon,
  key: KeyIcon,
  help: HelpIcon,
  view: ViewIcon,
  keyboard: KeyboardIcon,
  web: WebIcon,
  add: AddIcon,
  warn: WarnIcon,
  close: CloseIcon,
  delete: DeleteIcon,
  default: WebIcon,
  bell: BellIcon,
  check: CheckIcon,
  success: SuccessIcon,
  copy: CopyIcon,
  back: LeftArrowIcon,
  externalLink: ExternalLink,
  info: InfoIcon,
  refresh: RefreshIcon,
  table: TableIcon,
  creditCard: CreditCardIcon,
  caretRight: CaretRight,
  error: ErrorIcon,
  complete: CompleteIcon,
  logout: LogoutIcon,
  hasura: HasuraIcon,
  gst: GSTIcon,
  location: LocationIcon,
  company: CompanyIcon,
  download: DownloadIcon,
  rightArrow: RightArrowIcon,
  sparkle: SparkleIcon,
  resend: ResendIcon,
  coupon: CouponIcon,
  addButton: AddCircleIcon,
  open: OpenIcon,
  plus: PlusIcon,
  rollback: RollbackIcon,
  launch: LaunchIcon,
  maintenance: MaintenanceIcon,
  update: UpdateIcon,
  clock: ClockIcon,
  enterprise: Enterprise,
  play: PlayIcon,
  run: RunIcon,
  network: NetworkIcon,
  angleRight: AngleRightIcon,
  checkCircle: CheckCircle,
  checkCircleFilled: CheckCircleFilled,
  vercel: VercelIcon,
  speedometer: Speedometer,
  github: GithubIcon,
  exclamationTriangle: ExclamationTriangle,
  exclamationHexagon: ExclamationHexagon,
  snowflake: Snowflake,
  announcement: AnnouncementIcon,
  incognito: Incognito,
  pound: Pound,
  tada: Tada,
};

export const Icon = props => {
  const { type, isActive, color } = props;

  const { icon } = theme;

  let iconColor;

  if (color) {
    iconColor = color;
  } else {
    iconColor = isActive ? icon.colors.active : icon.colors.default;
  }

  const CurrentActiveIcon = iconReferenceMap[type] || iconReferenceMap.default;

  return <CurrentActiveIcon {...props} color={iconColor} aria-hidden="true" />;
};
