import * as React from 'react';

import { StyledPopupModal } from './StyledPopupModal';

interface PopupModalProps {
  onClose: VoidFunction;
  open: boolean;
  id?: string;
  children: React.ReactNode;
  opaqueMask?: boolean;
}

export const PopupModal: React.FC<PopupModalProps> = ({
  onClose,
  open,
  children,
  id,
  opaqueMask,
}) => {
  const onOverlayClick = () => {
    onClose();
  };

  const onDialogClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  if (open) {
    return (
      <StyledPopupModal id={id} opaqueMask={opaqueMask}>
        <div
          onClick={onOverlayClick}
          className="modal-content-div"
          style={{ paddingBottom: 0 }}
        >
          <div className="modal-dialog-div" onClick={onDialogClick}>
            {children}
          </div>
        </div>
      </StyledPopupModal>
    );
  }

  return null;
};
