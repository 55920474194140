import { getSearchParam } from '../../../utils/helpers';

export const parseGithubURL = (url: string) => {
  let githubURL;
  try {
    githubURL = new URL(url);
  } catch (_) {
    return null;
  }

  if (
    githubURL.hostname !== 'github.com' &&
    githubURL.hostname !== 'www.github.com'
  ) {
    return null;
  }

  const githubURLPathSplit = githubURL.pathname.split('/');

  if (
    githubURLPathSplit.length < 3 ||
    (githubURLPathSplit.length >= 3 &&
      (githubURLPathSplit[1] == '' || githubURLPathSplit[2] == ''))
  )
    return null;

  return githubURL.pathname.substring(1);
};

export const getUserFacingOneClickDeploymentCreationError = (
  backendError: string
) => {
  if (backendError.includes('access is restricted')) {
    return 'One click deployment to Hasura Cloud is currently restricted. It will be available soon.';
  }
  if (backendError.includes('failed parsing the GitHub repo URL')) {
    return 'We could not parse the given GitHub repo URL in the deploy link.';
  }
  if (backendError.includes('failed getting the repository')) {
    return 'We could not find the given repository on GitHub.';
  }
  if (backendError.includes('failed getting the branch')) {
    return 'We could not find the given branch in the GitHub repository.';
  }
  if (backendError.includes('could not find a valid Hasura project')) {
    return 'We could not find a valid Hasura project at the given Hasura directory path of the GitHub repository.';
  }
  if (
    backendError.includes(
      'there was an internal error while serving your request'
    )
  ) {
    return 'That is unexpected. Please refresh to try again.';
  }

  return `Error: ${backendError}`;
};

export const isHasuraPath = (path: string | null) => {
  if (!path) return false;
  const [owner] = path.split('/');
  return owner === 'hasura';
};

export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts && parts.length === 2) return parts.pop()?.split(';').shift();

  return null;
};

export const trackingParams = [
  'utm_campaign',
  'utm_medium',
  'utm_source',
  'utm_term',
  'tech',
  'plcmt',
  'cta',
  'pg',
  'ft_trk', // source of feature
];

export const getTrackingCookies = () => {
  const trackingCookiesValues: Record<string, string | undefined> = {};

  trackingParams.forEach(param => {
    const cookie = getCookie(param);

    if (cookie) trackingCookiesValues[param] = cookie;
  });

  return trackingCookiesValues;
};

export const getTrackingUrlParams = () => {
  const trackingUrlParamsValues: Record<string, string | undefined> = {};

  trackingParams.forEach(param => {
    const searchParam = getSearchParam(window.location.search, param);

    if (searchParam) trackingUrlParamsValues[param] = searchParam;
  });

  return trackingUrlParamsValues;
};
