import React from 'react';

import { StyledSVG } from '../Icon';

export const CouponIcon = props => {
  const { color } = props;

  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 20 20"
      fill={color}
      {...props}
    >
      <path d="M16.2 11.3684H1.8V5.68421H6.372L4.5 8.36526L5.958 9.47368L8.1 6.40421L9 5.11579L9.9 6.40421L12.042 9.47368L13.5 8.36526L11.628 5.68421H16.2V11.3684ZM16.2 16.1053H1.8V14.2105H16.2V16.1053ZM6.3 1.89474C6.53869 1.89474 6.76761 1.99455 6.9364 2.17221C7.10518 2.34988 7.2 2.59085 7.2 2.84211C7.2 3.09336 7.10518 3.33433 6.9364 3.512C6.76761 3.68966 6.53869 3.78947 6.3 3.78947C6.06131 3.78947 5.83239 3.68966 5.6636 3.512C5.49482 3.33433 5.4 3.09336 5.4 2.84211C5.4 2.59085 5.49482 2.34988 5.6636 2.17221C5.83239 1.99455 6.06131 1.89474 6.3 1.89474ZM11.7 1.89474C11.9387 1.89474 12.1676 1.99455 12.3364 2.17221C12.5052 2.34988 12.6 2.59085 12.6 2.84211C12.6 3.09336 12.5052 3.33433 12.3364 3.512C12.1676 3.68966 11.9387 3.78947 11.7 3.78947C11.4613 3.78947 11.2324 3.68966 11.0636 3.512C10.8948 3.33433 10.8 3.09336 10.8 2.84211C10.8 2.59085 10.8948 2.34988 11.0636 2.17221C11.2324 1.99455 11.4613 1.89474 11.7 1.89474ZM16.2 3.78947H14.238C14.337 3.49579 14.4 3.17368 14.4 2.84211C14.4 2.08833 14.1155 1.36543 13.6092 0.832433C13.1028 0.299435 12.4161 0 11.7 0C10.755 0 9.936 0.511579 9.45 1.27895L9 1.89474L8.55 1.26947C8.064 0.511579 7.245 0 6.3 0C5.58392 0 4.89716 0.299435 4.39081 0.832433C3.88446 1.36543 3.6 2.08833 3.6 2.84211C3.6 3.17368 3.663 3.49579 3.762 3.78947H1.8C0.801 3.78947 0 4.63263 0 5.68421V16.1053C0 17.1568 0.801 18 1.8 18H16.2C17.199 18 18 17.1568 18 16.1053V5.68421C18 4.63263 17.199 3.78947 16.2 3.78947Z" />
    </StyledSVG>
  );
};
