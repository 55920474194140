import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient as createWsClient } from 'graphql-ws';

import { cleanupBrowserState, isUserLoggedIn } from './utils/auth';
import { trackGraphQLError } from './utils/sentry';

/*
const formAccessKeyHeader = (ak) => {
  return {
    Authorization: `Bearer ${ ak }`,
  };
};
*/

const makeApolloClient = (httpUrl: string, wsUrl: string) => {
  // Create an http link:
  const httpLink = new HttpLink({
    uri: httpUrl,
    credentials: 'include',
  });
  const wsLink = new GraphQLWsLink(
    createWsClient({
      url: wsUrl,
      shouldRetry: () => true,
      retryAttempts: 10,
    })
  );

  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors && graphQLErrors.length) {
      if (
        graphQLErrors[0].message.includes("not found in type: 'query_root'") ||
        graphQLErrors[0].message.includes(
          "not found in type: 'mutation_root'"
        ) ||
        graphQLErrors[0].message.includes("not found in type: 'Query'") ||
        graphQLErrors[0].message.includes("not found in type: 'Mutation'") ||
        graphQLErrors[0].message.includes(
          'no such type exists in the schema'
        ) ||
        graphQLErrors[0].message.includes('access denied')
      ) {
        isUserLoggedIn().then(isLoggedIn => {
          if (!isLoggedIn) {
            cleanupBrowserState();
            window.location.replace(window.location.origin);
          }
        });
      }
      graphQLErrors.forEach(e => {
        trackGraphQLError(e);
      });
    }
  });

  const link = new RetryLink({
    delay: {
      initial: 100,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 10,
      retryIf: error => !!error,
    },
  }).split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink
  );

  const client = new ApolloClient({
    link: errorLink.concat(link),
    cache: new InMemoryCache({
      addTypename: true,
    }),
  });

  return client;
};

export default makeApolloClient;
