import React from 'react';

import { StyledSVG } from '../Icon';

export const ProjectIcon = props => {
  const { color } = props;

  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill={color}
      {...props}
    >
      <path d="M5.18182 8.63636H0V10.3636H5.18182V8.63636ZM7.05591 5.83818L5.225 4.00727L4.00727 5.225L5.83818 7.05591L7.05591 5.83818ZM10.3636 0H8.63636V5.18182H10.3636V0ZM14.9927 5.225L13.775 4.00727L11.9441 5.83818L13.1618 7.05591L14.9927 5.225ZM13.8182 8.63636V10.3636H19V8.63636H13.8182ZM9.5 6.90909C8.81285 6.90909 8.15384 7.18206 7.66795 7.66795C7.18206 8.15384 6.90909 8.81285 6.90909 9.5C6.90909 10.1872 7.18206 10.8462 7.66795 11.3321C8.15384 11.8179 8.81285 12.0909 9.5 12.0909C10.1872 12.0909 10.8462 11.8179 11.3321 11.3321C11.8179 10.8462 12.0909 10.1872 12.0909 9.5C12.0909 8.81285 11.8179 8.15384 11.3321 7.66795C10.8462 7.18206 10.1872 6.90909 9.5 6.90909ZM11.9441 13.1618L13.775 14.9927L14.9927 13.775L13.1618 11.9441L11.9441 13.1618ZM4.00727 13.775L5.225 14.9927L7.05591 13.1618L5.83818 11.9441L4.00727 13.775ZM8.63636 19H10.3636V13.8182H8.63636V19Z" />
    </StyledSVG>
  );
};
