import React from 'react';

import { StyledModal } from './Modal';

export const Modal = props => {
  const { placement, width, height, type, maxWidth, minWidth } = props;

  // Confirmation box
  if (type === 'confirm') {
    return (
      <StyledModal
        {...props}
        borderRadius="8px"
        minWidth="328px"
        maxWidth={width || '360px'}
        height="auto"
        boxShadow={1}
        opacity={1}
      />
    );
  }

  switch (placement) {
    case 'left':
      return (
        <StyledModal
          borderTopRightRadius="10px"
          borderBottomRightRadius="10px"
          minWidth="512px"
          width="40%"
          maxWidth="650px"
          minHeight="100vh"
          maxHeight="100%"
          position="absolute"
          zIndex="card"
          {...props}
        />
      );
    case 'right':
      return (
        <StyledModal
          width="40%"
          minHeight="100vh"
          maxHeight="100%"
          {...props}
          boxShadow={0}
        />
      );
    case 'content':
      return (
        <StyledModal
          {...props}
          borderRadius="8px"
          minWidth={minWidth || '512px'}
          width={width || '50%'}
          maxWidth={maxWidth || '650px'}
          minHeight={height || '592px'}
          maxHeight="100%"
          boxShadow={1}
        />
      );
    case 'sidebar':
      return (
        <StyledModal
          {...props}
          position="absolute"
          overflow="hidden"
          top={0}
          right={0}
          minWidth="512px"
          width={width || '35%'}
          maxWidth="560px"
          height="100vh"
          boxShadow="rightSidebar"
          // TODO: refactor this
          px="56px"
          pt="40px"
          pb="45px"
        />
      );
    default:
      return (
        <StyledModal
          {...props}
          position="absolute"
          overflow="hidden"
          top={0}
          right={0}
          minWidth={minWidth || '512px'}
          width={width || '35%'}
          maxWidth="505px"
          height="100vh"
          boxShadow="rightSidebar"
        />
      );
  }
};

Modal.defaultProps = {
  boxShadow: 'modal',
  bg: 'white',
  flexDirection: 'column',
};
