import * as React from 'react';
import { css } from 'styled-components/macro';

import { getCurrentYear } from '../../utils/time';
import { Banner } from '../Banners';
import landingBackgroundImg from '../images/register-bg.svg';
import { Flex, Text } from '../UIKit';

interface LayoutProps {
  withoutFeaturesList?: boolean;
  plainBackground?: boolean;
  children: React.ReactNode;
}

export const LandingLayout: React.FC<LayoutProps> = ({
  children,
  plainBackground = false,
}) => (
  <>
    <Banner />
    <Flex
      width="100%"
      minHeight="100vh"
      height="100%"
      maxHeight="auto"
      overflowX="hidden"
      flexDirection="column"
      justifyContent="center"
      pb={{ _: '0', lg: '3.4%' }}
      // fallback background
      bg={plainBackground ? '#f8fafb' : '#fff'}
      css={
        plainBackground
          ? css`
              background-size: cover;
              font-size: 1.25em;
              font-size: 1.1vw !important;
              @media screen and (max-width: 750px) {
                background: #f8fafb;
                justify-content: space-between;
              }
              @media screen and (min-width: 990px) {
                #m-hasura-logo {
                  display: none;
                }
              }
            `
          : css`
              background-image: url(${landingBackgroundImg});
              background-size: cover;
              font-size: 1.25em;
              font-size: 1.1vw !important;
              @media screen and (max-width: 750px) {
                background: #fff;
                justify-content: space-between;
              }
              @media screen and (min-width: 990px) {
                #m-hasura-logo {
                  display: none;
                }
              }
            `
      }
    >
      {children}
      <Text
        fontSize={{ _: '0.875rem', lg: '0.9rem' }}
        fontWeight="bold"
        letterSpacing="0.04em"
        color="#fff"
        mt="3%"
        position="absolute"
        bottom="3%"
        css={css`
          @media screen and (max-height: 900px) {
            position: static;
            margin-top: 1%;
            margin-bottom: 30px;
          }

          @media screen and (max-width: 750px) {
            position: static;
            margin-top: 1%;
            margin-bottom: 30px;
          }
        `}
      >
        &copy; {getCurrentYear()} Hasura Inc. All rights reserved
      </Text>
    </Flex>
  </>
);

export const LoginLayout: React.FC<LayoutProps> = ({
  children,
  plainBackground = false,
}) => (
  <LandingLayout plainBackground={plainBackground}>
    <Flex
      minWidth="560px"
      width={{ _: '38.8%', lg: '38%' }}
      maxWidth="750px"
      px={{ _: '5%', lg: '4%', xl: '4%' }}
      bg="white"
      minHeight="600px"
      height="76vh"
      maxHeight="750px"
      flexDirection="column"
      overflowY="auto"
      borderRadius="8px"
      boxShadow={4}
      my="20px"
      css={css`
        @media screen and (max-width: 750px) {
          box-shadow: none;
          min-width: 300px;
          width: 100%;
          max-width: 550px;
          padding: 0 6%;

          #m-sso-heading {
            font-size: 1.35rem;
          }
        }
      `}
    >
      {children}
    </Flex>
  </LandingLayout>
);
