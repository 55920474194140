import React, { SVGProps } from 'react';

const HasuraLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={50}
    height={37}
    viewBox="0 0 50 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M34.84 12.558c1.052-3.308.42-9.907-1.619-12.343a.61.61 0 0 0-.985.079l-2.51 3.98a1.92 1.92 0 0 1-2.58.423A17.114 17.114 0 0 0 17.67 1.85c-3.496 0-6.75 1.046-9.474 2.847a1.925 1.925 0 0 1-2.58-.423L3.106.294a.613.613 0 0 0-.987-.08C.082 2.656-.55 9.255.501 12.559a7.04 7.04 0 0 1 .239 3.39C.534 17.07.329 18.422.329 19.364c0 9.674 7.765 17.514 17.338 17.514 9.577 0 17.338-7.844 17.338-17.514 0-.942-.206-2.295-.411-3.416a7.093 7.093 0 0 1 .246-3.39Zm-17.173 20.41c-7.404 0-13.423-6.084-13.423-13.562a13.569 13.569 0 0 1 7.687-12.252 13.173 13.173 0 0 1 5.74-1.303c2.054 0 3.997.465 5.74 1.307a13.574 13.574 0 0 1 7.686 12.252c-.004 7.474-6.027 13.558-13.43 13.558Z"
      fill="#3970FD"
    />
    <path
      d="m23.483 24.627-3.43-6.01-2.942-5.009a.384.384 0 0 0-.333-.19h-2.81a.39.39 0 0 0-.333.59l2.814 4.776-3.775 5.818a.395.395 0 0 0-.017.398.385.385 0 0 0 .341.204h2.831a.382.382 0 0 0 .325-.179l2.041-3.22 1.833 3.208c.07.12.197.195.333.195h2.79c.14 0 .266-.075.332-.195a.382.382 0 0 0 0-.386Z"
      fill="#3970FD"
    />
  </svg>
);

export default HasuraLogo;
