import React, { SVGProps } from 'react';

export const Pound = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.70051 12L7.6096 0.363636H8.74596L6.83687 12H5.70051ZM0.405051 8.77273L0.58687 7.63636H9.26869L9.08687 8.77273H0.405051ZM1.6096 12L3.51869 0.363636H4.65505L2.74596 12H1.6096ZM1.08687 4.72727L1.26869 3.59091H9.95051L9.76869 4.72727H1.08687Z"
      fill="#1E1E1E"
    />
  </svg>
);
