import React from 'react';

import { StyledListItem, StyledUnorderedList } from './ListItem';

export const UnorderedList = props => {
  const { children } = props;

  return <StyledUnorderedList>{children}</StyledUnorderedList>;
};

export const ListItem = props => {
  const { children } = props;

  return <StyledListItem {...props}>{children}</StyledListItem>;
};

ListItem.defaultProps = {
  mb: 0,
  mt: 0,
  lineHeight: 1.5,
  color: 'black.text',
  fontSize: '16px',
};
