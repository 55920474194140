import React from 'react';

import { EE_MODE } from '../../utils/constants';
import HasuraLogo from '../Dashboard/common/HasuraLogo';
import { Button, Flex, Text, ToolTip } from '../UIKit';

interface LoginHeaderProps {
  isBackButtonActive: boolean;
  handleBackButton: () => void;
  showFullBlackLogo?: boolean;
}

export const LoginHeader: React.FC<LoginHeaderProps> = ({
  isBackButtonActive,
  handleBackButton,
  showFullBlackLogo = false,
}) => {
  return (
    <Flex flexDirection="column">
      <Flex width="100%" justifyContent="space-between" margin={{ _: '12% 0' }}>
        {isBackButtonActive ? (
          <ToolTip message="Sign in">
            <Button
              id="signup-back-btn"
              variant="back"
              ml="-10px"
              mb="-7px"
              onClick={handleBackButton}
            />
          </ToolTip>
        ) : (
          <Text as="span" width="38px" ml="-10px" />
        )}
        <HasuraLogo
          width="27.71%"
          color={showFullBlackLogo ? 'full-black' : 'black'}
        />
        {/* To manage space */}
        <Text as="span" width="30px" />
      </Flex>
      {/* Forget Password page */}
      {!EE_MODE && !isBackButtonActive && (
        <Flex flexDirection="column" mb="6.5%">
          <Text color="#1B2738" fontWeight="bold" fontSize="1rem">
            No Credit Card Required
          </Text>
          <Text fontSize="0.75rem" color="#1B2738" fontWeight={500}>
            <h1>Try Hasura today, it’s absolutely free</h1>
          </Text>
        </Flex>
      )}
    </Flex>
  );
};
