import React, { useReducer, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  authEndpoints,
  HASURA_PRIVACY_POLICY_LINK,
  HASURA_TERMS_AND_CONDITIONS_LINK,
} from '../../utils/constants';
import {
  isValidPassword,
  parseAuthResponse,
  validateEmail,
} from '../../utils/helpers';
import NetworkLoader from '../../utils/NetworkLoader';
import { segmentIdentify } from '../../utils/segment';
import signupSuccessImg from '../images/signup_success.png';
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Image,
  Input,
  Label,
  Link,
  Text,
} from '../UIKit';
import { AuthAlert } from './AuthAlert';
import { useValidateOneClickDeploymentUrlAuth } from './hooks';
import { PasswordInput } from './PasswordInput';
import signupReducer, {
  SIGNING_UP,
  SIGNUP_FAIL,
  SIGNUP_SUCCESS,
} from './SignupActions';

// exported to reset password.

export const passwordErrorMessage =
  'Password must include at least 8 characters and at most 64 characters, with at least: 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character (!@#$%^&*()-+).';

// *********************** //

const defaultState = {
  isSignupByEmail: false,
  isSigningUp: false,
  hasAcceptedTnC: false,
  signupErr: '',
  signupSuccess: false,
  signupSuccessMessage: '',
  firstName: '',
  lastName: '',
  email: '',
  organization: '',
  password: '',
};

const registrationUrl = authEndpoints.signup;

export const SignupForm = ({
  handleResendEmail,
  signInURL,
}: {
  handleResendEmail: (email: string, setError: (err: string) => void) => void;
  signInURL: string;
}): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();

  const [state, setState] = useState(defaultState);
  const [signupState, dispatch] = useReducer(signupReducer, defaultState);

  const setSignupError = (err: string) => {
    dispatch({
      type: SIGNUP_FAIL,
      data: err,
    });
    dispatch({
      type: SIGNUP_SUCCESS,
      data: '',
    });
  };

  // ******************* //

  const handlePasswordInput = (passwordInput: string): void => {
    setState(prevState => ({
      ...prevState,
      password: passwordInput,
    }));
  };

  const handleTNCCheckboxInput = (checked: boolean): void => {
    setState(prevState => ({
      ...prevState,
      hasAcceptedTnC: checked,
    }));
  };

  // ******************* //

  const { signupErr, signupSuccessMessage, signupSuccess, isSigningUp } =
    signupState;

  const [oneClickDeployTitle] = useValidateOneClickDeploymentUrlAuth();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;

    dispatch({
      type: SIGNUP_FAIL,
      data: '',
    });

    if (signupSuccessMessage && signupSuccessMessage.length) {
      dispatch({
        type: SIGNUP_SUCCESS,
        data: '',
      });
    }

    setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const { firstName, lastName, email, organization, password, hasAcceptedTnC } =
    state;

  const trimmedFirstName = firstName.trim();
  const trimmedLastName = lastName.trim();
  const trimmedOrganization = organization.trim();
  const trimmedEmail = email.trim().toLowerCase();

  const handleRegistration = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    // Segment Identify call when the user hits Create my account`
    segmentIdentify(trimmedEmail);

    if (!trimmedFirstName) {
      return dispatch({
        type: SIGNUP_FAIL,
        data: 'Please enter your firstname.',
      });
    }
    if (!trimmedLastName) {
      return dispatch({
        type: SIGNUP_FAIL,
        data: 'Please enter your lastname.',
      });
    }
    if (!validateEmail(trimmedEmail)) {
      return dispatch({
        type: SIGNUP_FAIL,
        data: 'Please enter a valid email address.',
      });
    }

    // **************** //

    if (isValidPassword(password)) {
      dispatch({
        type: SIGNING_UP,
      });

      const registerBody = {
        first_name: trimmedFirstName,
        last_name: trimmedLastName,
        email: trimmedEmail,
        organization: trimmedOrganization,
        password,
        confirm_password: password,
      };

      const options: RequestInit = {
        credentials: 'include',
        body: JSON.stringify(registerBody),
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
      };
      return fetch(registrationUrl, options)
        .then(resp => {
          return resp.json();
        })
        .then(resp => {
          if ('status' in resp && resp.status === 'failure') {
            throw new Error(parseAuthResponse(resp.errors));
          }
          dispatch({
            type: SIGNUP_SUCCESS,
            data: resp.message,
          });
        })
        .catch(err => {
          dispatch({
            type: SIGNUP_FAIL,
            data: err.message,
          });
        });
    }

    return dispatch({
      type: SIGNUP_FAIL,
      data: passwordErrorMessage,
    });
  };

  const handleSignInButton = () => {
    navigate({
      pathname: signInURL,
      search: location.search,
    });
  };

  const isButtonDisabled =
    isSigningUp ||
    signupSuccess ||
    !trimmedFirstName ||
    !trimmedLastName ||
    !trimmedEmail ||
    !password ||
    !hasAcceptedTnC;

  const signupSuccessMessageExists =
    signupSuccessMessage && signupSuccessMessage.length;

  return (
    <Box>
      {isSigningUp && <NetworkLoader />}
      <Flex mb="3.06%" minHeight="40px" justifyContent="space-between">
        <Heading fontSize="1.5rem" fontFamily="Poppins">
          Sign Up
        </Heading>

        {/*hide Sign in link if one-click-deploy link and if signup successful*/}
        {!(signupSuccessMessageExists && oneClickDeployTitle) ? (
          <Flex width="auto">
            <Text fontSize="0.875rem">
              {signupSuccessMessageExists ? 'Verified email?' : 'Signed up?'}
            </Text>
            &nbsp;
            <Button
              variant="div"
              id="create-account-btn"
              onClick={handleSignInButton}
            >
              <Text color="red.primary" fontSize="0.875rem" hover="underline">
                Sign in
              </Text>
            </Button>
          </Flex>
        ) : (
          ''
        )}
      </Flex>
      <Flex
        flexDirection="column"
        as="form"
        width="100%"
        onSubmit={handleRegistration}
      >
        {signupSuccessMessageExists ? (
          <Flex flexDirection="column" mt={{ _: '10%', lg: 0 }}>
            <Image
              src={signupSuccessImg}
              alt="Signup successfull"
              width="100%"
            />
            <AuthAlert
              successMessage={`Registration successful. ${signupSuccessMessage}`}
            />
            {/*show Sign in post verification note if one-click-deploy link*/}
            {oneClickDeployTitle ? (
              <Flex width="100%" justifyContent="flex-start" mb="16px">
                <Flex width="auto">
                  <Button
                    variant="div"
                    id="signup-one-click-deploy-sign-in-btn"
                    onClick={handleSignInButton}
                  >
                    <Text
                      color="red.primary"
                      fontSize="0.875rem"
                      hover="underline"
                    >
                      Sign in
                    </Text>
                  </Button>
                  &nbsp;
                  <Text fontSize="0.875rem" noWrap>
                    here to continue your deployment after verifying your
                    account.
                  </Text>
                </Flex>
              </Flex>
            ) : (
              ''
            )}
            <Flex width="100%" justifyContent="flex-start" mb="60px">
              <Text fontSize="0.875rem" pr="5px" noWrap>
                Not received the verification email?&nbsp;
              </Text>
              <Link
                href="#"
                color="blue.link"
                hover="underline"
                onClick={() => handleResendEmail(email, setSignupError)}
              >
                <Flex>
                  <Icon type="resend" color="#337ab7" />
                  &nbsp; Resend
                </Flex>
              </Link>
            </Flex>
          </Flex>
        ) : (
          <>
            <div style={{ display: 'flex' }}>
              <Label style={{ flex: 1 }}>
                <Icon type="profile" mr="12px" isActive={trimmedFirstName} />
                <Input
                  type="text"
                  name="firstName"
                  disabled={!!signupSuccess}
                  placeholder="First Name"
                  value={firstName}
                  onChange={handleInputChange}
                />
              </Label>
              <Label style={{ flex: 1 }}>
                <Icon type="profile" mr="12px" isActive={trimmedLastName} />
                <Input
                  type="text"
                  name="lastName"
                  disabled={!!signupSuccess}
                  placeholder="Last Name"
                  value={lastName}
                  onChange={handleInputChange}
                />
              </Label>
            </div>
            <Label>
              <Icon type="email" mr="12px" isActive={trimmedEmail} />
              <Input
                type="email"
                name="email"
                disabled={!!signupSuccess}
                placeholder="Work Email"
                value={email}
                onChange={handleInputChange}
              />
            </Label>
            <Label>
              <Icon type="company" mr="12px" isActive={trimmedEmail} />
              <Input
                type="text"
                name="organization"
                disabled={!!signupSuccess}
                placeholder="Organization Name"
                value={organization}
                onChange={handleInputChange}
              />
            </Label>
            <PasswordInput
              onChangeCallback={handlePasswordInput}
              passwordInput={password}
              placeholderText="Password"
              disabled={!!signupSuccess}
            />
            <div className="mt-4 flex items-start">
              <input
                id="tnc-checkbox"
                checked={hasAcceptedTnC}
                name="tnc-checkbox"
                type="checkbox"
                className="mt-0.5 mr-2 h-6 w-6 cursor-pointer rounded border border-gray-300 shadow-sm hover:border-gray-400 focus:ring-primary-brand"
                onChange={e => handleTNCCheckboxInput(e.target.checked)}
                data-testid="tnc-checkbox-test-id"
              />
              <label
                htmlFor="tnc-checkbox"
                className="ml-1.5 cursor-pointer text-[14px] leading-[150%] text-gray-400 sm:text-[15px]"
              >
                By signing up, you agree to our{' '}
                <a
                  href={HASURA_TERMS_AND_CONDITIONS_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-inherit underline"
                >
                  Terms of Service
                </a>
                {' and '}
                <a
                  href={HASURA_PRIVACY_POLICY_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-inherit underline"
                >
                  Privacy Policy
                </a>
                . Keep me updated on news and offers.
              </label>
            </div>
            <Button
              id="create-new-account-btn"
              variant="primary"
              type="submit"
              size="large"
              width="100%"
              margin="5% 0"
              fontFamily="Poppins"
              disabled={isButtonDisabled}
              data-testid="create-account-btn-test-id"
            >
              Create my account
            </Button>
            <AuthAlert errorMessage={signupErr} />
          </>
        )}
      </Flex>
    </Box>
  );
};
