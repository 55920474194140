export const heapIdentify = (userId: string) => {
  const windowObj: any = window as any;
  if (windowObj.heap) {
    windowObj.heap.identify(userId);
  }
};

// Adding the follwoing properties to Heap:
//  - Launch userID and flags to heap -- https://help.heap.io/integrations/testing-and-personalization/launchdarkly/
//  - isHasuraUser -- Boolean to check if the user has hasura.io email to exclude them from analytics
export const heapAddProperties = (
  userId: string,
  flags: any,
  isInternalHasuraUser: boolean
) => {
  const windowObj: any = window as any;
  if (windowObj.heap) {
    windowObj.heap.addUserProperties({
      ldUserId: userId,
      isInternalHasuraUser: isInternalHasuraUser,
    });
    windowObj.heap.clearEventProperties();
    windowObj.heap.addEventProperties(flags);
  }
};
