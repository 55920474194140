import React from 'react';

import { StyledSVG } from '../Icon';

export const BellIcon = props => {
  const { color } = props;

  return (
    <StyledSVG
      width="15"
      height="18"
      viewBox="0 0 15 18"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4706 13.5H3.52941V7.65C3.52941 5.4 5.29412 3.6 7.5 3.6C9.70588 3.6 11.4706 5.4 11.4706 7.65V13.5ZM13.2353 12.6V7.65C13.2353 4.887 11.3471 2.574 8.82353 1.962V1.35C8.82353 0.991958 8.68409 0.64858 8.43588 0.395406C8.18767 0.142232 7.85102 0 7.5 0C7.14898 0 6.81233 0.142232 6.56412 0.395406C6.31591 0.64858 6.17647 0.991958 6.17647 1.35V1.962C3.64412 2.574 1.76471 4.887 1.76471 7.65V12.6L0 14.4V15.3H15V14.4L13.2353 12.6ZM7.5 18C7.96803 18 8.41689 17.8104 8.74784 17.4728C9.07878 17.1352 9.26471 16.6774 9.26471 16.2H5.73529C5.73529 16.6774 5.92122 17.1352 6.25216 17.4728C6.58311 17.8104 7.03197 18 7.5 18Z"
        fill="currentColor"
      />
    </StyledSVG>
  );
};
