import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { UnProtectedRoute } from '../../../../utils/routeUtils';

export function useTypedNavigate() {
  const { search } = useLocation();
  const navigate = useNavigate();

  const typedNavigate = useCallback(
    (route: UnProtectedRoute) => {
      navigate({ pathname: route, search });
    },
    [navigate, search]
  );

  return typedNavigate;
}
