import { authEndpoints, GRAPHQL_URL } from './constants';
import { validateEmail } from './helpers';

export const startSAMLSSO = (
  email: string,
  redir: string,
  errorCallback?: (eMsg?: string) => void
) => {
  const options = {
    method: 'POST',
    body: JSON.stringify({
      email,
    }),
  };

  return fetch(`${authEndpoints.samlSSO}?redir=${redir}`, options)
    .then(r => {
      if (r.status >= 500) {
        throw new Error('unexpected');
      }
      if (r.status >= 400) {
        if (errorCallback) {
          errorCallback('Email not registered with SSO');
        }
      } else {
        r.json().then(resp => {
          window.location.replace(resp.auth_url);
        });
      }
    })
    .catch(er => {
      console.error('Error performing SSO', er.message);
      if (errorCallback) {
        errorCallback('Unexpected failure with SSO. Please get in touch.');
      }
    });
};

export const getSAMLSSODomains = () => {
  const options = {
    method: 'POST',
    body: JSON.stringify({
      query: `
        query SAMLSSODomains {
          saml_idp {
            domain
          }
        }
      `,
    }),
  };
  return fetch(GRAPHQL_URL, options)
    .then(r => r.json())
    .then(r => {
      if (r.data && r.data.saml_idp) {
        return r.data.saml_idp.map((s: { domain: string }) => s.domain);
      }
      return [];
    })
    .catch(e => {
      console.error('Error fetching SAML SSO domains', e);
      return [];
    });
};

// returns the saml domain if the domain is registered with SAML SSO
export const getEmailSAMLDomain = (samlDomains: string[], emailRaw: string) => {
  const email = emailRaw.trim();
  if (validateEmail(email)) {
    return samlDomains.find(d => d === email?.split('@')[1]) || null;
  }
  return null;
};
