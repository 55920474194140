import React from 'react';

import { StyledSVG } from '../Icon';

export const RightArrowIcon = props => {
  const { color, width, height } = props;

  return (
    <StyledSVG
      width={width}
      height={height}
      focusable="false"
      data-prefix="fas"
      data-icon="trash"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      fill={color}
      {...props}
    >
      <path d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z" />
    </StyledSVG>
  );
};

RightArrowIcon.defaultProps = {
  width: '16px',
  height: '18px',
};
