import * as React from 'react';

import { StyledSVG } from '../Icon';

export const ExclamationHexagon = props => {
  const { color } = props;

  return (
    <StyledSVG
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="github-icon-alt"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={color}
      {...props}
    >
      <path
        d="M13.1083 2.5H6.89167L2.5 6.89167V13.1083L6.89167 17.5H13.1083L17.5 13.1083V6.89167L13.1083 2.5ZM15.8333 12.4167L12.4167 15.8333H7.58333L4.16667 12.4167V7.58333L7.58333 4.16667H12.4167L15.8333 7.58333V12.4167V12.4167Z"
        fill="#D97706"
      />
      <path
        d="M9.1665 5.83333H10.8332V10.8333H9.1665V5.83333ZM9.1665 12.5H10.8332V14.1667H9.1665V12.5Z"
        fill="#D97706"
      />
    </StyledSVG>
  );
};
