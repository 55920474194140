import styled, { css } from 'styled-components';
import {
  border,
  color,
  flexbox,
  layout,
  space,
  typography,
} from 'styled-system';

const hoverStyles = ({
  type,
  theme,
  disabled,
  hoverColor,
  boxShadowValue,
  variant,
}) => {
  if (type === 'secondary' && !disabled) {
    return css`
      &:hover {
        box-shadow: ${boxShadowValue};
        background: ${theme.colors.black.secondary};
        color: ${theme.colors.white};
      }
    `;
  }

  if (type === 'back' || variant === 'div') {
    return css`
      &:hover {
        background: ${hoverColor};
      }
    `;
  }

  if (!disabled) {
    return css`
      &:hover {
        box-shadow: ${boxShadowValue};
      }
    `;
  }
  return '';
};

export const StyledButton = styled.button`
  outline: none;
  border: none;
  white-space: nowrap;
  appearance: button;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;

  &:focus {
    box-shadow: 0 2px 8px 0 rgba(52, 187, 216, 0.4);
  }

  cursor: ${({ disabled }) => !disabled && 'pointer'};

  &:disabled {
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  ${hoverStyles}
  ${layout}
	  ${space}
	  ${typography}
	  ${color}
	  ${border}
	  ${flexbox}
`;
