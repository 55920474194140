import React from 'react';
import { css } from 'styled-components/macro';

import { StyledHeading, StyledText } from './Typography';

export const Heading = props => {
  const { children } = props;

  return <StyledHeading {...props}>{children}</StyledHeading>;
};

Heading.defaultProps = {
  fontWeight: 'normal',
  color: 'black.text',
};

export const Text = props => {
  const { children, hover } = props;

  return (
    <StyledText
      {...props}
      css={
        hover === 'underline' &&
        css`
          &:hover {
            text-decoration: underline;
          }
        `
      }
    >
      {children}
    </StyledText>
  );
};

Text.defaultProps = {
  mb: 0,
  mt: 0,
  lineHeight: 1.5,
  color: 'black.text',
  fontSize: '16px',
};
