import React from 'react';

import { StyledSVG } from '../Icon';

export const KeyIcon = props => {
  const { color } = props;

  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill={color}
      {...props}
    >
      <path
        fill="currentColor"
        d="M16 9.09091C16 14.1364 12.5867 18.8545 8 20C3.41333 18.8545 0 14.1364 0 9.09091V3.63636L8 0L16 3.63636V9.09091ZM8 18.1818C11.3333 17.2727 14.2222 13.2182 14.2222 9.29091V4.81818L8 1.98182L1.77778 4.81818V9.29091C1.77778 13.2182 4.66667 17.2727 8 18.1818ZM8 4.54545C8.70724 4.54545 9.38552 4.83279 9.88562 5.34425C10.3857 5.85572 10.6667 6.54941 10.6667 7.27273C10.6667 8.46364 9.92889 9.47273 8.88889 9.84546V11.8182H10.6667V13.6364H8.88889V15.4545H7.11111V9.84546C6.07111 9.47273 5.33333 8.46364 5.33333 7.27273C5.33333 6.54941 5.61428 5.85572 6.11438 5.34425C6.61448 4.83279 7.29276 4.54545 8 4.54545ZM8 6.36364C7.76425 6.36364 7.53816 6.45942 7.37146 6.6299C7.20476 6.80039 7.11111 7.03162 7.11111 7.27273C7.11111 7.51383 7.20476 7.74506 7.37146 7.91555C7.53816 8.08604 7.76425 8.18182 8 8.18182C8.23575 8.18182 8.46184 8.08604 8.62854 7.91555C8.79524 7.74506 8.88889 7.51383 8.88889 7.27273C8.88889 7.03162 8.79524 6.80039 8.62854 6.6299C8.46184 6.45942 8.23575 6.36364 8 6.36364Z"
      />
    </StyledSVG>
  );
};
