import React from 'react';

import { StyledSVG } from '../Icon';

export const CompanyIcon = props => {
  const { color } = props;

  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill={color}
      {...props}
    >
      <path d="M16 12H14V14H16V12ZM16 8H14V10H16V8ZM18 16H10V14H12V12H10V10H12V8H10V6H18V16ZM8 4H6V2H8V4ZM8 8H6V6H8V8ZM8 12H6V10H8V12ZM8 16H6V14H8V16ZM4 4H2V2H4V4ZM4 8H2V6H4V8ZM4 12H2V10H4V12ZM4 16H2V14H4V16ZM10 4V0H0V18H20V4H10Z" />
    </StyledSVG>
  );
};
