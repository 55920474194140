import React from 'react';
import { css } from 'styled-components/macro';

import { authEndpoints } from '../../utils/constants';
import { segmentEvents, segmentTrack } from '../../utils/segment';
import { ButtonOnClick } from '../../utils/types';
import githubLogo from '../images/github_logo.png';
import googleLogo from '../images/google_logo.png';
import { Box, Button, Image, Link } from '../UIKit';

interface OAuthProps {
  redirectUrl: string;
  authService: string;
  icon: string;
  iconWidth: string;
  id: string;
  bgColor: string;
  textColor?: string;
}

export const OAuth: React.FC<OAuthProps> = ({
  redirectUrl,
  authService,
  icon,
  iconWidth,
  id,
  bgColor,
  textColor,
}) => (
  <Box
    mb="3.75%"
    width="100%"
    border={1}
    borderColor="grey.border"
    borderRadius={6}
    bg={bgColor}
    css={css`
      transition: all 0.2s ease;
      -webkit-transition: all 0.2s ease;

      &:hover {
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.24);
      }
    `}
    onClick={(e: ButtonOnClick) => {
      e.stopPropagation();
      segmentTrack(segmentEvents.attemptLogin, {
        label: `Attempt Login with ${authService}`,
      });
    }}
  >
    <Link href={redirectUrl} id={id}>
      <Button
        variant="div"
        size="xl"
        width="100%"
        color={textColor}
        fontWeight="bold"
        fontSize="1rem"
      >
        <Image src={icon} mr="1rem" alt={authService} width={iconWidth} />
        {authService}
      </Button>
    </Link>
  </Box>
);

// ******************************** //

interface SocialAuthProps {
  redir: string;
}

export const SocialAuth: React.FC<SocialAuthProps> = ({ redir }) => (
  <Box width="100%">
    <OAuth
      id="github-btn"
      authService="GitHub"
      redirectUrl={`${authEndpoints.githubOAuthUrl}?redir=${redir}`}
      icon={githubLogo}
      iconWidth="37px"
      bgColor="#000"
      textColor="white"
    />
    <OAuth
      id="google-btn"
      authService="Google"
      redirectUrl={`${authEndpoints.googleOAuthUrl}?redir=${redir}`}
      icon={googleLogo}
      iconWidth="42px"
      bgColor="#fff"
    />
  </Box>
);
