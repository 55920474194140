import styled, { css } from 'styled-components/macro';

import { Box } from '../Box';

const checkedStyles = css`
  background-color: #1eb4d4;
  box-shadow: 0 0 1px #1eb4d4;
  :before {
    transform: translateX(20px);
  }
`;

export const StyledSwitchButton = styled(Box)`
  width: auto;

  label {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 25px;
    margin: 0;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    input:checked {
      background-color: #1eb4d4;
    }
    input:focus {
      box-shadow: 0 0 1px #1eb4d4;
    }
    input:checked {
      transform: translateX(20px);
    }
  }
`;

export const StyledSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #dedede;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    border-radius: 50%;
    position: absolute;
    content: '';
    height: 17px;
    width: 17px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.25s ease 0s;
    box-shadow:
      rgba(0, 0, 0, 0.25) 0px 0px 1px 0px,
      rgba(0, 0, 0, 0.08) 0px 4px 11px 0px,
      rgba(0, 0, 0, 0.14) -1px 3px 3px 0px;
  }

  ${({ checked }) => (checked ? checkedStyles : ' ')}
`;
