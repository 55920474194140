import { getRedir } from '../../utils';

type OauthParams = {
  authUrl: string;
  search: string;
};

export const oauthUrl = ({ authUrl, search }: OauthParams) => {
  const params = new URLSearchParams(search);
  const redir = getRedir(search);
  const redirectUrl = params.get('redirect_url');

  if (
    redirectUrl &&
    (redirectUrl.includes('console.hasura.io') ||
      redirectUrl.includes('console.arusah.com'))
  ) {
    const platform = redirectUrl.includes('promptql')
      ? 'promptql_v3_console'
      : 'hasura_v3_console';
    return `${authUrl}?redir=${redir}?platform=${platform}`;
  }

  return `${authUrl}?redir=${redir}`;
};
