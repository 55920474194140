import React, { useState } from 'react';

import { getPasswordStrength } from '../../utils/helpers';
import {
  StyledPasswordInput,
  StyledTooltipMessage,
} from '../styles/StyledPasswordInput';
import { Flex, Icon, Input, Label, Text, ToolTip } from '../UIKit';

const tooltipMessage = (
  <StyledTooltipMessage
    id="invalid-password-tooltip"
    data-testid="invalid-password-tooltip"
  >
    Password must include at least 8 characters and at most 64 characters,
    <br /> with at least:
    <br />
    &#8226;&nbsp; 1 uppercase letter,
    <br />
    &#8226;&nbsp; 1 lowercase letter,
    <br />
    &#8226;&nbsp; 1 number,
    <br />
    &#8226;&nbsp; {'1 special character (!@#$%^&*()-+)'}
  </StyledTooltipMessage>
);

// *********************** //

interface PasswordInputProps {
  passwordInput: string;
  onChangeCallback: (pass: string) => void;
  placeholderText: string;
  disabled?: boolean;
}

export const PasswordInput: React.FC<PasswordInputProps> = ({
  passwordInput,
  onChangeCallback,
  placeholderText,
  disabled = false,
}) => {
  const [passwordStrength, updatePasswordStrength] = useState<number>(
    getPasswordStrength(passwordInput)
  );

  // ********************** //

  const handlePasswordChange = (password: string) => {
    onChangeCallback(password);
    updatePasswordStrength(getPasswordStrength(password));
  };

  // ********************** //

  const getPasswordStrengthMeterUI = () => {
    if (!passwordInput) {
      return null;
    }

    const getPasswordStatusMessage = () => {
      let status;
      let textColor;

      switch (passwordStrength) {
        case 0:
          status = 'Weak password';
          textColor = 'red.primary';
          break;
        case 1:
          status = 'Moderate password';
          textColor = 'yellowgreen';
          break;
        case 2:
          status = 'Strong password';
          textColor = 'green.original';
          break;
        case 99:
          status = 'Password too long';
          textColor = 'red.original';
          break;
        default:
          status = 'Weak password';
          textColor = 'red.primary';
      }

      return (
        <Flex
          width="100%"
          justifyContent="flex-end"
          py="5px"
          minHeight="16px"
          alignItems="center"
        >
          <Text fontSize="13px" color={textColor} mb="-3px">
            {`${status}`}
          </Text>
          <ToolTip
            message={tooltipMessage}
            defaultTooltipView={passwordStrength == 0 || passwordStrength == 99}
            placement="top"
          />
        </Flex>
      );
    };

    return (
      <>
        <div className="strength-meter">
          <div
            className="strength-meter-fill"
            data-strength={passwordStrength}
          />
        </div>
        {getPasswordStatusMessage()}
      </>
    );
  };

  // ********************** //

  return (
    <StyledPasswordInput>
      <Label>
        <Icon type="password" mr="12px" isActive={passwordInput || ''} />
        <Input
          type="password"
          fontSize="14px"
          data-field-name="password"
          disabled={disabled}
          placeholder={placeholderText}
          value={passwordInput}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handlePasswordChange(e.target.value);
          }}
        />
      </Label>
      {getPasswordStrengthMeterUI()}
    </StyledPasswordInput>
  );
};
